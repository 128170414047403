import { AvistaAppBar } from "@avista/avista-app-bar";
import { AvistaAvatar } from "@avista/avista-avatar";
import React from "react";
import { loginHostUrl } from "./consts";
import Paper from "@mui/material/Paper";
import { ReactNode } from "react";

interface LayoutProps {
  children: ReactNode;
}

export const Layout: React.FC<LayoutProps>  = ({ children }) => {
  return (
    <Paper sx={() => (
      {
        minHeight: "100vh",
        height: "100%",
        width: "100%",
        backgroundColor: "#eceff1",
      }
    )} >
      <AvistaAppBar
        children={{
          mainChildrenItems: [],
          rightChildren: (
            <>
              <AvistaAvatar
                loginServerUrl={loginHostUrl || "http://login.avaval.com"}
                myProfileUrl=""
              />
            </>
          ),
        }}
      />
      {children}
    </Paper>
  );
};
