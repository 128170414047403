import Cookies from "js-cookie";
import { decodeToken } from "@avista/jwt-client";
import { cookiesNameJwt } from "../consts";

export interface LandchinaParamInput {
  province: string;
  district: string;
  area: string;
  landusageFirstLevel: string;
  landusageSecondLevel: string;
  landusageThridLevel: string;
  landusageFourthLevel: string;
  landusageFifthLevel: string;
  method: string;
  startDate: Date;
  endDate: Date;
  code: string;
  location: string;
}

export interface ParamsDto {
  // pageNum: number; // set by server
  pageSize: number;
  xzqDm: string;
  gyFs: string;
  tdYt: string;
  startDate: Date;
  endDate: Date;
  dzBaBh: string;
  tdZl: string;
}

export const getLoginData = () => {
  const token = Cookies.get(cookiesNameJwt!);
  const payload = decodeToken(token || "");

  return payload;
};

export const toLandchinaParamDTO = ({
  province,
  district,
  area,
  landusageFirstLevel,
  landusageSecondLevel,
  landusageThridLevel,
  method,
  startDate,
  endDate,
  code,
  location,
}: Partial<LandchinaParamInput>) => {
  return {
    pageSize: 40,
    xzqDm: area || district || province,
    tdYt: landusageThridLevel || landusageSecondLevel || landusageFirstLevel,
    gyFs: method,
    startDate,
    endDate,
    dzBaBh: code,
    tdZl: location,
  } as ParamsDto;
};
