export type Base = {
  enumValue: string;
  enumName: string;
  [key: string]: any;
};

export type Area = Base;

export type District = Base & {
  children?: Area[];
};

export type Province = Base & {
  children?: District[];
};

export const districts: Province[] = [
  {
    "enumValue": "11",
    "enumName": "北京市",
    "enumDesc": "zdcs",
    "children": [
      {
        "parentEnumValue": "11",
        "enumValue": "1101",
        "enumName": "北京市",
        "children": [
          {
            "parentEnumValue": "1101",
            "enumValue": "110100",
            "enumName": "北京市本级"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110228",
            "enumName": "密云县"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110229",
            "enumName": "延庆县"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110119",
            "enumName": "延庆区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110118",
            "enumName": "密云区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110101",
            "enumName": "东城区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110102",
            "enumName": "西城区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110103",
            "enumName": "崇文区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110104",
            "enumName": "宣武区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110105",
            "enumName": "朝阳区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110106",
            "enumName": "丰台区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110107",
            "enumName": "石景山区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110108",
            "enumName": "海淀区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110109",
            "enumName": "门头沟区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110111",
            "enumName": "房山区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110112",
            "enumName": "通州区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110113",
            "enumName": "顺义区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110114",
            "enumName": "昌平区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110115",
            "enumName": "大兴区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110116",
            "enumName": "怀柔区"
          },
          {
            "parentEnumValue": "1101",
            "enumValue": "110117",
            "enumName": "平谷区"
          }
        ]
      },
      {
        "parentEnumValue": "11",
        "enumValue": "1102",
        "enumName": "北京市辖县"
      }
    ]
  },
  {
    "enumValue": "12",
    "enumName": "天津市",
    "enumDesc": "zdcs",
    "children": [
      {
        "parentEnumValue": "12",
        "enumValue": "1201",
        "enumName": "天津市",
        "children": [
          {
            "parentEnumValue": "1201",
            "enumValue": "120223",
            "enumName": "静海县"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120100",
            "enumName": "天津市本级"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120116",
            "enumName": "滨海新区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120221",
            "enumName": "宁河县"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120117",
            "enumName": "宁河区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120102",
            "enumName": "河东区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120101",
            "enumName": "和平区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120103",
            "enumName": "河西区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120104",
            "enumName": "南开区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120105",
            "enumName": "河北区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120106",
            "enumName": "红桥区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120107",
            "enumName": "塘沽区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120108",
            "enumName": "汉沽区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120109",
            "enumName": "大港区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120110",
            "enumName": "东丽区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120111",
            "enumName": "西青区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120112",
            "enumName": "津南区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120113",
            "enumName": "北辰区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120114",
            "enumName": "武清区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120115",
            "enumName": "宝坻区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120118",
            "enumName": "静海区"
          },
          {
            "parentEnumValue": "1201",
            "enumValue": "120119",
            "enumName": "蓟州区"
          }
        ]
      },
      {
        "parentEnumValue": "12",
        "enumValue": "1202",
        "enumName": "天津市所辖县(撤)",
        "children": [
          {
            "parentEnumValue": "1202",
            "enumValue": "120225",
            "enumName": "蓟县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "13",
    "enumName": "河北省",
    "children": [
      {
        "parentEnumValue": "13",
        "enumValue": "1399",
        "enumName": "雄安新区",
        "children": [
          {
            "parentEnumValue": "1399",
            "enumValue": "139900",
            "enumName": "雄安新区"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1301",
        "enumName": "石家庄市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "1301",
            "enumValue": "130100",
            "enumName": "石家庄市本级"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130111",
            "enumName": "栾城区"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130110",
            "enumName": "鹿泉区"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130109",
            "enumName": "藁城区"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130102",
            "enumName": "长安区"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130103",
            "enumName": "桥东区"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130104",
            "enumName": "桥西区"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130105",
            "enumName": "新华区"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130107",
            "enumName": "井陉矿区"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130108",
            "enumName": "裕华区"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130121",
            "enumName": "井陉县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130123",
            "enumName": "正定县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130124",
            "enumName": "栾城县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130125",
            "enumName": "行唐县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130126",
            "enumName": "灵寿县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130127",
            "enumName": "高邑县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130128",
            "enumName": "深泽县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130129",
            "enumName": "赞皇县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130130",
            "enumName": "无极县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130131",
            "enumName": "平山县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130132",
            "enumName": "元氏县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130133",
            "enumName": "赵县"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130181",
            "enumName": "辛集市"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130182",
            "enumName": "藁城市"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130183",
            "enumName": "晋州市"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130184",
            "enumName": "新乐市"
          },
          {
            "parentEnumValue": "1301",
            "enumValue": "130185",
            "enumName": "鹿泉市"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1302",
        "enumName": "唐山市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "1302",
            "enumValue": "130284",
            "enumName": "滦州市"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130200",
            "enumName": "唐山市本级"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130202",
            "enumName": "路南区"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130203",
            "enumName": "路北区"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130204",
            "enumName": "古冶区"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130205",
            "enumName": "开平区"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130207",
            "enumName": "丰南区"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130208",
            "enumName": "丰润区"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130223",
            "enumName": "滦县"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130224",
            "enumName": "滦南县"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130225",
            "enumName": "乐亭县"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130227",
            "enumName": "迁西县"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130229",
            "enumName": "玉田县"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130230",
            "enumName": "唐海县"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130281",
            "enumName": "遵化市"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130283",
            "enumName": "迁安市"
          },
          {
            "parentEnumValue": "1302",
            "enumValue": "130209",
            "enumName": "曹妃甸区"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1303",
        "enumName": "秦皇岛市",
        "children": [
          {
            "parentEnumValue": "1303",
            "enumValue": "130306",
            "enumName": "抚宁区"
          },
          {
            "parentEnumValue": "1303",
            "enumValue": "130300",
            "enumName": "秦皇岛市本级"
          },
          {
            "parentEnumValue": "1303",
            "enumValue": "130302",
            "enumName": "海港区"
          },
          {
            "parentEnumValue": "1303",
            "enumValue": "130303",
            "enumName": "山海关区"
          },
          {
            "parentEnumValue": "1303",
            "enumValue": "130304",
            "enumName": "北戴河区"
          },
          {
            "parentEnumValue": "1303",
            "enumValue": "130321",
            "enumName": "青龙满族自治县"
          },
          {
            "parentEnumValue": "1303",
            "enumValue": "130322",
            "enumName": "昌黎县"
          },
          {
            "parentEnumValue": "1303",
            "enumValue": "130323",
            "enumName": "抚宁县"
          },
          {
            "parentEnumValue": "1303",
            "enumValue": "130324",
            "enumName": "卢龙县"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1304",
        "enumName": "邯郸市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "1304",
            "enumValue": "130408",
            "enumName": "永年区"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130407",
            "enumName": "肥乡区"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130400",
            "enumName": "邯郸市本级"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130402",
            "enumName": "邯山区"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130403",
            "enumName": "丛台区"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130404",
            "enumName": "复兴区"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130406",
            "enumName": "峰峰矿区"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130421",
            "enumName": "邯郸县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130423",
            "enumName": "临漳县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130424",
            "enumName": "成安县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130425",
            "enumName": "大名县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130426",
            "enumName": "涉县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130427",
            "enumName": "磁县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130428",
            "enumName": "肥乡县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130429",
            "enumName": "永年县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130430",
            "enumName": "邱县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130431",
            "enumName": "鸡泽县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130432",
            "enumName": "广平县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130433",
            "enumName": "馆陶县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130434",
            "enumName": "魏县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130435",
            "enumName": "曲周县"
          },
          {
            "parentEnumValue": "1304",
            "enumValue": "130481",
            "enumName": "武安市"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1305",
        "enumName": "邢台市",
        "children": [
          {
            "parentEnumValue": "1305",
            "enumValue": "130506",
            "enumName": "南和区"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130505",
            "enumName": "任泽区"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130500",
            "enumName": "邢台市本级"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130502",
            "enumName": "襄都区"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130503",
            "enumName": "信都区"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130521",
            "enumName": "邢台县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130522",
            "enumName": "临城县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130523",
            "enumName": "内丘县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130524",
            "enumName": "柏乡县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130525",
            "enumName": "隆尧县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130526",
            "enumName": "任县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130527",
            "enumName": "南和县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130528",
            "enumName": "宁晋县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130529",
            "enumName": "巨鹿县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130530",
            "enumName": "新河县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130531",
            "enumName": "广宗县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130532",
            "enumName": "平乡县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130533",
            "enumName": "威县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130534",
            "enumName": "清河县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130535",
            "enumName": "临西县"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130581",
            "enumName": "南宫市"
          },
          {
            "parentEnumValue": "1305",
            "enumValue": "130582",
            "enumName": "沙河市"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1306",
        "enumName": "保定市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "1306",
            "enumValue": "130606",
            "enumName": "莲池区"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130609",
            "enumName": "徐水区"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130608",
            "enumName": "清苑区"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130607",
            "enumName": "满城区"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130600",
            "enumName": "保定市本级"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130602",
            "enumName": "竞秀区"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130603",
            "enumName": "北市区"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130604",
            "enumName": "南市区"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130621",
            "enumName": "满城县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130622",
            "enumName": "清苑县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130623",
            "enumName": "涞水县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130624",
            "enumName": "阜平县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130625",
            "enumName": "徐水县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130626",
            "enumName": "定兴县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130627",
            "enumName": "唐县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130628",
            "enumName": "高阳县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130629",
            "enumName": "容城县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130630",
            "enumName": "涞源县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130631",
            "enumName": "望都县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130632",
            "enumName": "安新县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130633",
            "enumName": "易县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130634",
            "enumName": "曲阳县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130635",
            "enumName": "蠡县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130636",
            "enumName": "顺平县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130637",
            "enumName": "博野县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130638",
            "enumName": "雄县"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130681",
            "enumName": "涿州市"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130682",
            "enumName": "定州市"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130683",
            "enumName": "安国市"
          },
          {
            "parentEnumValue": "1306",
            "enumValue": "130684",
            "enumName": "高碑店市"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1307",
        "enumName": "张家口市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "1307",
            "enumValue": "130709",
            "enumName": "崇礼区"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130708",
            "enumName": "万全区"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130700",
            "enumName": "张家口市本级"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130702",
            "enumName": "桥东区"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130703",
            "enumName": "桥西区"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130705",
            "enumName": "宣化区"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130706",
            "enumName": "下花园区"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130721",
            "enumName": "宣化县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130722",
            "enumName": "张北县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130723",
            "enumName": "康保县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130724",
            "enumName": "沽源县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130725",
            "enumName": "尚义县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130726",
            "enumName": "蔚县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130727",
            "enumName": "阳原县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130728",
            "enumName": "怀安县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130729",
            "enumName": "万全县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130730",
            "enumName": "怀来县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130731",
            "enumName": "涿鹿县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130732",
            "enumName": "赤城县"
          },
          {
            "parentEnumValue": "1307",
            "enumValue": "130733",
            "enumName": "崇礼县"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1308",
        "enumName": "承德市",
        "children": [
          {
            "parentEnumValue": "1308",
            "enumValue": "130881",
            "enumName": "平泉市"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130800",
            "enumName": "承德市本级"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130802",
            "enumName": "双桥区"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130803",
            "enumName": "双滦区"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130804",
            "enumName": "鹰手营子矿区"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130821",
            "enumName": "承德县"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130822",
            "enumName": "兴隆县"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130823",
            "enumName": "平泉县"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130824",
            "enumName": "滦平县"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130825",
            "enumName": "隆化县"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130826",
            "enumName": "丰宁满族自治县"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130827",
            "enumName": "宽城满族自治县"
          },
          {
            "parentEnumValue": "1308",
            "enumValue": "130828",
            "enumName": "围场满族蒙古族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1309",
        "enumName": "沧州市",
        "children": [
          {
            "parentEnumValue": "1309",
            "enumValue": "130900",
            "enumName": "沧州市本级"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130902",
            "enumName": "新华区"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130903",
            "enumName": "运河区"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130921",
            "enumName": "沧县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130922",
            "enumName": "青县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130923",
            "enumName": "东光县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130924",
            "enumName": "海兴县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130925",
            "enumName": "盐山县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130926",
            "enumName": "肃宁县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130927",
            "enumName": "南皮县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130928",
            "enumName": "吴桥县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130929",
            "enumName": "献县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130930",
            "enumName": "孟村回族自治县"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130981",
            "enumName": "泊头市"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130982",
            "enumName": "任丘市"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130983",
            "enumName": "黄骅市"
          },
          {
            "parentEnumValue": "1309",
            "enumValue": "130984",
            "enumName": "河间市"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1310",
        "enumName": "廊坊市",
        "children": [
          {
            "parentEnumValue": "1310",
            "enumValue": "131000",
            "enumName": "廊坊市本级"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131002",
            "enumName": "安次区"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131003",
            "enumName": "广阳区"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131022",
            "enumName": "固安县"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131023",
            "enumName": "永清县"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131024",
            "enumName": "香河县"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131025",
            "enumName": "大城县"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131026",
            "enumName": "文安县"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131028",
            "enumName": "大厂回族自治县"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131081",
            "enumName": "霸州市"
          },
          {
            "parentEnumValue": "1310",
            "enumValue": "131082",
            "enumName": "三河市"
          }
        ]
      },
      {
        "parentEnumValue": "13",
        "enumValue": "1311",
        "enumName": "衡水市",
        "children": [
          {
            "parentEnumValue": "1311",
            "enumValue": "131103",
            "enumName": "冀州区"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131100",
            "enumName": "衡水市本级"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131102",
            "enumName": "桃城区"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131121",
            "enumName": "枣强县"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131122",
            "enumName": "武邑县"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131123",
            "enumName": "武强县"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131124",
            "enumName": "饶阳县"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131125",
            "enumName": "安平县"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131126",
            "enumName": "故城县"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131127",
            "enumName": "景县"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131128",
            "enumName": "阜城县"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131181",
            "enumName": "冀州市"
          },
          {
            "parentEnumValue": "1311",
            "enumValue": "131182",
            "enumName": "深州市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "14",
    "enumName": "山西省",
    "children": [
      {
        "parentEnumValue": "14",
        "enumValue": "1401",
        "enumName": "太原市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "1401",
            "enumValue": "140100",
            "enumName": "太原市本级"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140105",
            "enumName": "小店区"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140106",
            "enumName": "迎泽区"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140107",
            "enumName": "杏花岭区"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140108",
            "enumName": "尖草坪区"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140109",
            "enumName": "万柏林区"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140110",
            "enumName": "晋源区"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140121",
            "enumName": "清徐县"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140122",
            "enumName": "阳曲县"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140123",
            "enumName": "娄烦县"
          },
          {
            "parentEnumValue": "1401",
            "enumValue": "140181",
            "enumName": "古交市"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1402",
        "enumName": "大同市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "1402",
            "enumValue": "140214",
            "enumName": "云冈区"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140213",
            "enumName": "平城区"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140200",
            "enumName": "大同市本级"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140202",
            "enumName": "城区"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140203",
            "enumName": "矿区"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140211",
            "enumName": "南郊区"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140212",
            "enumName": "新荣区"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140221",
            "enumName": "阳高县"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140222",
            "enumName": "天镇县"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140223",
            "enumName": "广灵县"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140224",
            "enumName": "灵丘县"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140225",
            "enumName": "浑源县"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140226",
            "enumName": "左云县"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140227",
            "enumName": "大同县"
          },
          {
            "parentEnumValue": "1402",
            "enumValue": "140215",
            "enumName": "云州区"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1403",
        "enumName": "阳泉市",
        "children": [
          {
            "parentEnumValue": "1403",
            "enumValue": "140300",
            "enumName": "阳泉市本级"
          },
          {
            "parentEnumValue": "1403",
            "enumValue": "140302",
            "enumName": "城区"
          },
          {
            "parentEnumValue": "1403",
            "enumValue": "140303",
            "enumName": "矿区"
          },
          {
            "parentEnumValue": "1403",
            "enumValue": "140311",
            "enumName": "郊区"
          },
          {
            "parentEnumValue": "1403",
            "enumValue": "140321",
            "enumName": "平定县"
          },
          {
            "parentEnumValue": "1403",
            "enumValue": "140322",
            "enumName": "盂县"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1404",
        "enumName": "长治市",
        "children": [
          {
            "parentEnumValue": "1404",
            "enumValue": "140406",
            "enumName": "潞城区"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140405",
            "enumName": "屯留区"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140404",
            "enumName": "上党区"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140403",
            "enumName": "潞州区"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140400",
            "enumName": "长治市本级"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140402",
            "enumName": "城区"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140411",
            "enumName": "郊区"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140421",
            "enumName": "长治县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140423",
            "enumName": "襄垣县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140424",
            "enumName": "屯留县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140425",
            "enumName": "平顺县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140426",
            "enumName": "黎城县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140427",
            "enumName": "壶关县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140428",
            "enumName": "长子县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140429",
            "enumName": "武乡县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140430",
            "enumName": "沁县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140431",
            "enumName": "沁源县"
          },
          {
            "parentEnumValue": "1404",
            "enumValue": "140481",
            "enumName": "潞城市"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1405",
        "enumName": "晋城市",
        "children": [
          {
            "parentEnumValue": "1405",
            "enumValue": "140500",
            "enumName": "晋城市本级"
          },
          {
            "parentEnumValue": "1405",
            "enumValue": "140502",
            "enumName": "城区"
          },
          {
            "parentEnumValue": "1405",
            "enumValue": "140521",
            "enumName": "沁水县"
          },
          {
            "parentEnumValue": "1405",
            "enumValue": "140522",
            "enumName": "阳城县"
          },
          {
            "parentEnumValue": "1405",
            "enumValue": "140524",
            "enumName": "陵川县"
          },
          {
            "parentEnumValue": "1405",
            "enumValue": "140525",
            "enumName": "泽州县"
          },
          {
            "parentEnumValue": "1405",
            "enumValue": "140581",
            "enumName": "高平市"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1406",
        "enumName": "朔州市",
        "children": [
          {
            "parentEnumValue": "1406",
            "enumValue": "140681",
            "enumName": "怀仁市"
          },
          {
            "parentEnumValue": "1406",
            "enumValue": "140600",
            "enumName": "朔州市本级"
          },
          {
            "parentEnumValue": "1406",
            "enumValue": "140602",
            "enumName": "朔城区"
          },
          {
            "parentEnumValue": "1406",
            "enumValue": "140603",
            "enumName": "平鲁区"
          },
          {
            "parentEnumValue": "1406",
            "enumValue": "140621",
            "enumName": "山阴县"
          },
          {
            "parentEnumValue": "1406",
            "enumValue": "140622",
            "enumName": "应县"
          },
          {
            "parentEnumValue": "1406",
            "enumValue": "140623",
            "enumName": "右玉县"
          },
          {
            "parentEnumValue": "1406",
            "enumValue": "140624",
            "enumName": "怀仁县"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1407",
        "enumName": "晋中市",
        "children": [
          {
            "parentEnumValue": "1407",
            "enumValue": "140703",
            "enumName": "太谷区"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140700",
            "enumName": "晋中市本级"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140702",
            "enumName": "榆次区"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140721",
            "enumName": "榆社县"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140722",
            "enumName": "左权县"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140723",
            "enumName": "和顺县"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140724",
            "enumName": "昔阳县"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140725",
            "enumName": "寿阳县"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140726",
            "enumName": "太谷县"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140727",
            "enumName": "祁县"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140728",
            "enumName": "平遥县"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140729",
            "enumName": "灵石县"
          },
          {
            "parentEnumValue": "1407",
            "enumValue": "140781",
            "enumName": "介休市"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1408",
        "enumName": "运城市",
        "children": [
          {
            "parentEnumValue": "1408",
            "enumValue": "140800",
            "enumName": "运城市本级"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140802",
            "enumName": "盐湖区"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140821",
            "enumName": "临猗县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140822",
            "enumName": "万荣县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140823",
            "enumName": "闻喜县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140824",
            "enumName": "稷山县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140825",
            "enumName": "新绛县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140826",
            "enumName": "绛县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140827",
            "enumName": "垣曲县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140828",
            "enumName": "夏县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140829",
            "enumName": "平陆县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140830",
            "enumName": "芮城县"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140881",
            "enumName": "永济市"
          },
          {
            "parentEnumValue": "1408",
            "enumValue": "140882",
            "enumName": "河津市"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1409",
        "enumName": "忻州市",
        "children": [
          {
            "parentEnumValue": "1409",
            "enumValue": "140900",
            "enumName": "忻州市本级"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140902",
            "enumName": "忻府区"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140921",
            "enumName": "定襄县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140922",
            "enumName": "五台县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140923",
            "enumName": "代县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140924",
            "enumName": "繁峙县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140925",
            "enumName": "宁武县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140926",
            "enumName": "静乐县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140927",
            "enumName": "神池县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140928",
            "enumName": "五寨县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140929",
            "enumName": "岢岚县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140930",
            "enumName": "河曲县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140931",
            "enumName": "保德县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140932",
            "enumName": "偏关县"
          },
          {
            "parentEnumValue": "1409",
            "enumValue": "140981",
            "enumName": "原平市"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1410",
        "enumName": "临汾市",
        "children": [
          {
            "parentEnumValue": "1410",
            "enumValue": "141000",
            "enumName": "临汾市本级"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141002",
            "enumName": "尧都区"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141021",
            "enumName": "曲沃县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141022",
            "enumName": "翼城县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141023",
            "enumName": "襄汾县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141024",
            "enumName": "洪洞县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141025",
            "enumName": "古县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141026",
            "enumName": "安泽县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141027",
            "enumName": "浮山县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141028",
            "enumName": "吉县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141029",
            "enumName": "乡宁县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141030",
            "enumName": "大宁县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141031",
            "enumName": "隰县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141032",
            "enumName": "永和县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141033",
            "enumName": "蒲县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141034",
            "enumName": "汾西县"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141081",
            "enumName": "侯马市"
          },
          {
            "parentEnumValue": "1410",
            "enumValue": "141082",
            "enumName": "霍州市"
          }
        ]
      },
      {
        "parentEnumValue": "14",
        "enumValue": "1411",
        "enumName": "吕梁市",
        "children": [
          {
            "parentEnumValue": "1411",
            "enumValue": "141100",
            "enumName": "吕梁市本级"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141102",
            "enumName": "离石区"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141121",
            "enumName": "文水县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141122",
            "enumName": "交城县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141123",
            "enumName": "兴县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141124",
            "enumName": "临县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141125",
            "enumName": "柳林县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141126",
            "enumName": "石楼县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141127",
            "enumName": "岚县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141128",
            "enumName": "方山县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141129",
            "enumName": "中阳县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141130",
            "enumName": "交口县"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141181",
            "enumName": "孝义市"
          },
          {
            "parentEnumValue": "1411",
            "enumValue": "141182",
            "enumName": "汾阳市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "15",
    "enumName": "内蒙古自治区",
    "children": [
      {
        "parentEnumValue": "15",
        "enumValue": "1501",
        "enumName": "呼和浩特市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "1501",
            "enumValue": "150100",
            "enumName": "呼和浩特市本级"
          },
          {
            "parentEnumValue": "1501",
            "enumValue": "150102",
            "enumName": "新城区"
          },
          {
            "parentEnumValue": "1501",
            "enumValue": "150103",
            "enumName": "回民区"
          },
          {
            "parentEnumValue": "1501",
            "enumValue": "150104",
            "enumName": "玉泉区"
          },
          {
            "parentEnumValue": "1501",
            "enumValue": "150105",
            "enumName": "赛罕区"
          },
          {
            "parentEnumValue": "1501",
            "enumValue": "150121",
            "enumName": "土默特左旗"
          },
          {
            "parentEnumValue": "1501",
            "enumValue": "150122",
            "enumName": "托克托县"
          },
          {
            "parentEnumValue": "1501",
            "enumValue": "150123",
            "enumName": "和林格尔县"
          },
          {
            "parentEnumValue": "1501",
            "enumValue": "150124",
            "enumName": "清水河县"
          },
          {
            "parentEnumValue": "1501",
            "enumValue": "150125",
            "enumName": "武川县"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1502",
        "enumName": "包头市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "1502",
            "enumValue": "150200",
            "enumName": "包头市本级"
          },
          {
            "parentEnumValue": "1502",
            "enumValue": "150202",
            "enumName": "东河区"
          },
          {
            "parentEnumValue": "1502",
            "enumValue": "150203",
            "enumName": "昆都仑区"
          },
          {
            "parentEnumValue": "1502",
            "enumValue": "150204",
            "enumName": "青山区"
          },
          {
            "parentEnumValue": "1502",
            "enumValue": "150205",
            "enumName": "石拐区"
          },
          {
            "parentEnumValue": "1502",
            "enumValue": "150206",
            "enumName": "白云鄂博矿区"
          },
          {
            "parentEnumValue": "1502",
            "enumValue": "150207",
            "enumName": "九原区"
          },
          {
            "parentEnumValue": "1502",
            "enumValue": "150221",
            "enumName": "土默特右旗"
          },
          {
            "parentEnumValue": "1502",
            "enumValue": "150222",
            "enumName": "固阳县"
          },
          {
            "parentEnumValue": "1502",
            "enumValue": "150223",
            "enumName": "达尔罕茂明安联合旗"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1503",
        "enumName": "乌海市",
        "children": [
          {
            "parentEnumValue": "1503",
            "enumValue": "150300",
            "enumName": "乌海市本级"
          },
          {
            "parentEnumValue": "1503",
            "enumValue": "150302",
            "enumName": "海勃湾区"
          },
          {
            "parentEnumValue": "1503",
            "enumValue": "150303",
            "enumName": "海南区"
          },
          {
            "parentEnumValue": "1503",
            "enumValue": "150304",
            "enumName": "乌达区"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1504",
        "enumName": "赤峰市",
        "children": [
          {
            "parentEnumValue": "1504",
            "enumValue": "150400",
            "enumName": "赤峰市本级"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150402",
            "enumName": "红山区"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150403",
            "enumName": "元宝山区"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150404",
            "enumName": "松山区"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150421",
            "enumName": "阿鲁科尔沁旗"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150422",
            "enumName": "巴林左旗"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150423",
            "enumName": "巴林右旗"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150424",
            "enumName": "林西县"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150425",
            "enumName": "克什克腾旗"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150426",
            "enumName": "翁牛特旗"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150428",
            "enumName": "喀喇沁旗"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150429",
            "enumName": "宁城县"
          },
          {
            "parentEnumValue": "1504",
            "enumValue": "150430",
            "enumName": "敖汉旗"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1505",
        "enumName": "通辽市",
        "children": [
          {
            "parentEnumValue": "1505",
            "enumValue": "150500",
            "enumName": "通辽市本级"
          },
          {
            "parentEnumValue": "1505",
            "enumValue": "150502",
            "enumName": "科尔沁区"
          },
          {
            "parentEnumValue": "1505",
            "enumValue": "150521",
            "enumName": "科尔沁左翼中旗"
          },
          {
            "parentEnumValue": "1505",
            "enumValue": "150522",
            "enumName": "科尔沁左翼后旗"
          },
          {
            "parentEnumValue": "1505",
            "enumValue": "150523",
            "enumName": "开鲁县"
          },
          {
            "parentEnumValue": "1505",
            "enumValue": "150524",
            "enumName": "库伦旗"
          },
          {
            "parentEnumValue": "1505",
            "enumValue": "150525",
            "enumName": "奈曼旗"
          },
          {
            "parentEnumValue": "1505",
            "enumValue": "150526",
            "enumName": "扎鲁特旗"
          },
          {
            "parentEnumValue": "1505",
            "enumValue": "150581",
            "enumName": "霍林郭勒市"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1506",
        "enumName": "鄂尔多斯市",
        "children": [
          {
            "parentEnumValue": "1506",
            "enumValue": "150603",
            "enumName": "康巴什区"
          },
          {
            "parentEnumValue": "1506",
            "enumValue": "150600",
            "enumName": "鄂尔多斯市本级"
          },
          {
            "parentEnumValue": "1506",
            "enumValue": "150602",
            "enumName": "东胜区"
          },
          {
            "parentEnumValue": "1506",
            "enumValue": "150621",
            "enumName": "达拉特旗"
          },
          {
            "parentEnumValue": "1506",
            "enumValue": "150622",
            "enumName": "准格尔旗"
          },
          {
            "parentEnumValue": "1506",
            "enumValue": "150623",
            "enumName": "鄂托克前旗"
          },
          {
            "parentEnumValue": "1506",
            "enumValue": "150624",
            "enumName": "鄂托克旗"
          },
          {
            "parentEnumValue": "1506",
            "enumValue": "150625",
            "enumName": "杭锦旗"
          },
          {
            "parentEnumValue": "1506",
            "enumValue": "150626",
            "enumName": "乌审旗"
          },
          {
            "parentEnumValue": "1506",
            "enumValue": "150627",
            "enumName": "伊金霍洛旗"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1507",
        "enumName": "呼伦贝尔市",
        "children": [
          {
            "parentEnumValue": "1507",
            "enumValue": "150703",
            "enumName": "扎赉诺尔区"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150700",
            "enumName": "呼伦贝尔市本级"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150702",
            "enumName": "海拉尔区"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150721",
            "enumName": "阿荣旗"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150722",
            "enumName": "莫力达瓦达斡尔族自治旗"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150723",
            "enumName": "鄂伦春自治旗"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150724",
            "enumName": "鄂温克族自治旗"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150725",
            "enumName": "陈巴尔虎旗"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150726",
            "enumName": "新巴尔虎左旗"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150727",
            "enumName": "新巴尔虎右旗"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150781",
            "enumName": "满洲里市"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150782",
            "enumName": "牙克石市"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150783",
            "enumName": "扎兰屯市"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150784",
            "enumName": "额尔古纳市"
          },
          {
            "parentEnumValue": "1507",
            "enumValue": "150785",
            "enumName": "根河市"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1508",
        "enumName": "巴彦淖尔市",
        "children": [
          {
            "parentEnumValue": "1508",
            "enumValue": "150800",
            "enumName": "巴彦淖尔市本级"
          },
          {
            "parentEnumValue": "1508",
            "enumValue": "150802",
            "enumName": "临河区"
          },
          {
            "parentEnumValue": "1508",
            "enumValue": "150821",
            "enumName": "五原县"
          },
          {
            "parentEnumValue": "1508",
            "enumValue": "150822",
            "enumName": "磴口县"
          },
          {
            "parentEnumValue": "1508",
            "enumValue": "150823",
            "enumName": "乌拉特前旗"
          },
          {
            "parentEnumValue": "1508",
            "enumValue": "150824",
            "enumName": "乌拉特中旗"
          },
          {
            "parentEnumValue": "1508",
            "enumValue": "150825",
            "enumName": "乌拉特后旗"
          },
          {
            "parentEnumValue": "1508",
            "enumValue": "150826",
            "enumName": "杭锦后旗"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1509",
        "enumName": "乌兰察布市",
        "children": [
          {
            "parentEnumValue": "1509",
            "enumValue": "150900",
            "enumName": "乌兰察布市本级"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150902",
            "enumName": "集宁区"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150921",
            "enumName": "卓资县"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150922",
            "enumName": "化德县"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150923",
            "enumName": "商都县"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150924",
            "enumName": "兴和县"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150925",
            "enumName": "凉城县"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150926",
            "enumName": "察哈尔右翼前旗"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150927",
            "enumName": "察哈尔右翼中旗"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150928",
            "enumName": "察哈尔右翼后旗"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150929",
            "enumName": "四子王旗"
          },
          {
            "parentEnumValue": "1509",
            "enumValue": "150981",
            "enumName": "丰镇市"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1522",
        "enumName": "兴安盟",
        "children": [
          {
            "parentEnumValue": "1522",
            "enumValue": "152200",
            "enumName": "兴安盟本级"
          },
          {
            "parentEnumValue": "1522",
            "enumValue": "152201",
            "enumName": "乌兰浩特市"
          },
          {
            "parentEnumValue": "1522",
            "enumValue": "152202",
            "enumName": "阿尔山市"
          },
          {
            "parentEnumValue": "1522",
            "enumValue": "152221",
            "enumName": "科尔沁右翼前旗"
          },
          {
            "parentEnumValue": "1522",
            "enumValue": "152222",
            "enumName": "科尔沁右翼中旗"
          },
          {
            "parentEnumValue": "1522",
            "enumValue": "152223",
            "enumName": "扎赉特旗"
          },
          {
            "parentEnumValue": "1522",
            "enumValue": "152224",
            "enumName": "突泉县"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1525",
        "enumName": "锡林郭勒盟",
        "children": [
          {
            "parentEnumValue": "1525",
            "enumValue": "152500",
            "enumName": "锡林郭勒盟本级"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152501",
            "enumName": "二连浩特市"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152502",
            "enumName": "锡林浩特市"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152522",
            "enumName": "阿巴嘎旗"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152523",
            "enumName": "苏尼特左旗"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152524",
            "enumName": "苏尼特右旗"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152525",
            "enumName": "东乌珠穆沁旗"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152526",
            "enumName": "西乌珠穆沁旗"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152527",
            "enumName": "太仆寺旗"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152528",
            "enumName": "镶黄旗"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152529",
            "enumName": "正镶白旗"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152530",
            "enumName": "正蓝旗"
          },
          {
            "parentEnumValue": "1525",
            "enumValue": "152531",
            "enumName": "多伦县"
          }
        ]
      },
      {
        "parentEnumValue": "15",
        "enumValue": "1529",
        "enumName": "阿拉善盟",
        "children": [
          {
            "parentEnumValue": "1529",
            "enumValue": "152900",
            "enumName": "阿拉善盟本级"
          },
          {
            "parentEnumValue": "1529",
            "enumValue": "152921",
            "enumName": "阿拉善左旗"
          },
          {
            "parentEnumValue": "1529",
            "enumValue": "152922",
            "enumName": "阿拉善右旗"
          },
          {
            "parentEnumValue": "1529",
            "enumValue": "152923",
            "enumName": "额济纳旗"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "21",
    "enumName": "辽宁省",
    "children": [
      {
        "parentEnumValue": "21",
        "enumValue": "2101",
        "enumName": "沈阳市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2101",
            "enumValue": "210100",
            "enumName": "沈阳市本级"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210115",
            "enumName": "辽中区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210102",
            "enumName": "和平区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210103",
            "enumName": "沈河区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210104",
            "enumName": "大东区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210105",
            "enumName": "皇姑区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210106",
            "enumName": "铁西区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210111",
            "enumName": "苏家屯区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210112",
            "enumName": "浑南区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210113",
            "enumName": "沈北新区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210114",
            "enumName": "于洪区"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210122",
            "enumName": "辽中县"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210123",
            "enumName": "康平县"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210124",
            "enumName": "法库县"
          },
          {
            "parentEnumValue": "2101",
            "enumValue": "210181",
            "enumName": "新民市"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2102",
        "enumName": "大连市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2102",
            "enumValue": "210214",
            "enumName": "普兰店区"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210200",
            "enumName": "大连市本级"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210202",
            "enumName": "中山区"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210203",
            "enumName": "西岗区"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210204",
            "enumName": "沙河口区"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210211",
            "enumName": "甘井子区"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210212",
            "enumName": "旅顺口区"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210213",
            "enumName": "金州区"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210224",
            "enumName": "长海县"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210281",
            "enumName": "瓦房店市"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210282",
            "enumName": "普兰店市"
          },
          {
            "parentEnumValue": "2102",
            "enumValue": "210283",
            "enumName": "庄河市"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2103",
        "enumName": "鞍山市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2103",
            "enumValue": "210300",
            "enumName": "鞍山市本级"
          },
          {
            "parentEnumValue": "2103",
            "enumValue": "210302",
            "enumName": "铁东区"
          },
          {
            "parentEnumValue": "2103",
            "enumValue": "210303",
            "enumName": "铁西区"
          },
          {
            "parentEnumValue": "2103",
            "enumValue": "210304",
            "enumName": "立山区"
          },
          {
            "parentEnumValue": "2103",
            "enumValue": "210311",
            "enumName": "千山区"
          },
          {
            "parentEnumValue": "2103",
            "enumValue": "210321",
            "enumName": "台安县"
          },
          {
            "parentEnumValue": "2103",
            "enumValue": "210323",
            "enumName": "岫岩满族自治县"
          },
          {
            "parentEnumValue": "2103",
            "enumValue": "210381",
            "enumName": "海城市"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2104",
        "enumName": "抚顺市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2104",
            "enumValue": "210400",
            "enumName": "抚顺市本级"
          },
          {
            "parentEnumValue": "2104",
            "enumValue": "210402",
            "enumName": "新抚区"
          },
          {
            "parentEnumValue": "2104",
            "enumValue": "210403",
            "enumName": "东洲区"
          },
          {
            "parentEnumValue": "2104",
            "enumValue": "210404",
            "enumName": "望花区"
          },
          {
            "parentEnumValue": "2104",
            "enumValue": "210411",
            "enumName": "顺城区"
          },
          {
            "parentEnumValue": "2104",
            "enumValue": "210421",
            "enumName": "抚顺县"
          },
          {
            "parentEnumValue": "2104",
            "enumValue": "210422",
            "enumName": "新宾满族自治县"
          },
          {
            "parentEnumValue": "2104",
            "enumValue": "210423",
            "enumName": "清原满族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2105",
        "enumName": "本溪市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2105",
            "enumValue": "210500",
            "enumName": "本溪市本级"
          },
          {
            "parentEnumValue": "2105",
            "enumValue": "210502",
            "enumName": "平山区"
          },
          {
            "parentEnumValue": "2105",
            "enumValue": "210503",
            "enumName": "溪湖区"
          },
          {
            "parentEnumValue": "2105",
            "enumValue": "210504",
            "enumName": "明山区"
          },
          {
            "parentEnumValue": "2105",
            "enumValue": "210505",
            "enumName": "南芬区"
          },
          {
            "parentEnumValue": "2105",
            "enumValue": "210521",
            "enumName": "本溪满族自治县"
          },
          {
            "parentEnumValue": "2105",
            "enumValue": "210522",
            "enumName": "桓仁满族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2106",
        "enumName": "丹东市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2106",
            "enumValue": "210600",
            "enumName": "丹东市本级"
          },
          {
            "parentEnumValue": "2106",
            "enumValue": "210602",
            "enumName": "元宝区"
          },
          {
            "parentEnumValue": "2106",
            "enumValue": "210603",
            "enumName": "振兴区"
          },
          {
            "parentEnumValue": "2106",
            "enumValue": "210604",
            "enumName": "振安区"
          },
          {
            "parentEnumValue": "2106",
            "enumValue": "210624",
            "enumName": "宽甸满族自治县"
          },
          {
            "parentEnumValue": "2106",
            "enumValue": "210681",
            "enumName": "东港市"
          },
          {
            "parentEnumValue": "2106",
            "enumValue": "210682",
            "enumName": "凤城市"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2107",
        "enumName": "锦州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2107",
            "enumValue": "210700",
            "enumName": "锦州市本级"
          },
          {
            "parentEnumValue": "2107",
            "enumValue": "210702",
            "enumName": "古塔区"
          },
          {
            "parentEnumValue": "2107",
            "enumValue": "210703",
            "enumName": "凌河区"
          },
          {
            "parentEnumValue": "2107",
            "enumValue": "210711",
            "enumName": "太和区"
          },
          {
            "parentEnumValue": "2107",
            "enumValue": "210726",
            "enumName": "黑山县"
          },
          {
            "parentEnumValue": "2107",
            "enumValue": "210727",
            "enumName": "义县"
          },
          {
            "parentEnumValue": "2107",
            "enumValue": "210781",
            "enumName": "凌海市"
          },
          {
            "parentEnumValue": "2107",
            "enumValue": "210782",
            "enumName": "北镇市"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2108",
        "enumName": "营口市",
        "children": [
          {
            "parentEnumValue": "2108",
            "enumValue": "210800",
            "enumName": "营口市本级"
          },
          {
            "parentEnumValue": "2108",
            "enumValue": "210802",
            "enumName": "站前区"
          },
          {
            "parentEnumValue": "2108",
            "enumValue": "210803",
            "enumName": "西市区"
          },
          {
            "parentEnumValue": "2108",
            "enumValue": "210804",
            "enumName": "鲅鱼圈区"
          },
          {
            "parentEnumValue": "2108",
            "enumValue": "210811",
            "enumName": "老边区"
          },
          {
            "parentEnumValue": "2108",
            "enumValue": "210881",
            "enumName": "盖州市"
          },
          {
            "parentEnumValue": "2108",
            "enumValue": "210882",
            "enumName": "大石桥市"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2109",
        "enumName": "阜新市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2109",
            "enumValue": "210900",
            "enumName": "阜新市本级"
          },
          {
            "parentEnumValue": "2109",
            "enumValue": "210902",
            "enumName": "海州区"
          },
          {
            "parentEnumValue": "2109",
            "enumValue": "210903",
            "enumName": "新邱区"
          },
          {
            "parentEnumValue": "2109",
            "enumValue": "210904",
            "enumName": "太平区"
          },
          {
            "parentEnumValue": "2109",
            "enumValue": "210905",
            "enumName": "清河门区"
          },
          {
            "parentEnumValue": "2109",
            "enumValue": "210911",
            "enumName": "细河区"
          },
          {
            "parentEnumValue": "2109",
            "enumValue": "210921",
            "enumName": "阜新蒙古族自治县"
          },
          {
            "parentEnumValue": "2109",
            "enumValue": "210922",
            "enumName": "彰武县"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2110",
        "enumName": "辽阳市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2110",
            "enumValue": "211000",
            "enumName": "辽阳市本级"
          },
          {
            "parentEnumValue": "2110",
            "enumValue": "211002",
            "enumName": "白塔区"
          },
          {
            "parentEnumValue": "2110",
            "enumValue": "211003",
            "enumName": "文圣区"
          },
          {
            "parentEnumValue": "2110",
            "enumValue": "211004",
            "enumName": "宏伟区"
          },
          {
            "parentEnumValue": "2110",
            "enumValue": "211005",
            "enumName": "弓长岭区"
          },
          {
            "parentEnumValue": "2110",
            "enumValue": "211011",
            "enumName": "太子河区"
          },
          {
            "parentEnumValue": "2110",
            "enumValue": "211021",
            "enumName": "辽阳县"
          },
          {
            "parentEnumValue": "2110",
            "enumValue": "211081",
            "enumName": "灯塔市"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2111",
        "enumName": "盘锦市",
        "children": [
          {
            "parentEnumValue": "2111",
            "enumValue": "211104",
            "enumName": "大洼区"
          },
          {
            "parentEnumValue": "2111",
            "enumValue": "211100",
            "enumName": "盘锦市本级"
          },
          {
            "parentEnumValue": "2111",
            "enumValue": "211102",
            "enumName": "双台子区"
          },
          {
            "parentEnumValue": "2111",
            "enumValue": "211103",
            "enumName": "兴隆台区"
          },
          {
            "parentEnumValue": "2111",
            "enumValue": "211121",
            "enumName": "大洼县"
          },
          {
            "parentEnumValue": "2111",
            "enumValue": "211122",
            "enumName": "盘山县"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2112",
        "enumName": "铁岭市",
        "children": [
          {
            "parentEnumValue": "2112",
            "enumValue": "211200",
            "enumName": "铁岭市本级"
          },
          {
            "parentEnumValue": "2112",
            "enumValue": "211202",
            "enumName": "银州区"
          },
          {
            "parentEnumValue": "2112",
            "enumValue": "211204",
            "enumName": "清河区"
          },
          {
            "parentEnumValue": "2112",
            "enumValue": "211221",
            "enumName": "铁岭县"
          },
          {
            "parentEnumValue": "2112",
            "enumValue": "211223",
            "enumName": "西丰县"
          },
          {
            "parentEnumValue": "2112",
            "enumValue": "211224",
            "enumName": "昌图县"
          },
          {
            "parentEnumValue": "2112",
            "enumValue": "211281",
            "enumName": "调兵山市"
          },
          {
            "parentEnumValue": "2112",
            "enumValue": "211282",
            "enumName": "开原市"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2113",
        "enumName": "朝阳市",
        "children": [
          {
            "parentEnumValue": "2113",
            "enumValue": "211300",
            "enumName": "朝阳市本级"
          },
          {
            "parentEnumValue": "2113",
            "enumValue": "211302",
            "enumName": "双塔区"
          },
          {
            "parentEnumValue": "2113",
            "enumValue": "211303",
            "enumName": "龙城区"
          },
          {
            "parentEnumValue": "2113",
            "enumValue": "211321",
            "enumName": "朝阳县"
          },
          {
            "parentEnumValue": "2113",
            "enumValue": "211322",
            "enumName": "建平县"
          },
          {
            "parentEnumValue": "2113",
            "enumValue": "211324",
            "enumName": "喀喇沁左翼蒙古族自治县"
          },
          {
            "parentEnumValue": "2113",
            "enumValue": "211381",
            "enumName": "北票市"
          },
          {
            "parentEnumValue": "2113",
            "enumValue": "211382",
            "enumName": "凌源市"
          }
        ]
      },
      {
        "parentEnumValue": "21",
        "enumValue": "2114",
        "enumName": "葫芦岛市",
        "children": [
          {
            "parentEnumValue": "2114",
            "enumValue": "211400",
            "enumName": "葫芦岛市本级"
          },
          {
            "parentEnumValue": "2114",
            "enumValue": "211402",
            "enumName": "连山区"
          },
          {
            "parentEnumValue": "2114",
            "enumValue": "211403",
            "enumName": "龙港区"
          },
          {
            "parentEnumValue": "2114",
            "enumValue": "211404",
            "enumName": "南票区"
          },
          {
            "parentEnumValue": "2114",
            "enumValue": "211421",
            "enumName": "绥中县"
          },
          {
            "parentEnumValue": "2114",
            "enumValue": "211422",
            "enumName": "建昌县"
          },
          {
            "parentEnumValue": "2114",
            "enumValue": "211481",
            "enumName": "兴城市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "22",
    "enumName": "吉林省",
    "children": [
      {
        "parentEnumValue": "22",
        "enumValue": "2201",
        "enumName": "长春市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2201",
            "enumValue": "220100",
            "enumName": "长春市本级"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220184",
            "enumName": "公主岭市"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220113",
            "enumName": "九台区"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220102",
            "enumName": "南关区"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220103",
            "enumName": "宽城区"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220104",
            "enumName": "朝阳区"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220105",
            "enumName": "二道区"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220106",
            "enumName": "绿园区"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220112",
            "enumName": "双阳区"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220122",
            "enumName": "农安县"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220181",
            "enumName": "九台市"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220182",
            "enumName": "榆树市"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220183",
            "enumName": "德惠市"
          },
          {
            "parentEnumValue": "2201",
            "enumValue": "220173",
            "enumName": "长春高新技术产业开发区"
          }
        ]
      },
      {
        "parentEnumValue": "22",
        "enumValue": "2202",
        "enumName": "吉林市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2202",
            "enumValue": "220200",
            "enumName": "吉林市本级"
          },
          {
            "parentEnumValue": "2202",
            "enumValue": "220202",
            "enumName": "昌邑区"
          },
          {
            "parentEnumValue": "2202",
            "enumValue": "220203",
            "enumName": "龙潭区"
          },
          {
            "parentEnumValue": "2202",
            "enumValue": "220204",
            "enumName": "船营区"
          },
          {
            "parentEnumValue": "2202",
            "enumValue": "220211",
            "enumName": "丰满区"
          },
          {
            "parentEnumValue": "2202",
            "enumValue": "220221",
            "enumName": "永吉县"
          },
          {
            "parentEnumValue": "2202",
            "enumValue": "220281",
            "enumName": "蛟河市"
          },
          {
            "parentEnumValue": "2202",
            "enumValue": "220282",
            "enumName": "桦甸市"
          },
          {
            "parentEnumValue": "2202",
            "enumValue": "220283",
            "enumName": "舒兰市"
          },
          {
            "parentEnumValue": "2202",
            "enumValue": "220284",
            "enumName": "磐石市"
          }
        ]
      },
      {
        "parentEnumValue": "22",
        "enumValue": "2203",
        "enumName": "四平市",
        "children": [
          {
            "parentEnumValue": "2203",
            "enumValue": "220300",
            "enumName": "四平市本级"
          },
          {
            "parentEnumValue": "2203",
            "enumValue": "220302",
            "enumName": "铁西区"
          },
          {
            "parentEnumValue": "2203",
            "enumValue": "220303",
            "enumName": "铁东区"
          },
          {
            "parentEnumValue": "2203",
            "enumValue": "220322",
            "enumName": "梨树县"
          },
          {
            "parentEnumValue": "2203",
            "enumValue": "220323",
            "enumName": "伊通满族自治县"
          },
          {
            "parentEnumValue": "2203",
            "enumValue": "220381",
            "enumName": "公主岭市"
          },
          {
            "parentEnumValue": "2203",
            "enumValue": "220382",
            "enumName": "双辽市"
          }
        ]
      },
      {
        "parentEnumValue": "22",
        "enumValue": "2204",
        "enumName": "辽源市",
        "children": [
          {
            "parentEnumValue": "2204",
            "enumValue": "220400",
            "enumName": "辽源市本级"
          },
          {
            "parentEnumValue": "2204",
            "enumValue": "220402",
            "enumName": "龙山区"
          },
          {
            "parentEnumValue": "2204",
            "enumValue": "220403",
            "enumName": "西安区"
          },
          {
            "parentEnumValue": "2204",
            "enumValue": "220421",
            "enumName": "东丰县"
          },
          {
            "parentEnumValue": "2204",
            "enumValue": "220422",
            "enumName": "东辽县"
          }
        ]
      },
      {
        "parentEnumValue": "22",
        "enumValue": "2205",
        "enumName": "通化市",
        "children": [
          {
            "parentEnumValue": "2205",
            "enumValue": "220500",
            "enumName": "通化市本级"
          },
          {
            "parentEnumValue": "2205",
            "enumValue": "220502",
            "enumName": "东昌区"
          },
          {
            "parentEnumValue": "2205",
            "enumValue": "220503",
            "enumName": "二道江区"
          },
          {
            "parentEnumValue": "2205",
            "enumValue": "220521",
            "enumName": "通化县"
          },
          {
            "parentEnumValue": "2205",
            "enumValue": "220523",
            "enumName": "辉南县"
          },
          {
            "parentEnumValue": "2205",
            "enumValue": "220524",
            "enumName": "柳河县"
          },
          {
            "parentEnumValue": "2205",
            "enumValue": "220581",
            "enumName": "梅河口市"
          },
          {
            "parentEnumValue": "2205",
            "enumValue": "220582",
            "enumName": "集安市"
          }
        ]
      },
      {
        "parentEnumValue": "22",
        "enumValue": "2206",
        "enumName": "白山市",
        "children": [
          {
            "parentEnumValue": "2206",
            "enumValue": "220600",
            "enumName": "白山市本级"
          },
          {
            "parentEnumValue": "2206",
            "enumValue": "220602",
            "enumName": "浑江区"
          },
          {
            "parentEnumValue": "2206",
            "enumValue": "220605",
            "enumName": "江源区"
          },
          {
            "parentEnumValue": "2206",
            "enumValue": "220621",
            "enumName": "抚松县"
          },
          {
            "parentEnumValue": "2206",
            "enumValue": "220622",
            "enumName": "靖宇县"
          },
          {
            "parentEnumValue": "2206",
            "enumValue": "220623",
            "enumName": "长白朝鲜族自治县"
          },
          {
            "parentEnumValue": "2206",
            "enumValue": "220681",
            "enumName": "临江市"
          }
        ]
      },
      {
        "parentEnumValue": "22",
        "enumValue": "2207",
        "enumName": "松原市",
        "children": [
          {
            "parentEnumValue": "2207",
            "enumValue": "220781",
            "enumName": "扶余市"
          },
          {
            "parentEnumValue": "2207",
            "enumValue": "220700",
            "enumName": "松原市本级"
          },
          {
            "parentEnumValue": "2207",
            "enumValue": "220702",
            "enumName": "宁江区"
          },
          {
            "parentEnumValue": "2207",
            "enumValue": "220721",
            "enumName": "前郭尔罗斯蒙古族自治县"
          },
          {
            "parentEnumValue": "2207",
            "enumValue": "220722",
            "enumName": "长岭县"
          },
          {
            "parentEnumValue": "2207",
            "enumValue": "220723",
            "enumName": "乾安县"
          },
          {
            "parentEnumValue": "2207",
            "enumValue": "220724",
            "enumName": "扶余县"
          }
        ]
      },
      {
        "parentEnumValue": "22",
        "enumValue": "2208",
        "enumName": "白城市",
        "children": [
          {
            "parentEnumValue": "2208",
            "enumValue": "220800",
            "enumName": "白城市本级"
          },
          {
            "parentEnumValue": "2208",
            "enumValue": "220802",
            "enumName": "洮北区"
          },
          {
            "parentEnumValue": "2208",
            "enumValue": "220821",
            "enumName": "镇赉县"
          },
          {
            "parentEnumValue": "2208",
            "enumValue": "220822",
            "enumName": "通榆县"
          },
          {
            "parentEnumValue": "2208",
            "enumValue": "220881",
            "enumName": "洮南市"
          },
          {
            "parentEnumValue": "2208",
            "enumValue": "220882",
            "enumName": "大安市"
          }
        ]
      },
      {
        "parentEnumValue": "22",
        "enumValue": "2224",
        "enumName": "延边朝鲜族自治州",
        "children": [
          {
            "parentEnumValue": "2224",
            "enumValue": "222400",
            "enumName": "延边朝鲜族自治州本级"
          },
          {
            "parentEnumValue": "2224",
            "enumValue": "222401",
            "enumName": "延吉市"
          },
          {
            "parentEnumValue": "2224",
            "enumValue": "222402",
            "enumName": "图们市"
          },
          {
            "parentEnumValue": "2224",
            "enumValue": "222403",
            "enumName": "敦化市"
          },
          {
            "parentEnumValue": "2224",
            "enumValue": "222404",
            "enumName": "珲春市"
          },
          {
            "parentEnumValue": "2224",
            "enumValue": "222405",
            "enumName": "龙井市"
          },
          {
            "parentEnumValue": "2224",
            "enumValue": "222406",
            "enumName": "和龙市"
          },
          {
            "parentEnumValue": "2224",
            "enumValue": "222424",
            "enumName": "汪清县"
          },
          {
            "parentEnumValue": "2224",
            "enumValue": "222426",
            "enumName": "安图县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "23",
    "enumName": "黑龙江省",
    "children": [
      {
        "parentEnumValue": "23",
        "enumValue": "2391",
        "enumName": "黑龙江农垦总局",
        "children": [
          {
            "parentEnumValue": "2391",
            "enumValue": "239111",
            "enumName": "直属佳南分局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239109",
            "enumName": "绥化分局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239110",
            "enumName": "哈尔滨分局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239108",
            "enumName": "齐齐哈尔分局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239107",
            "enumName": "九三分局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239106",
            "enumName": "北安分局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239104",
            "enumName": "建三江分局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239103",
            "enumName": "红兴隆分局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239101",
            "enumName": "宝泉岭分局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239102",
            "enumName": "国土资源局(撤)"
          },
          {
            "parentEnumValue": "2391",
            "enumValue": "239105",
            "enumName": "牡丹江分局(撤)"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2392",
        "enumName": "黑龙江森工总局(撤)",
        "children": [
          {
            "parentEnumValue": "2392",
            "enumValue": "239201",
            "enumName": "国土资源局(撤)"
          },
          {
            "parentEnumValue": "2392",
            "enumValue": "239202",
            "enumName": "松花江分局(撤)"
          },
          {
            "parentEnumValue": "2392",
            "enumValue": "239203",
            "enumName": "牡丹江分局(撤)"
          },
          {
            "parentEnumValue": "2392",
            "enumValue": "239204",
            "enumName": "合江分局(撤)"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2393",
        "enumName": "友谊国土资源局(撤)",
        "children": [
          {
            "parentEnumValue": "2393",
            "enumValue": "239300",
            "enumName": "友谊国土资源局(撤)"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2394",
        "enumName": "五大连池风景名胜区(撤)",
        "children": [
          {
            "parentEnumValue": "2394",
            "enumValue": "239400",
            "enumName": "五大连池风景名胜区(撤)"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2301",
        "enumName": "哈尔滨市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2301",
            "enumValue": "230100",
            "enumName": "哈尔滨市本级"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230113",
            "enumName": "双城区"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230102",
            "enumName": "道里区"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230103",
            "enumName": "南岗区"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230104",
            "enumName": "道外区"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230108",
            "enumName": "平房区"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230109",
            "enumName": "松北区"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230110",
            "enumName": "香坊区"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230111",
            "enumName": "呼兰区"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230112",
            "enumName": "阿城区"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230123",
            "enumName": "依兰县"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230124",
            "enumName": "方正县"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230125",
            "enumName": "宾县"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230126",
            "enumName": "巴彦县"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230127",
            "enumName": "木兰县"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230128",
            "enumName": "通河县"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230129",
            "enumName": "延寿县"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230182",
            "enumName": "双城市"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230183",
            "enumName": "尚志市"
          },
          {
            "parentEnumValue": "2301",
            "enumValue": "230184",
            "enumName": "五常市"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2302",
        "enumName": "齐齐哈尔市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2302",
            "enumValue": "230200",
            "enumName": "齐齐哈尔市本级"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230202",
            "enumName": "龙沙区"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230203",
            "enumName": "建华区"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230204",
            "enumName": "铁锋区"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230205",
            "enumName": "昂昂溪区"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230206",
            "enumName": "富拉尔基区"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230207",
            "enumName": "碾子山区"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230208",
            "enumName": "梅里斯达斡尔族区"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230221",
            "enumName": "龙江县"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230223",
            "enumName": "依安县"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230224",
            "enumName": "泰来县"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230225",
            "enumName": "甘南县"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230227",
            "enumName": "富裕县"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230229",
            "enumName": "克山县"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230230",
            "enumName": "克东县"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230231",
            "enumName": "拜泉县"
          },
          {
            "parentEnumValue": "2302",
            "enumValue": "230281",
            "enumName": "讷河市"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2303",
        "enumName": "鸡西市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2303",
            "enumValue": "230300",
            "enumName": "鸡西市本级"
          },
          {
            "parentEnumValue": "2303",
            "enumValue": "230302",
            "enumName": "鸡冠区"
          },
          {
            "parentEnumValue": "2303",
            "enumValue": "230303",
            "enumName": "恒山区"
          },
          {
            "parentEnumValue": "2303",
            "enumValue": "230304",
            "enumName": "滴道区"
          },
          {
            "parentEnumValue": "2303",
            "enumValue": "230305",
            "enumName": "梨树区"
          },
          {
            "parentEnumValue": "2303",
            "enumValue": "230306",
            "enumName": "城子河区"
          },
          {
            "parentEnumValue": "2303",
            "enumValue": "230307",
            "enumName": "麻山区"
          },
          {
            "parentEnumValue": "2303",
            "enumValue": "230321",
            "enumName": "鸡东县"
          },
          {
            "parentEnumValue": "2303",
            "enumValue": "230381",
            "enumName": "虎林市"
          },
          {
            "parentEnumValue": "2303",
            "enumValue": "230382",
            "enumName": "密山市"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2304",
        "enumName": "鹤岗市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2304",
            "enumValue": "230400",
            "enumName": "鹤岗市本级"
          },
          {
            "parentEnumValue": "2304",
            "enumValue": "230402",
            "enumName": "向阳区"
          },
          {
            "parentEnumValue": "2304",
            "enumValue": "230403",
            "enumName": "工农区"
          },
          {
            "parentEnumValue": "2304",
            "enumValue": "230404",
            "enumName": "南山区"
          },
          {
            "parentEnumValue": "2304",
            "enumValue": "230405",
            "enumName": "兴安区"
          },
          {
            "parentEnumValue": "2304",
            "enumValue": "230406",
            "enumName": "东山区"
          },
          {
            "parentEnumValue": "2304",
            "enumValue": "230407",
            "enumName": "兴山区"
          },
          {
            "parentEnumValue": "2304",
            "enumValue": "230421",
            "enumName": "萝北县"
          },
          {
            "parentEnumValue": "2304",
            "enumValue": "230422",
            "enumName": "绥滨县"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2305",
        "enumName": "双鸭山市",
        "children": [
          {
            "parentEnumValue": "2305",
            "enumValue": "230500",
            "enumName": "双鸭山市本级"
          },
          {
            "parentEnumValue": "2305",
            "enumValue": "230502",
            "enumName": "尖山区"
          },
          {
            "parentEnumValue": "2305",
            "enumValue": "230503",
            "enumName": "岭东区"
          },
          {
            "parentEnumValue": "2305",
            "enumValue": "230505",
            "enumName": "四方台区"
          },
          {
            "parentEnumValue": "2305",
            "enumValue": "230506",
            "enumName": "宝山区"
          },
          {
            "parentEnumValue": "2305",
            "enumValue": "230521",
            "enumName": "集贤县"
          },
          {
            "parentEnumValue": "2305",
            "enumValue": "230522",
            "enumName": "友谊县"
          },
          {
            "parentEnumValue": "2305",
            "enumValue": "230523",
            "enumName": "宝清县"
          },
          {
            "parentEnumValue": "2305",
            "enumValue": "230524",
            "enumName": "饶河县"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2306",
        "enumName": "大庆市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2306",
            "enumValue": "230600",
            "enumName": "大庆市本级"
          },
          {
            "parentEnumValue": "2306",
            "enumValue": "230602",
            "enumName": "萨尔图区"
          },
          {
            "parentEnumValue": "2306",
            "enumValue": "230603",
            "enumName": "龙凤区"
          },
          {
            "parentEnumValue": "2306",
            "enumValue": "230604",
            "enumName": "让胡路区"
          },
          {
            "parentEnumValue": "2306",
            "enumValue": "230605",
            "enumName": "红岗区"
          },
          {
            "parentEnumValue": "2306",
            "enumValue": "230606",
            "enumName": "大同区"
          },
          {
            "parentEnumValue": "2306",
            "enumValue": "230621",
            "enumName": "肇州县"
          },
          {
            "parentEnumValue": "2306",
            "enumValue": "230622",
            "enumName": "肇源县"
          },
          {
            "parentEnumValue": "2306",
            "enumValue": "230623",
            "enumName": "林甸县"
          },
          {
            "parentEnumValue": "2306",
            "enumValue": "230624",
            "enumName": "杜尔伯特蒙古族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2307",
        "enumName": "伊春市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2307",
            "enumValue": "230717",
            "enumName": "伊美区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230718",
            "enumName": "乌翠区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230719",
            "enumName": "友好区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230723",
            "enumName": "汤旺县"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230724",
            "enumName": "丰林县"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230725",
            "enumName": "大箐山县"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230726",
            "enumName": "南岔县"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230751",
            "enumName": "金林区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230700",
            "enumName": "伊春市本级"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230702",
            "enumName": "伊春区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230703",
            "enumName": "南岔区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230704",
            "enumName": "友好区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230705",
            "enumName": "西林区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230706",
            "enumName": "翠峦区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230707",
            "enumName": "新青区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230708",
            "enumName": "美溪区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230709",
            "enumName": "金山屯区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230710",
            "enumName": "五营区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230711",
            "enumName": "乌马河区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230712",
            "enumName": "汤旺河区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230713",
            "enumName": "带岭区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230714",
            "enumName": "乌伊岭区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230715",
            "enumName": "红星区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230716",
            "enumName": "上甘岭区"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230722",
            "enumName": "嘉荫县"
          },
          {
            "parentEnumValue": "2307",
            "enumValue": "230781",
            "enumName": "铁力市"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2308",
        "enumName": "佳木斯市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2308",
            "enumValue": "230883",
            "enumName": "抚远市"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230800",
            "enumName": "佳木斯市本级"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230803",
            "enumName": "向阳区"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230804",
            "enumName": "前进区"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230805",
            "enumName": "东风区"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230811",
            "enumName": "郊区"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230822",
            "enumName": "桦南县"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230826",
            "enumName": "桦川县"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230828",
            "enumName": "汤原县"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230833",
            "enumName": "抚远县"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230881",
            "enumName": "同江市"
          },
          {
            "parentEnumValue": "2308",
            "enumValue": "230882",
            "enumName": "富锦市"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2309",
        "enumName": "七台河市",
        "children": [
          {
            "parentEnumValue": "2309",
            "enumValue": "230900",
            "enumName": "七台河市本级"
          },
          {
            "parentEnumValue": "2309",
            "enumValue": "230902",
            "enumName": "新兴区"
          },
          {
            "parentEnumValue": "2309",
            "enumValue": "230903",
            "enumName": "桃山区"
          },
          {
            "parentEnumValue": "2309",
            "enumValue": "230904",
            "enumName": "茄子河区"
          },
          {
            "parentEnumValue": "2309",
            "enumValue": "230921",
            "enumName": "勃利县"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2310",
        "enumName": "牡丹江市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "2310",
            "enumValue": "231086",
            "enumName": "东宁市"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231000",
            "enumName": "牡丹江市本级"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231002",
            "enumName": "东安区"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231003",
            "enumName": "阳明区"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231004",
            "enumName": "爱民区"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231005",
            "enumName": "西安区"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231024",
            "enumName": "东宁县"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231025",
            "enumName": "林口县"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231081",
            "enumName": "绥芬河市"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231083",
            "enumName": "海林市"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231084",
            "enumName": "宁安市"
          },
          {
            "parentEnumValue": "2310",
            "enumValue": "231085",
            "enumName": "穆棱市"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2311",
        "enumName": "黑河市",
        "children": [
          {
            "parentEnumValue": "2311",
            "enumValue": "231183",
            "enumName": "嫩江市"
          },
          {
            "parentEnumValue": "2311",
            "enumValue": "231100",
            "enumName": "黑河市本级"
          },
          {
            "parentEnumValue": "2311",
            "enumValue": "231102",
            "enumName": "爱辉区"
          },
          {
            "parentEnumValue": "2311",
            "enumValue": "231121",
            "enumName": "嫩江县"
          },
          {
            "parentEnumValue": "2311",
            "enumValue": "231123",
            "enumName": "逊克县"
          },
          {
            "parentEnumValue": "2311",
            "enumValue": "231124",
            "enumName": "孙吴县"
          },
          {
            "parentEnumValue": "2311",
            "enumValue": "231181",
            "enumName": "北安市"
          },
          {
            "parentEnumValue": "2311",
            "enumValue": "231182",
            "enumName": "五大连池市"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2312",
        "enumName": "绥化市",
        "children": [
          {
            "parentEnumValue": "2312",
            "enumValue": "231200",
            "enumName": "绥化市本级"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231202",
            "enumName": "北林区"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231221",
            "enumName": "望奎县"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231222",
            "enumName": "兰西县"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231223",
            "enumName": "青冈县"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231224",
            "enumName": "庆安县"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231225",
            "enumName": "明水县"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231226",
            "enumName": "绥棱县"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231281",
            "enumName": "安达市"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231282",
            "enumName": "肇东市"
          },
          {
            "parentEnumValue": "2312",
            "enumValue": "231283",
            "enumName": "海伦市"
          }
        ]
      },
      {
        "parentEnumValue": "23",
        "enumValue": "2327",
        "enumName": "大兴安岭地区",
        "children": [
          {
            "parentEnumValue": "2327",
            "enumValue": "232700",
            "enumName": "大兴安岭地区本级"
          },
          {
            "parentEnumValue": "2327",
            "enumValue": "232701",
            "enumName": "漠河市"
          },
          {
            "parentEnumValue": "2327",
            "enumValue": "232702",
            "enumName": "松岭区"
          },
          {
            "parentEnumValue": "2327",
            "enumValue": "232703",
            "enumName": "新林区"
          },
          {
            "parentEnumValue": "2327",
            "enumValue": "232704",
            "enumName": "呼中区"
          },
          {
            "parentEnumValue": "2327",
            "enumValue": "232721",
            "enumName": "呼玛县"
          },
          {
            "parentEnumValue": "2327",
            "enumValue": "232722",
            "enumName": "塔河县"
          },
          {
            "parentEnumValue": "2327",
            "enumValue": "232723",
            "enumName": "漠河县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "31",
    "enumName": "上海市",
    "enumDesc": "zdcs",
    "children": [
      {
        "parentEnumValue": "31",
        "enumValue": "3101",
        "enumName": "上海市",
        "children": [
          {
            "parentEnumValue": "3101",
            "enumValue": "310151",
            "enumName": "崇明区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310101",
            "enumName": "黄浦区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310100",
            "enumName": "上海市本级"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310230",
            "enumName": "崇明区(撤)"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310103",
            "enumName": "卢湾区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310104",
            "enumName": "徐汇区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310105",
            "enumName": "长宁区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310106",
            "enumName": "静安区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310107",
            "enumName": "普陀区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310108",
            "enumName": "闸北区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310109",
            "enumName": "虹口区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310110",
            "enumName": "杨浦区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310112",
            "enumName": "闵行区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310113",
            "enumName": "宝山区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310114",
            "enumName": "嘉定区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310115",
            "enumName": "浦东新区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310116",
            "enumName": "金山区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310117",
            "enumName": "松江区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310118",
            "enumName": "青浦区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310119",
            "enumName": "南汇区"
          },
          {
            "parentEnumValue": "3101",
            "enumValue": "310120",
            "enumName": "奉贤区"
          }
        ]
      },
      {
        "parentEnumValue": "31",
        "enumValue": "3102",
        "enumName": "上海市所辖县(撤)"
      }
    ]
  },
  {
    "enumValue": "32",
    "enumName": "江苏省",
    "children": [
      {
        "parentEnumValue": "32",
        "enumValue": "3201",
        "enumName": "南京市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3201",
            "enumValue": "320100",
            "enumName": "南京市本级"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320102",
            "enumName": "玄武区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320103",
            "enumName": "白下区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320104",
            "enumName": "秦淮区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320105",
            "enumName": "建邺区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320106",
            "enumName": "鼓楼区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320111",
            "enumName": "浦口区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320113",
            "enumName": "栖霞区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320114",
            "enumName": "雨花台区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320115",
            "enumName": "江宁区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320116",
            "enumName": "六合区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320107",
            "enumName": "下关区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320117",
            "enumName": "溧水区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320118",
            "enumName": "高淳区"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320124",
            "enumName": "溧水县"
          },
          {
            "parentEnumValue": "3201",
            "enumValue": "320125",
            "enumName": "高淳县"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3202",
        "enumName": "无锡市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3202",
            "enumValue": "320200",
            "enumName": "无锡市本级"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320202",
            "enumName": "崇安区"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320203",
            "enumName": "南长区"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320204",
            "enumName": "北塘区"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320205",
            "enumName": "锡山区"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320206",
            "enumName": "惠山区"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320211",
            "enumName": "滨湖区"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320281",
            "enumName": "江阴市"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320282",
            "enumName": "宜兴市"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320214",
            "enumName": "新吴区"
          },
          {
            "parentEnumValue": "3202",
            "enumValue": "320213",
            "enumName": "梁溪区"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3203",
        "enumName": "徐州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3203",
            "enumValue": "320312",
            "enumName": "铜山区"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320300",
            "enumName": "徐州市本级"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320302",
            "enumName": "鼓楼区"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320303",
            "enumName": "云龙区"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320304",
            "enumName": "九里区"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320305",
            "enumName": "贾汪区"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320311",
            "enumName": "泉山区"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320321",
            "enumName": "丰县"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320322",
            "enumName": "沛县"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320323",
            "enumName": "铜山县"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320324",
            "enumName": "睢宁县"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320381",
            "enumName": "新沂市"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320382",
            "enumName": "邳州市"
          },
          {
            "parentEnumValue": "3203",
            "enumValue": "320371",
            "enumName": "徐州经济开发区"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3204",
        "enumName": "常州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3204",
            "enumValue": "320413",
            "enumName": "金坛区"
          },
          {
            "parentEnumValue": "3204",
            "enumValue": "320400",
            "enumName": "常州市本级"
          },
          {
            "parentEnumValue": "3204",
            "enumValue": "320402",
            "enumName": "天宁区"
          },
          {
            "parentEnumValue": "3204",
            "enumValue": "320404",
            "enumName": "钟楼区"
          },
          {
            "parentEnumValue": "3204",
            "enumValue": "320405",
            "enumName": "戚墅堰区"
          },
          {
            "parentEnumValue": "3204",
            "enumValue": "320411",
            "enumName": "新北区"
          },
          {
            "parentEnumValue": "3204",
            "enumValue": "320412",
            "enumName": "武进区"
          },
          {
            "parentEnumValue": "3204",
            "enumValue": "320481",
            "enumName": "溧阳市"
          },
          {
            "parentEnumValue": "3204",
            "enumValue": "320482",
            "enumName": "金坛市"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3205",
        "enumName": "苏州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3205",
            "enumValue": "320500",
            "enumName": "苏州市本级"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320502",
            "enumName": "沧浪区"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320503",
            "enumName": "平江区"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320504",
            "enumName": "金阊区"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320505",
            "enumName": "虎丘区"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320506",
            "enumName": "吴中区"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320507",
            "enumName": "相城区"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320581",
            "enumName": "常熟市"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320582",
            "enumName": "张家港市"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320583",
            "enumName": "昆山市"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320584",
            "enumName": "吴江区"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320585",
            "enumName": "太仓市"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320509",
            "enumName": "吴江区"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320508",
            "enumName": "姑苏区"
          },
          {
            "parentEnumValue": "3205",
            "enumValue": "320571",
            "enumName": "苏州工业园区"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3206",
        "enumName": "南通市",
        "children": [
          {
            "parentEnumValue": "3206",
            "enumValue": "320600",
            "enumName": "南通市本级"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320602",
            "enumName": "崇川区"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320611",
            "enumName": "港闸区"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320621",
            "enumName": "海安县"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320623",
            "enumName": "如东县"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320681",
            "enumName": "启东市"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320682",
            "enumName": "如皋市"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320683",
            "enumName": "通州市"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320684",
            "enumName": "海门市"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320612",
            "enumName": "通州区"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320614",
            "enumName": "海门区"
          },
          {
            "parentEnumValue": "3206",
            "enumValue": "320685",
            "enumName": "海安市"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3207",
        "enumName": "连云港市",
        "children": [
          {
            "parentEnumValue": "3207",
            "enumValue": "320707",
            "enumName": "赣榆区"
          },
          {
            "parentEnumValue": "3207",
            "enumValue": "320700",
            "enumName": "连云港市本级"
          },
          {
            "parentEnumValue": "3207",
            "enumValue": "320703",
            "enumName": "连云区"
          },
          {
            "parentEnumValue": "3207",
            "enumValue": "320705",
            "enumName": "新浦区"
          },
          {
            "parentEnumValue": "3207",
            "enumValue": "320706",
            "enumName": "海州区"
          },
          {
            "parentEnumValue": "3207",
            "enumValue": "320721",
            "enumName": "赣榆县"
          },
          {
            "parentEnumValue": "3207",
            "enumValue": "320722",
            "enumName": "东海县"
          },
          {
            "parentEnumValue": "3207",
            "enumValue": "320723",
            "enumName": "灌云县"
          },
          {
            "parentEnumValue": "3207",
            "enumValue": "320724",
            "enumName": "灌南县"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3208",
        "enumName": "淮安市",
        "children": [
          {
            "parentEnumValue": "3208",
            "enumValue": "320813",
            "enumName": "洪泽区"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320800",
            "enumName": "淮安市本级"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320802",
            "enumName": "清河区"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320803",
            "enumName": "淮安区"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320804",
            "enumName": "淮阴区"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320811",
            "enumName": "清浦区"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320826",
            "enumName": "涟水县"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320829",
            "enumName": "洪泽县"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320830",
            "enumName": "盱眙县"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320831",
            "enumName": "金湖县"
          },
          {
            "parentEnumValue": "3208",
            "enumValue": "320812",
            "enumName": "清江浦区"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3209",
        "enumName": "盐城市",
        "children": [
          {
            "parentEnumValue": "3209",
            "enumValue": "320904",
            "enumName": "大丰区"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320900",
            "enumName": "盐城市本级"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320902",
            "enumName": "亭湖区"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320903",
            "enumName": "盐都区"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320921",
            "enumName": "响水县"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320922",
            "enumName": "滨海县"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320923",
            "enumName": "阜宁县"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320924",
            "enumName": "射阳县"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320925",
            "enumName": "建湖县"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320981",
            "enumName": "东台市"
          },
          {
            "parentEnumValue": "3209",
            "enumValue": "320982",
            "enumName": "大丰市"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3210",
        "enumName": "扬州市",
        "children": [
          {
            "parentEnumValue": "3210",
            "enumValue": "321012",
            "enumName": "江都区"
          },
          {
            "parentEnumValue": "3210",
            "enumValue": "321000",
            "enumName": "扬州市本级"
          },
          {
            "parentEnumValue": "3210",
            "enumValue": "321002",
            "enumName": "广陵区"
          },
          {
            "parentEnumValue": "3210",
            "enumValue": "321003",
            "enumName": "邗江区"
          },
          {
            "parentEnumValue": "3210",
            "enumValue": "321011",
            "enumName": "维扬区"
          },
          {
            "parentEnumValue": "3210",
            "enumValue": "321023",
            "enumName": "宝应县"
          },
          {
            "parentEnumValue": "3210",
            "enumValue": "321081",
            "enumName": "仪征市"
          },
          {
            "parentEnumValue": "3210",
            "enumValue": "321084",
            "enumName": "高邮市"
          },
          {
            "parentEnumValue": "3210",
            "enumValue": "321088",
            "enumName": "江都市"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3211",
        "enumName": "镇江市",
        "children": [
          {
            "parentEnumValue": "3211",
            "enumValue": "321100",
            "enumName": "镇江市本级"
          },
          {
            "parentEnumValue": "3211",
            "enumValue": "321102",
            "enumName": "京口区"
          },
          {
            "parentEnumValue": "3211",
            "enumValue": "321111",
            "enumName": "润州区"
          },
          {
            "parentEnumValue": "3211",
            "enumValue": "321112",
            "enumName": "丹徒区"
          },
          {
            "parentEnumValue": "3211",
            "enumValue": "321181",
            "enumName": "丹阳市"
          },
          {
            "parentEnumValue": "3211",
            "enumValue": "321182",
            "enumName": "扬中市"
          },
          {
            "parentEnumValue": "3211",
            "enumValue": "321183",
            "enumName": "句容市"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3212",
        "enumName": "泰州市",
        "children": [
          {
            "parentEnumValue": "3212",
            "enumValue": "321204",
            "enumName": "姜堰区"
          },
          {
            "parentEnumValue": "3212",
            "enumValue": "321200",
            "enumName": "泰州市本级"
          },
          {
            "parentEnumValue": "3212",
            "enumValue": "321202",
            "enumName": "海陵区"
          },
          {
            "parentEnumValue": "3212",
            "enumValue": "321203",
            "enumName": "高港区"
          },
          {
            "parentEnumValue": "3212",
            "enumValue": "321281",
            "enumName": "兴化市"
          },
          {
            "parentEnumValue": "3212",
            "enumValue": "321282",
            "enumName": "靖江市"
          },
          {
            "parentEnumValue": "3212",
            "enumValue": "321283",
            "enumName": "泰兴市"
          },
          {
            "parentEnumValue": "3212",
            "enumValue": "321284",
            "enumName": "姜堰市"
          }
        ]
      },
      {
        "parentEnumValue": "32",
        "enumValue": "3213",
        "enumName": "宿迁市",
        "children": [
          {
            "parentEnumValue": "3213",
            "enumValue": "321300",
            "enumName": "宿迁市本级"
          },
          {
            "parentEnumValue": "3213",
            "enumValue": "321302",
            "enumName": "宿城区"
          },
          {
            "parentEnumValue": "3213",
            "enumValue": "321311",
            "enumName": "宿豫区"
          },
          {
            "parentEnumValue": "3213",
            "enumValue": "321322",
            "enumName": "沭阳县"
          },
          {
            "parentEnumValue": "3213",
            "enumValue": "321323",
            "enumName": "泗阳县"
          },
          {
            "parentEnumValue": "3213",
            "enumValue": "321324",
            "enumName": "泗洪县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "33",
    "enumName": "浙江省",
    "children": [
      {
        "parentEnumValue": "33",
        "enumValue": "3301",
        "enumName": "杭州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3301",
            "enumValue": "330100",
            "enumName": "杭州市本级"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330112",
            "enumName": "临安区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330111",
            "enumName": "富阳区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330102",
            "enumName": "上城区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330103",
            "enumName": "下城区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330104",
            "enumName": "江干区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330105",
            "enumName": "拱墅区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330106",
            "enumName": "西湖区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330108",
            "enumName": "滨江区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330109",
            "enumName": "萧山区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330181",
            "enumName": "萧山市"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330110",
            "enumName": "余杭区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330122",
            "enumName": "桐庐县"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330127",
            "enumName": "淳安县"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330182",
            "enumName": "建德市"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330183",
            "enumName": "富阳市"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330185",
            "enumName": "临安市"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330113",
            "enumName": "临平区"
          },
          {
            "parentEnumValue": "3301",
            "enumValue": "330114",
            "enumName": "钱塘区"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3302",
        "enumName": "宁波市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3302",
            "enumValue": "330213",
            "enumName": "奉化区"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330200",
            "enumName": "宁波市本级"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330203",
            "enumName": "海曙区"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330204",
            "enumName": "江东区"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330205",
            "enumName": "江北区"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330206",
            "enumName": "北仑区"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330211",
            "enumName": "镇海区"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330212",
            "enumName": "鄞州区"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330225",
            "enumName": "象山县"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330226",
            "enumName": "宁海县"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330281",
            "enumName": "余姚市"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330282",
            "enumName": "慈溪市"
          },
          {
            "parentEnumValue": "3302",
            "enumValue": "330283",
            "enumName": "奉化市"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3303",
        "enumName": "温州市",
        "children": [
          {
            "parentEnumValue": "3303",
            "enumValue": "330305",
            "enumName": "洞头区"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330300",
            "enumName": "温州市本级"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330302",
            "enumName": "鹿城区"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330303",
            "enumName": "龙湾区"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330304",
            "enumName": "瓯海区"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330322",
            "enumName": "洞头县"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330324",
            "enumName": "永嘉县"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330326",
            "enumName": "平阳县"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330327",
            "enumName": "苍南县"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330328",
            "enumName": "文成县"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330329",
            "enumName": "泰顺县"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330381",
            "enumName": "瑞安市"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330382",
            "enumName": "乐清市"
          },
          {
            "parentEnumValue": "3303",
            "enumValue": "330383",
            "enumName": "龙港市"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3304",
        "enumName": "嘉兴市",
        "children": [
          {
            "parentEnumValue": "3304",
            "enumValue": "330400",
            "enumName": "嘉兴市本级"
          },
          {
            "parentEnumValue": "3304",
            "enumValue": "330402",
            "enumName": "南湖区"
          },
          {
            "parentEnumValue": "3304",
            "enumValue": "330411",
            "enumName": "秀洲区"
          },
          {
            "parentEnumValue": "3304",
            "enumValue": "330421",
            "enumName": "嘉善县"
          },
          {
            "parentEnumValue": "3304",
            "enumValue": "330424",
            "enumName": "海盐县"
          },
          {
            "parentEnumValue": "3304",
            "enumValue": "330481",
            "enumName": "海宁市"
          },
          {
            "parentEnumValue": "3304",
            "enumValue": "330482",
            "enumName": "平湖市"
          },
          {
            "parentEnumValue": "3304",
            "enumValue": "330483",
            "enumName": "桐乡市"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3305",
        "enumName": "湖州市",
        "children": [
          {
            "parentEnumValue": "3305",
            "enumValue": "330504",
            "enumName": "湖州经济开发区"
          },
          {
            "parentEnumValue": "3305",
            "enumValue": "330500",
            "enumName": "湖州市本级"
          },
          {
            "parentEnumValue": "3305",
            "enumValue": "330502",
            "enumName": "吴兴区"
          },
          {
            "parentEnumValue": "3305",
            "enumValue": "330503",
            "enumName": "南浔区"
          },
          {
            "parentEnumValue": "3305",
            "enumValue": "330521",
            "enumName": "德清县"
          },
          {
            "parentEnumValue": "3305",
            "enumValue": "330522",
            "enumName": "长兴县"
          },
          {
            "parentEnumValue": "3305",
            "enumValue": "330523",
            "enumName": "安吉县"
          },
          {
            "parentEnumValue": "3305",
            "enumValue": "330501",
            "enumName": "市辖区"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3306",
        "enumName": "绍兴市",
        "children": [
          {
            "parentEnumValue": "3306",
            "enumValue": "330604",
            "enumName": "上虞区"
          },
          {
            "parentEnumValue": "3306",
            "enumValue": "330600",
            "enumName": "绍兴市本级"
          },
          {
            "parentEnumValue": "3306",
            "enumValue": "330602",
            "enumName": "越城区"
          },
          {
            "parentEnumValue": "3306",
            "enumValue": "330621",
            "enumName": "绍兴县"
          },
          {
            "parentEnumValue": "3306",
            "enumValue": "330624",
            "enumName": "新昌县"
          },
          {
            "parentEnumValue": "3306",
            "enumValue": "330681",
            "enumName": "诸暨市"
          },
          {
            "parentEnumValue": "3306",
            "enumValue": "330682",
            "enumName": "上虞市"
          },
          {
            "parentEnumValue": "3306",
            "enumValue": "330683",
            "enumName": "嵊州市"
          },
          {
            "parentEnumValue": "3306",
            "enumValue": "330603",
            "enumName": "柯桥区"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3307",
        "enumName": "金华市",
        "children": [
          {
            "parentEnumValue": "3307",
            "enumValue": "330700",
            "enumName": "金华市本级"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330702",
            "enumName": "婺城区"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330703",
            "enumName": "金东区"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330721",
            "enumName": "金华县"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330723",
            "enumName": "武义县"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330726",
            "enumName": "浦江县"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330727",
            "enumName": "磐安县"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330781",
            "enumName": "兰溪市"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330782",
            "enumName": "义乌市"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330783",
            "enumName": "东阳市"
          },
          {
            "parentEnumValue": "3307",
            "enumValue": "330784",
            "enumName": "永康市"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3308",
        "enumName": "衢州市",
        "children": [
          {
            "parentEnumValue": "3308",
            "enumValue": "330821",
            "enumName": "衢江县"
          },
          {
            "parentEnumValue": "3308",
            "enumValue": "330800",
            "enumName": "衢州市本级"
          },
          {
            "parentEnumValue": "3308",
            "enumValue": "330802",
            "enumName": "柯城区"
          },
          {
            "parentEnumValue": "3308",
            "enumValue": "330803",
            "enumName": "衢江区"
          },
          {
            "parentEnumValue": "3308",
            "enumValue": "330822",
            "enumName": "常山县"
          },
          {
            "parentEnumValue": "3308",
            "enumValue": "330824",
            "enumName": "开化县"
          },
          {
            "parentEnumValue": "3308",
            "enumValue": "330825",
            "enumName": "龙游县"
          },
          {
            "parentEnumValue": "3308",
            "enumValue": "330881",
            "enumName": "江山市"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3309",
        "enumName": "舟山市",
        "children": [
          {
            "parentEnumValue": "3309",
            "enumValue": "330900",
            "enumName": "舟山市本级"
          },
          {
            "parentEnumValue": "3309",
            "enumValue": "330902",
            "enumName": "定海区"
          },
          {
            "parentEnumValue": "3309",
            "enumValue": "330903",
            "enumName": "普陀区"
          },
          {
            "parentEnumValue": "3309",
            "enumValue": "330921",
            "enumName": "岱山县"
          },
          {
            "parentEnumValue": "3309",
            "enumValue": "330922",
            "enumName": "嵊泗县"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3310",
        "enumName": "台州市",
        "children": [
          {
            "parentEnumValue": "3310",
            "enumValue": "331083",
            "enumName": "玉环市"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331000",
            "enumName": "台州市本级"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331002",
            "enumName": "椒江区"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331003",
            "enumName": "黄岩区"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331004",
            "enumName": "路桥区"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331021",
            "enumName": "玉环县"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331022",
            "enumName": "三门县"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331023",
            "enumName": "天台县"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331024",
            "enumName": "仙居县"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331081",
            "enumName": "温岭市"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331082",
            "enumName": "临海市"
          },
          {
            "parentEnumValue": "3310",
            "enumValue": "331099",
            "enumName": "台州经济开发区"
          }
        ]
      },
      {
        "parentEnumValue": "33",
        "enumValue": "3311",
        "enumName": "丽水市",
        "children": [
          {
            "parentEnumValue": "3311",
            "enumValue": "331100",
            "enumName": "丽水市本级"
          },
          {
            "parentEnumValue": "3311",
            "enumValue": "331102",
            "enumName": "莲都区"
          },
          {
            "parentEnumValue": "3311",
            "enumValue": "331121",
            "enumName": "青田县"
          },
          {
            "parentEnumValue": "3311",
            "enumValue": "331122",
            "enumName": "缙云县"
          },
          {
            "parentEnumValue": "3311",
            "enumValue": "331123",
            "enumName": "遂昌县"
          },
          {
            "parentEnumValue": "3311",
            "enumValue": "331124",
            "enumName": "松阳县"
          },
          {
            "parentEnumValue": "3311",
            "enumValue": "331125",
            "enumName": "云和县"
          },
          {
            "parentEnumValue": "3311",
            "enumValue": "331126",
            "enumName": "庆元县"
          },
          {
            "parentEnumValue": "3311",
            "enumValue": "331127",
            "enumName": "景宁畲族自治县"
          },
          {
            "parentEnumValue": "3311",
            "enumValue": "331181",
            "enumName": "龙泉市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "34",
    "enumName": "安徽省",
    "children": [
      {
        "parentEnumValue": "34",
        "enumValue": "3401",
        "enumName": "合肥市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3401",
            "enumValue": "341421",
            "enumName": "庐江县"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340100",
            "enumName": "合肥市本级"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340124",
            "enumName": "庐江县"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340102",
            "enumName": "瑶海区"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340103",
            "enumName": "庐阳区"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340104",
            "enumName": "蜀山区"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340111",
            "enumName": "包河区"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340121",
            "enumName": "长丰县"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340122",
            "enumName": "肥东县"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340123",
            "enumName": "肥西县"
          },
          {
            "parentEnumValue": "3401",
            "enumValue": "340181",
            "enumName": "巢湖市"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3402",
        "enumName": "芜湖市",
        "children": [
          {
            "parentEnumValue": "3402",
            "enumValue": "340281",
            "enumName": "无为市"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340225",
            "enumName": "无为县"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340211",
            "enumName": "繁昌区"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340209",
            "enumName": "弋江区"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340210",
            "enumName": "湾沚区"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340200",
            "enumName": "芜湖市本级"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340202",
            "enumName": "镜湖区"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340203",
            "enumName": "弋江区"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340207",
            "enumName": "鸠江区"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340208",
            "enumName": "三山区"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340221",
            "enumName": "芜湖县"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340222",
            "enumName": "繁昌县"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "340223",
            "enumName": "南陵县"
          },
          {
            "parentEnumValue": "3402",
            "enumValue": "341422",
            "enumName": "无为县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3403",
        "enumName": "蚌埠市",
        "children": [
          {
            "parentEnumValue": "3403",
            "enumValue": "340300",
            "enumName": "蚌埠市本级"
          },
          {
            "parentEnumValue": "3403",
            "enumValue": "340302",
            "enumName": "龙子湖区"
          },
          {
            "parentEnumValue": "3403",
            "enumValue": "340303",
            "enumName": "蚌山区"
          },
          {
            "parentEnumValue": "3403",
            "enumValue": "340304",
            "enumName": "禹会区"
          },
          {
            "parentEnumValue": "3403",
            "enumValue": "340311",
            "enumName": "淮上区"
          },
          {
            "parentEnumValue": "3403",
            "enumValue": "340321",
            "enumName": "怀远县"
          },
          {
            "parentEnumValue": "3403",
            "enumValue": "340322",
            "enumName": "五河县"
          },
          {
            "parentEnumValue": "3403",
            "enumValue": "340323",
            "enumName": "固镇县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3404",
        "enumName": "淮南市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3404",
            "enumValue": "340422",
            "enumName": "寿县"
          },
          {
            "parentEnumValue": "3404",
            "enumValue": "340400",
            "enumName": "淮南市本级"
          },
          {
            "parentEnumValue": "3404",
            "enumValue": "340402",
            "enumName": "大通区"
          },
          {
            "parentEnumValue": "3404",
            "enumValue": "340403",
            "enumName": "田家庵区"
          },
          {
            "parentEnumValue": "3404",
            "enumValue": "340404",
            "enumName": "谢家集区"
          },
          {
            "parentEnumValue": "3404",
            "enumValue": "340405",
            "enumName": "八公山区"
          },
          {
            "parentEnumValue": "3404",
            "enumValue": "340406",
            "enumName": "潘集区"
          },
          {
            "parentEnumValue": "3404",
            "enumValue": "340421",
            "enumName": "凤台县"
          },
          {
            "parentEnumValue": "3404",
            "enumValue": "341521",
            "enumName": "寿县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3405",
        "enumName": "马鞍山市",
        "children": [
          {
            "parentEnumValue": "3405",
            "enumValue": "340500",
            "enumName": "马鞍山市本级"
          },
          {
            "parentEnumValue": "3405",
            "enumValue": "340502",
            "enumName": "金家庄区"
          },
          {
            "parentEnumValue": "3405",
            "enumValue": "340503",
            "enumName": "花山区"
          },
          {
            "parentEnumValue": "3405",
            "enumValue": "340504",
            "enumName": "雨山区"
          },
          {
            "parentEnumValue": "3405",
            "enumValue": "340521",
            "enumName": "当涂县"
          },
          {
            "parentEnumValue": "3405",
            "enumValue": "340506",
            "enumName": "博望区"
          },
          {
            "parentEnumValue": "3405",
            "enumValue": "340522",
            "enumName": "含山县"
          },
          {
            "parentEnumValue": "3405",
            "enumValue": "340523",
            "enumName": "和县"
          },
          {
            "parentEnumValue": "3405",
            "enumValue": "341424",
            "enumName": "和县"
          },
          {
            "parentEnumValue": "3405",
            "enumValue": "341423",
            "enumName": "含山县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3406",
        "enumName": "淮北市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3406",
            "enumValue": "340600",
            "enumName": "淮北市本级"
          },
          {
            "parentEnumValue": "3406",
            "enumValue": "340602",
            "enumName": "杜集区"
          },
          {
            "parentEnumValue": "3406",
            "enumValue": "340603",
            "enumName": "相山区"
          },
          {
            "parentEnumValue": "3406",
            "enumValue": "340604",
            "enumName": "烈山区"
          },
          {
            "parentEnumValue": "3406",
            "enumValue": "340621",
            "enumName": "濉溪县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3407",
        "enumName": "铜陵市",
        "children": [
          {
            "parentEnumValue": "3407",
            "enumValue": "340705",
            "enumName": "铜官区"
          },
          {
            "parentEnumValue": "3407",
            "enumValue": "340700",
            "enumName": "铜陵市本级"
          },
          {
            "parentEnumValue": "3407",
            "enumValue": "340702",
            "enumName": "铜官山区"
          },
          {
            "parentEnumValue": "3407",
            "enumValue": "340703",
            "enumName": "狮子山区"
          },
          {
            "parentEnumValue": "3407",
            "enumValue": "340711",
            "enumName": "郊区"
          },
          {
            "parentEnumValue": "3407",
            "enumValue": "340706",
            "enumName": "义安区"
          },
          {
            "parentEnumValue": "3407",
            "enumValue": "340721",
            "enumName": "铜陵县"
          },
          {
            "parentEnumValue": "3407",
            "enumValue": "340722",
            "enumName": "枞阳县"
          },
          {
            "parentEnumValue": "3407",
            "enumValue": "340823",
            "enumName": "枞阳县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3408",
        "enumName": "安庆市",
        "children": [
          {
            "parentEnumValue": "3408",
            "enumValue": "340800",
            "enumName": "安庆市本级"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340802",
            "enumName": "迎江区"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340803",
            "enumName": "大观区"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340811",
            "enumName": "宜秀区"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340822",
            "enumName": "怀宁县"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340824",
            "enumName": "潜山县"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340825",
            "enumName": "太湖县"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340826",
            "enumName": "宿松县"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340827",
            "enumName": "望江县"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340828",
            "enumName": "岳西县"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340881",
            "enumName": "桐城市"
          },
          {
            "parentEnumValue": "3408",
            "enumValue": "340882",
            "enumName": "潜山市"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3410",
        "enumName": "黄山市",
        "children": [
          {
            "parentEnumValue": "3410",
            "enumValue": "341000",
            "enumName": "黄山市本级"
          },
          {
            "parentEnumValue": "3410",
            "enumValue": "341002",
            "enumName": "屯溪区"
          },
          {
            "parentEnumValue": "3410",
            "enumValue": "341003",
            "enumName": "黄山区"
          },
          {
            "parentEnumValue": "3410",
            "enumValue": "341004",
            "enumName": "徽州区"
          },
          {
            "parentEnumValue": "3410",
            "enumValue": "341021",
            "enumName": "歙县"
          },
          {
            "parentEnumValue": "3410",
            "enumValue": "341022",
            "enumName": "休宁县"
          },
          {
            "parentEnumValue": "3410",
            "enumValue": "341023",
            "enumName": "黟县"
          },
          {
            "parentEnumValue": "3410",
            "enumValue": "341024",
            "enumName": "祁门县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3411",
        "enumName": "滁州市",
        "children": [
          {
            "parentEnumValue": "3411",
            "enumValue": "341100",
            "enumName": "滁州市本级"
          },
          {
            "parentEnumValue": "3411",
            "enumValue": "341102",
            "enumName": "琅琊区"
          },
          {
            "parentEnumValue": "3411",
            "enumValue": "341103",
            "enumName": "南谯区"
          },
          {
            "parentEnumValue": "3411",
            "enumValue": "341122",
            "enumName": "来安县"
          },
          {
            "parentEnumValue": "3411",
            "enumValue": "341124",
            "enumName": "全椒县"
          },
          {
            "parentEnumValue": "3411",
            "enumValue": "341125",
            "enumName": "定远县"
          },
          {
            "parentEnumValue": "3411",
            "enumValue": "341126",
            "enumName": "凤阳县"
          },
          {
            "parentEnumValue": "3411",
            "enumValue": "341181",
            "enumName": "天长市"
          },
          {
            "parentEnumValue": "3411",
            "enumValue": "341182",
            "enumName": "明光市"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3412",
        "enumName": "阜阳市",
        "children": [
          {
            "parentEnumValue": "3412",
            "enumValue": "341200",
            "enumName": "阜阳市本级"
          },
          {
            "parentEnumValue": "3412",
            "enumValue": "341202",
            "enumName": "颍州区"
          },
          {
            "parentEnumValue": "3412",
            "enumValue": "341203",
            "enumName": "颍东区"
          },
          {
            "parentEnumValue": "3412",
            "enumValue": "341204",
            "enumName": "颍泉区"
          },
          {
            "parentEnumValue": "3412",
            "enumValue": "341221",
            "enumName": "临泉县"
          },
          {
            "parentEnumValue": "3412",
            "enumValue": "341222",
            "enumName": "太和县"
          },
          {
            "parentEnumValue": "3412",
            "enumValue": "341225",
            "enumName": "阜南县"
          },
          {
            "parentEnumValue": "3412",
            "enumValue": "341226",
            "enumName": "颍上县"
          },
          {
            "parentEnumValue": "3412",
            "enumValue": "341282",
            "enumName": "界首市"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3413",
        "enumName": "宿州市",
        "children": [
          {
            "parentEnumValue": "3413",
            "enumValue": "341300",
            "enumName": "宿州市本级"
          },
          {
            "parentEnumValue": "3413",
            "enumValue": "341302",
            "enumName": "埇桥区"
          },
          {
            "parentEnumValue": "3413",
            "enumValue": "341321",
            "enumName": "砀山县"
          },
          {
            "parentEnumValue": "3413",
            "enumValue": "341322",
            "enumName": "萧县"
          },
          {
            "parentEnumValue": "3413",
            "enumValue": "341323",
            "enumName": "灵璧县"
          },
          {
            "parentEnumValue": "3413",
            "enumValue": "341324",
            "enumName": "泗县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3414",
        "enumName": "巢湖市",
        "children": [
          {
            "parentEnumValue": "3414",
            "enumValue": "341400",
            "enumName": "巢湖市"
          },
          {
            "parentEnumValue": "3414",
            "enumValue": "341402",
            "enumName": "居巢区"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3415",
        "enumName": "六安市",
        "children": [
          {
            "parentEnumValue": "3415",
            "enumValue": "341500",
            "enumName": "六安市本级"
          },
          {
            "parentEnumValue": "3415",
            "enumValue": "341502",
            "enumName": "金安区"
          },
          {
            "parentEnumValue": "3415",
            "enumValue": "341503",
            "enumName": "裕安区"
          },
          {
            "parentEnumValue": "3415",
            "enumValue": "341522",
            "enumName": "霍邱县"
          },
          {
            "parentEnumValue": "3415",
            "enumValue": "341523",
            "enumName": "舒城县"
          },
          {
            "parentEnumValue": "3415",
            "enumValue": "341524",
            "enumName": "金寨县"
          },
          {
            "parentEnumValue": "3415",
            "enumValue": "341525",
            "enumName": "霍山县"
          },
          {
            "parentEnumValue": "3415",
            "enumValue": "341504",
            "enumName": "叶集区"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3416",
        "enumName": "亳州市",
        "children": [
          {
            "parentEnumValue": "3416",
            "enumValue": "341600",
            "enumName": "亳州市本级"
          },
          {
            "parentEnumValue": "3416",
            "enumValue": "341602",
            "enumName": "谯城区"
          },
          {
            "parentEnumValue": "3416",
            "enumValue": "341621",
            "enumName": "涡阳县"
          },
          {
            "parentEnumValue": "3416",
            "enumValue": "341622",
            "enumName": "蒙城县"
          },
          {
            "parentEnumValue": "3416",
            "enumValue": "341623",
            "enumName": "利辛县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3417",
        "enumName": "池州市",
        "children": [
          {
            "parentEnumValue": "3417",
            "enumValue": "341700",
            "enumName": "池州市本级"
          },
          {
            "parentEnumValue": "3417",
            "enumValue": "341702",
            "enumName": "贵池区"
          },
          {
            "parentEnumValue": "3417",
            "enumValue": "341721",
            "enumName": "东至县"
          },
          {
            "parentEnumValue": "3417",
            "enumValue": "341722",
            "enumName": "石台县"
          },
          {
            "parentEnumValue": "3417",
            "enumValue": "341723",
            "enumName": "青阳县"
          }
        ]
      },
      {
        "parentEnumValue": "34",
        "enumValue": "3418",
        "enumName": "宣城市",
        "children": [
          {
            "parentEnumValue": "3418",
            "enumValue": "341882",
            "enumName": "广德市"
          },
          {
            "parentEnumValue": "3418",
            "enumValue": "341800",
            "enumName": "宣城市本级"
          },
          {
            "parentEnumValue": "3418",
            "enumValue": "341802",
            "enumName": "宣州区"
          },
          {
            "parentEnumValue": "3418",
            "enumValue": "341821",
            "enumName": "郎溪县"
          },
          {
            "parentEnumValue": "3418",
            "enumValue": "341822",
            "enumName": "广德县"
          },
          {
            "parentEnumValue": "3418",
            "enumValue": "341823",
            "enumName": "泾县"
          },
          {
            "parentEnumValue": "3418",
            "enumValue": "341824",
            "enumName": "绩溪县"
          },
          {
            "parentEnumValue": "3418",
            "enumValue": "341825",
            "enumName": "旌德县"
          },
          {
            "parentEnumValue": "3418",
            "enumValue": "341881",
            "enumName": "宁国市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "35",
    "enumName": "福建省",
    "children": [
      {
        "parentEnumValue": "35",
        "enumValue": "3501",
        "enumName": "福州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3501",
            "enumValue": "350100",
            "enumName": "福州市本级"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350102",
            "enumName": "鼓楼区"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350103",
            "enumName": "台江区"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350104",
            "enumName": "仓山区"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350105",
            "enumName": "马尾区"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350111",
            "enumName": "晋安区"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350121",
            "enumName": "闽侯县"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350122",
            "enumName": "连江县"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350123",
            "enumName": "罗源县"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350124",
            "enumName": "闽清县"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350125",
            "enumName": "永泰县"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350128",
            "enumName": "平潭县"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350181",
            "enumName": "福清市"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350182",
            "enumName": "长乐市"
          },
          {
            "parentEnumValue": "3501",
            "enumValue": "350112",
            "enumName": "长乐区"
          }
        ]
      },
      {
        "parentEnumValue": "35",
        "enumValue": "3502",
        "enumName": "厦门市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3502",
            "enumValue": "350200",
            "enumName": "厦门市本级"
          },
          {
            "parentEnumValue": "3502",
            "enumValue": "350203",
            "enumName": "思明区"
          },
          {
            "parentEnumValue": "3502",
            "enumValue": "350205",
            "enumName": "海沧区"
          },
          {
            "parentEnumValue": "3502",
            "enumValue": "350206",
            "enumName": "湖里区"
          },
          {
            "parentEnumValue": "3502",
            "enumValue": "350211",
            "enumName": "集美区"
          },
          {
            "parentEnumValue": "3502",
            "enumValue": "350212",
            "enumName": "同安区"
          },
          {
            "parentEnumValue": "3502",
            "enumValue": "350213",
            "enumName": "翔安区"
          }
        ]
      },
      {
        "parentEnumValue": "35",
        "enumValue": "3503",
        "enumName": "莆田市",
        "children": [
          {
            "parentEnumValue": "3503",
            "enumValue": "350300",
            "enumName": "莆田市本级"
          },
          {
            "parentEnumValue": "3503",
            "enumValue": "350302",
            "enumName": "城厢区"
          },
          {
            "parentEnumValue": "3503",
            "enumValue": "350303",
            "enumName": "涵江区"
          },
          {
            "parentEnumValue": "3503",
            "enumValue": "350304",
            "enumName": "荔城区"
          },
          {
            "parentEnumValue": "3503",
            "enumValue": "350305",
            "enumName": "秀屿区"
          },
          {
            "parentEnumValue": "3503",
            "enumValue": "350322",
            "enumName": "仙游县"
          }
        ]
      },
      {
        "parentEnumValue": "35",
        "enumValue": "3504",
        "enumName": "三明市",
        "children": [
          {
            "parentEnumValue": "3504",
            "enumValue": "350405",
            "enumName": "沙县区"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350404",
            "enumName": "三元区"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350400",
            "enumName": "三明市本级"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350402",
            "enumName": "梅列区"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350403",
            "enumName": "三元区"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350421",
            "enumName": "明溪县"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350423",
            "enumName": "清流县"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350424",
            "enumName": "宁化县"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350425",
            "enumName": "大田县"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350426",
            "enumName": "尤溪县"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350427",
            "enumName": "沙县"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350428",
            "enumName": "将乐县"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350429",
            "enumName": "泰宁县"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350430",
            "enumName": "建宁县"
          },
          {
            "parentEnumValue": "3504",
            "enumValue": "350481",
            "enumName": "永安市"
          }
        ]
      },
      {
        "parentEnumValue": "35",
        "enumValue": "3505",
        "enumName": "泉州市",
        "children": [
          {
            "parentEnumValue": "3505",
            "enumValue": "350500",
            "enumName": "泉州市本级"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350502",
            "enumName": "鲤城区"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350503",
            "enumName": "丰泽区"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350504",
            "enumName": "洛江区"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350505",
            "enumName": "泉港区"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350521",
            "enumName": "惠安县"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350524",
            "enumName": "安溪县"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350525",
            "enumName": "永春县"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350526",
            "enumName": "德化县"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350527",
            "enumName": "金门县"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350581",
            "enumName": "石狮市"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350582",
            "enumName": "晋江市"
          },
          {
            "parentEnumValue": "3505",
            "enumValue": "350583",
            "enumName": "南安市"
          }
        ]
      },
      {
        "parentEnumValue": "35",
        "enumValue": "3506",
        "enumName": "漳州市",
        "children": [
          {
            "parentEnumValue": "3506",
            "enumValue": "350605",
            "enumName": "长泰区"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350604",
            "enumName": "龙海区"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350600",
            "enumName": "漳州市本级"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350602",
            "enumName": "芗城区"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350603",
            "enumName": "龙文区"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350622",
            "enumName": "云霄县"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350623",
            "enumName": "漳浦县"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350624",
            "enumName": "诏安县"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350625",
            "enumName": "长泰县"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350626",
            "enumName": "东山县"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350627",
            "enumName": "南靖县"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350628",
            "enumName": "平和县"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350629",
            "enumName": "华安县"
          },
          {
            "parentEnumValue": "3506",
            "enumValue": "350681",
            "enumName": "龙海市"
          }
        ]
      },
      {
        "parentEnumValue": "35",
        "enumValue": "3507",
        "enumName": "南平市",
        "children": [
          {
            "parentEnumValue": "3507",
            "enumValue": "350703",
            "enumName": "建阳区"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350700",
            "enumName": "南平市本级"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350702",
            "enumName": "延平区"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350721",
            "enumName": "顺昌县"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350722",
            "enumName": "浦城县"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350723",
            "enumName": "光泽县"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350724",
            "enumName": "松溪县"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350725",
            "enumName": "政和县"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350781",
            "enumName": "邵武市"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350782",
            "enumName": "武夷山市"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350783",
            "enumName": "建瓯市"
          },
          {
            "parentEnumValue": "3507",
            "enumValue": "350784",
            "enumName": "建阳市"
          }
        ]
      },
      {
        "parentEnumValue": "35",
        "enumValue": "3508",
        "enumName": "龙岩市",
        "children": [
          {
            "parentEnumValue": "3508",
            "enumValue": "350803",
            "enumName": "永定区"
          },
          {
            "parentEnumValue": "3508",
            "enumValue": "350800",
            "enumName": "龙岩市本级"
          },
          {
            "parentEnumValue": "3508",
            "enumValue": "350802",
            "enumName": "新罗区"
          },
          {
            "parentEnumValue": "3508",
            "enumValue": "350821",
            "enumName": "长汀县"
          },
          {
            "parentEnumValue": "3508",
            "enumValue": "350822",
            "enumName": "永定县"
          },
          {
            "parentEnumValue": "3508",
            "enumValue": "350823",
            "enumName": "上杭县"
          },
          {
            "parentEnumValue": "3508",
            "enumValue": "350824",
            "enumName": "武平县"
          },
          {
            "parentEnumValue": "3508",
            "enumValue": "350825",
            "enumName": "连城县"
          },
          {
            "parentEnumValue": "3508",
            "enumValue": "350881",
            "enumName": "漳平市"
          }
        ]
      },
      {
        "parentEnumValue": "35",
        "enumValue": "3509",
        "enumName": "宁德市",
        "children": [
          {
            "parentEnumValue": "3509",
            "enumValue": "350900",
            "enumName": "宁德市本级"
          },
          {
            "parentEnumValue": "3509",
            "enumValue": "350902",
            "enumName": "蕉城区"
          },
          {
            "parentEnumValue": "3509",
            "enumValue": "350921",
            "enumName": "霞浦县"
          },
          {
            "parentEnumValue": "3509",
            "enumValue": "350922",
            "enumName": "古田县"
          },
          {
            "parentEnumValue": "3509",
            "enumValue": "350923",
            "enumName": "屏南县"
          },
          {
            "parentEnumValue": "3509",
            "enumValue": "350924",
            "enumName": "寿宁县"
          },
          {
            "parentEnumValue": "3509",
            "enumValue": "350925",
            "enumName": "周宁县"
          },
          {
            "parentEnumValue": "3509",
            "enumValue": "350926",
            "enumName": "柘荣县"
          },
          {
            "parentEnumValue": "3509",
            "enumValue": "350981",
            "enumName": "福安市"
          },
          {
            "parentEnumValue": "3509",
            "enumValue": "350982",
            "enumName": "福鼎市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "36",
    "enumName": "江西省",
    "children": [
      {
        "parentEnumValue": "36",
        "enumValue": "3601",
        "enumName": "南昌市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3601",
            "enumValue": "360100",
            "enumName": "南昌市本级"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360112",
            "enumName": "新建区"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360113",
            "enumName": "红谷滩区"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360119",
            "enumName": "湾里管理局"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360118",
            "enumName": "高新区"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360117",
            "enumName": "经开区"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360102",
            "enumName": "东湖区"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360103",
            "enumName": "西湖区"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360104",
            "enumName": "青云谱区"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360105",
            "enumName": "湾里区"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360111",
            "enumName": "青山湖区"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360121",
            "enumName": "南昌县"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360122",
            "enumName": "新建县"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360123",
            "enumName": "安义县"
          },
          {
            "parentEnumValue": "3601",
            "enumValue": "360124",
            "enumName": "进贤县"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3602",
        "enumName": "景德镇市",
        "children": [
          {
            "parentEnumValue": "3602",
            "enumValue": "360200",
            "enumName": "景德镇市本级"
          },
          {
            "parentEnumValue": "3602",
            "enumValue": "360202",
            "enumName": "昌江区"
          },
          {
            "parentEnumValue": "3602",
            "enumValue": "360203",
            "enumName": "珠山区"
          },
          {
            "parentEnumValue": "3602",
            "enumValue": "360222",
            "enumName": "浮梁县"
          },
          {
            "parentEnumValue": "3602",
            "enumValue": "360281",
            "enumName": "乐平市"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3603",
        "enumName": "萍乡市",
        "children": [
          {
            "parentEnumValue": "3603",
            "enumValue": "360300",
            "enumName": "萍乡市本级"
          },
          {
            "parentEnumValue": "3603",
            "enumValue": "360302",
            "enumName": "安源区"
          },
          {
            "parentEnumValue": "3603",
            "enumValue": "360313",
            "enumName": "湘东区"
          },
          {
            "parentEnumValue": "3603",
            "enumValue": "360321",
            "enumName": "莲花县"
          },
          {
            "parentEnumValue": "3603",
            "enumValue": "360322",
            "enumName": "上栗县"
          },
          {
            "parentEnumValue": "3603",
            "enumValue": "360323",
            "enumName": "芦溪县"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3604",
        "enumName": "九江市",
        "children": [
          {
            "parentEnumValue": "3604",
            "enumValue": "360483",
            "enumName": "庐山市"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360404",
            "enumName": "柴桑区"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360400",
            "enumName": "九江市本级"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360402",
            "enumName": "濂溪区"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360403",
            "enumName": "浔阳区"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360421",
            "enumName": "九江县"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360423",
            "enumName": "武宁县"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360424",
            "enumName": "修水县"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360425",
            "enumName": "永修县"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360426",
            "enumName": "德安县"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360427",
            "enumName": "星子县"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360428",
            "enumName": "都昌县"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360429",
            "enumName": "湖口县"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360430",
            "enumName": "彭泽县"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360481",
            "enumName": "瑞昌市"
          },
          {
            "parentEnumValue": "3604",
            "enumValue": "360482",
            "enumName": "共青城市"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3605",
        "enumName": "新余市",
        "children": [
          {
            "parentEnumValue": "3605",
            "enumValue": "360500",
            "enumName": "新余市本级"
          },
          {
            "parentEnumValue": "3605",
            "enumValue": "360502",
            "enumName": "渝水区"
          },
          {
            "parentEnumValue": "3605",
            "enumValue": "360521",
            "enumName": "分宜县"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3606",
        "enumName": "鹰潭市",
        "children": [
          {
            "parentEnumValue": "3606",
            "enumValue": "360603",
            "enumName": "余江区"
          },
          {
            "parentEnumValue": "3606",
            "enumValue": "360600",
            "enumName": "鹰潭市本级"
          },
          {
            "parentEnumValue": "3606",
            "enumValue": "360602",
            "enumName": "月湖区"
          },
          {
            "parentEnumValue": "3606",
            "enumValue": "360622",
            "enumName": "余江县"
          },
          {
            "parentEnumValue": "3606",
            "enumValue": "360681",
            "enumName": "贵溪市"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3607",
        "enumName": "赣州市",
        "children": [
          {
            "parentEnumValue": "3607",
            "enumValue": "360704",
            "enumName": "赣县区"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360703",
            "enumName": "南康区"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360783",
            "enumName": "龙南市"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360700",
            "enumName": "赣州市本级"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360702",
            "enumName": "章贡区"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360721",
            "enumName": "赣县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360722",
            "enumName": "信丰县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360723",
            "enumName": "大余县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360724",
            "enumName": "上犹县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360725",
            "enumName": "崇义县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360726",
            "enumName": "安远县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360727",
            "enumName": "龙南县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360728",
            "enumName": "定南县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360729",
            "enumName": "全南县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360730",
            "enumName": "宁都县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360731",
            "enumName": "于都县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360732",
            "enumName": "兴国县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360733",
            "enumName": "会昌县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360734",
            "enumName": "寻乌县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360735",
            "enumName": "石城县"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360781",
            "enumName": "瑞金市"
          },
          {
            "parentEnumValue": "3607",
            "enumValue": "360782",
            "enumName": "南康市"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3608",
        "enumName": "吉安市",
        "children": [
          {
            "parentEnumValue": "3608",
            "enumValue": "360800",
            "enumName": "吉安市本级"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360802",
            "enumName": "吉州区"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360803",
            "enumName": "青原区"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360821",
            "enumName": "吉安县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360822",
            "enumName": "吉水县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360823",
            "enumName": "峡江县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360824",
            "enumName": "新干县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360825",
            "enumName": "永丰县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360826",
            "enumName": "泰和县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360827",
            "enumName": "遂川县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360828",
            "enumName": "万安县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360829",
            "enumName": "安福县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360830",
            "enumName": "永新县"
          },
          {
            "parentEnumValue": "3608",
            "enumValue": "360881",
            "enumName": "井冈山市"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3609",
        "enumName": "宜春市",
        "children": [
          {
            "parentEnumValue": "3609",
            "enumValue": "360900",
            "enumName": "宜春市本级"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360902",
            "enumName": "袁州区"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360921",
            "enumName": "奉新县"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360922",
            "enumName": "万载县"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360923",
            "enumName": "上高县"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360924",
            "enumName": "宜丰县"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360925",
            "enumName": "靖安县"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360926",
            "enumName": "铜鼓县"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360981",
            "enumName": "丰城市"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360982",
            "enumName": "樟树市"
          },
          {
            "parentEnumValue": "3609",
            "enumValue": "360983",
            "enumName": "高安市"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3610",
        "enumName": "抚州市",
        "children": [
          {
            "parentEnumValue": "3610",
            "enumValue": "361003",
            "enumName": "东乡区"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361000",
            "enumName": "抚州市本级"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361002",
            "enumName": "临川区"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361021",
            "enumName": "南城县"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361022",
            "enumName": "黎川县"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361023",
            "enumName": "南丰县"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361024",
            "enumName": "崇仁县"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361025",
            "enumName": "乐安县"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361026",
            "enumName": "宜黄县"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361027",
            "enumName": "金溪县"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361028",
            "enumName": "资溪县"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361029",
            "enumName": "东乡县"
          },
          {
            "parentEnumValue": "3610",
            "enumValue": "361030",
            "enumName": "广昌县"
          }
        ]
      },
      {
        "parentEnumValue": "36",
        "enumValue": "3611",
        "enumName": "上饶市",
        "children": [
          {
            "parentEnumValue": "3611",
            "enumValue": "361103",
            "enumName": "广丰区"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361104",
            "enumName": "广信区"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361100",
            "enumName": "上饶市本级"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361102",
            "enumName": "信州区"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361121",
            "enumName": "上饶县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361122",
            "enumName": "广丰县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361123",
            "enumName": "玉山县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361124",
            "enumName": "铅山县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361125",
            "enumName": "横峰县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361126",
            "enumName": "弋阳县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361127",
            "enumName": "余干县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361128",
            "enumName": "鄱阳县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361129",
            "enumName": "万年县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361130",
            "enumName": "婺源县"
          },
          {
            "parentEnumValue": "3611",
            "enumValue": "361181",
            "enumName": "德兴市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "37",
    "enumName": "山东省",
    "children": [
      {
        "parentEnumValue": "37",
        "enumValue": "3701",
        "enumName": "济南市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3701",
            "enumValue": "370117",
            "enumName": "钢城区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370116",
            "enumName": "莱芜区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370100",
            "enumName": "济南市本级"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370115",
            "enumName": "济阳区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370114",
            "enumName": "章丘区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370102",
            "enumName": "历下区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370103",
            "enumName": "市中区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370104",
            "enumName": "槐荫区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370105",
            "enumName": "天桥区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370112",
            "enumName": "历城区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370113",
            "enumName": "长清区"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370124",
            "enumName": "平阴县"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370125",
            "enumName": "济阳县"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370126",
            "enumName": "商河县"
          },
          {
            "parentEnumValue": "3701",
            "enumValue": "370181",
            "enumName": "章丘市"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3702",
        "enumName": "青岛市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3702",
            "enumValue": "370200",
            "enumName": "青岛市本级"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370202",
            "enumName": "市南区"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370203",
            "enumName": "市北区"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370205",
            "enumName": "四方区"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370211",
            "enumName": "黄岛区"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370212",
            "enumName": "崂山区"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370213",
            "enumName": "李沧区"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370214",
            "enumName": "城阳区"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370215",
            "enumName": "即墨区"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370281",
            "enumName": "胶州市"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370282",
            "enumName": "即墨市"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370283",
            "enumName": "平度市"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370284",
            "enumName": "胶南市"
          },
          {
            "parentEnumValue": "3702",
            "enumValue": "370285",
            "enumName": "莱西市"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3703",
        "enumName": "淄博市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3703",
            "enumValue": "370300",
            "enumName": "淄博市本级"
          },
          {
            "parentEnumValue": "3703",
            "enumValue": "370302",
            "enumName": "淄川区"
          },
          {
            "parentEnumValue": "3703",
            "enumValue": "370303",
            "enumName": "张店区"
          },
          {
            "parentEnumValue": "3703",
            "enumValue": "370304",
            "enumName": "博山区"
          },
          {
            "parentEnumValue": "3703",
            "enumValue": "370305",
            "enumName": "临淄区"
          },
          {
            "parentEnumValue": "3703",
            "enumValue": "370306",
            "enumName": "周村区"
          },
          {
            "parentEnumValue": "3703",
            "enumValue": "370321",
            "enumName": "桓台县"
          },
          {
            "parentEnumValue": "3703",
            "enumValue": "370322",
            "enumName": "高青县"
          },
          {
            "parentEnumValue": "3703",
            "enumValue": "370323",
            "enumName": "沂源县"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3704",
        "enumName": "枣庄市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3704",
            "enumValue": "370400",
            "enumName": "枣庄市本级"
          },
          {
            "parentEnumValue": "3704",
            "enumValue": "370402",
            "enumName": "市中区"
          },
          {
            "parentEnumValue": "3704",
            "enumValue": "370403",
            "enumName": "薛城区"
          },
          {
            "parentEnumValue": "3704",
            "enumValue": "370404",
            "enumName": "峄城区"
          },
          {
            "parentEnumValue": "3704",
            "enumValue": "370405",
            "enumName": "台儿庄区"
          },
          {
            "parentEnumValue": "3704",
            "enumValue": "370406",
            "enumName": "山亭区"
          },
          {
            "parentEnumValue": "3704",
            "enumValue": "370481",
            "enumName": "滕州市"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3705",
        "enumName": "东营市",
        "children": [
          {
            "parentEnumValue": "3705",
            "enumValue": "370505",
            "enumName": "垦利区"
          },
          {
            "parentEnumValue": "3705",
            "enumValue": "370500",
            "enumName": "东营市本级"
          },
          {
            "parentEnumValue": "3705",
            "enumValue": "370502",
            "enumName": "东营区"
          },
          {
            "parentEnumValue": "3705",
            "enumValue": "370503",
            "enumName": "河口区"
          },
          {
            "parentEnumValue": "3705",
            "enumValue": "370521",
            "enumName": "垦利县"
          },
          {
            "parentEnumValue": "3705",
            "enumValue": "370522",
            "enumName": "利津县"
          },
          {
            "parentEnumValue": "3705",
            "enumValue": "370523",
            "enumName": "广饶县"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3706",
        "enumName": "烟台市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3706",
            "enumValue": "370614",
            "enumName": "蓬莱区"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370600",
            "enumName": "烟台市本级"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370602",
            "enumName": "芝罘区"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370611",
            "enumName": "福山区"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370612",
            "enumName": "牟平区"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370613",
            "enumName": "莱山区"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370634",
            "enumName": "长岛县"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370681",
            "enumName": "龙口市"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370682",
            "enumName": "莱阳市"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370683",
            "enumName": "莱州市"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370684",
            "enumName": "蓬莱市"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370685",
            "enumName": "招远市"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370686",
            "enumName": "栖霞市"
          },
          {
            "parentEnumValue": "3706",
            "enumValue": "370687",
            "enumName": "海阳市"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3707",
        "enumName": "潍坊市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3707",
            "enumValue": "370700",
            "enumName": "潍坊市本级"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370702",
            "enumName": "潍城区"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370703",
            "enumName": "寒亭区"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370704",
            "enumName": "坊子区"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370705",
            "enumName": "奎文区"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370724",
            "enumName": "临朐县"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370725",
            "enumName": "昌乐县"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370781",
            "enumName": "青州市"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370782",
            "enumName": "诸城市"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370783",
            "enumName": "寿光市"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370784",
            "enumName": "安丘市"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370785",
            "enumName": "高密市"
          },
          {
            "parentEnumValue": "3707",
            "enumValue": "370786",
            "enumName": "昌邑市"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3708",
        "enumName": "济宁市",
        "children": [
          {
            "parentEnumValue": "3708",
            "enumValue": "370812",
            "enumName": "兖州区"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370800",
            "enumName": "济宁市本级"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370802",
            "enumName": "市中区"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370811",
            "enumName": "任城区"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370826",
            "enumName": "微山县"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370827",
            "enumName": "鱼台县"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370828",
            "enumName": "金乡县"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370829",
            "enumName": "嘉祥县"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370830",
            "enumName": "汶上县"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370831",
            "enumName": "泗水县"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370832",
            "enumName": "梁山县"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370881",
            "enumName": "曲阜市"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370882",
            "enumName": "兖州市"
          },
          {
            "parentEnumValue": "3708",
            "enumValue": "370883",
            "enumName": "邹城市"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3709",
        "enumName": "泰安市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3709",
            "enumValue": "370900",
            "enumName": "泰安市本级"
          },
          {
            "parentEnumValue": "3709",
            "enumValue": "370902",
            "enumName": "泰山区"
          },
          {
            "parentEnumValue": "3709",
            "enumValue": "370911",
            "enumName": "岱岳区"
          },
          {
            "parentEnumValue": "3709",
            "enumValue": "370921",
            "enumName": "宁阳县"
          },
          {
            "parentEnumValue": "3709",
            "enumValue": "370923",
            "enumName": "东平县"
          },
          {
            "parentEnumValue": "3709",
            "enumValue": "370982",
            "enumName": "新泰市"
          },
          {
            "parentEnumValue": "3709",
            "enumValue": "370983",
            "enumName": "肥城市"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3710",
        "enumName": "威海市",
        "children": [
          {
            "parentEnumValue": "3710",
            "enumValue": "371003",
            "enumName": "文登区"
          },
          {
            "parentEnumValue": "3710",
            "enumValue": "371000",
            "enumName": "威海市本级"
          },
          {
            "parentEnumValue": "3710",
            "enumValue": "371002",
            "enumName": "环翠区"
          },
          {
            "parentEnumValue": "3710",
            "enumValue": "371081",
            "enumName": "文登市"
          },
          {
            "parentEnumValue": "3710",
            "enumValue": "371082",
            "enumName": "荣成市"
          },
          {
            "parentEnumValue": "3710",
            "enumValue": "371083",
            "enumName": "乳山市"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3711",
        "enumName": "日照市",
        "children": [
          {
            "parentEnumValue": "3711",
            "enumValue": "371100",
            "enumName": "日照市本级"
          },
          {
            "parentEnumValue": "3711",
            "enumValue": "371102",
            "enumName": "东港区"
          },
          {
            "parentEnumValue": "3711",
            "enumValue": "371103",
            "enumName": "岚山区"
          },
          {
            "parentEnumValue": "3711",
            "enumValue": "371121",
            "enumName": "五莲县"
          },
          {
            "parentEnumValue": "3711",
            "enumValue": "371122",
            "enumName": "莒县"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3712",
        "enumName": "莱芜市",
        "children": [
          {
            "parentEnumValue": "3712",
            "enumValue": "371200",
            "enumName": "莱芜市本级"
          },
          {
            "parentEnumValue": "3712",
            "enumValue": "371202",
            "enumName": "莱城区"
          },
          {
            "parentEnumValue": "3712",
            "enumValue": "371203",
            "enumName": "钢城区"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3713",
        "enumName": "临沂市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "3713",
            "enumValue": "371300",
            "enumName": "临沂市本级"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371302",
            "enumName": "兰山区"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371311",
            "enumName": "罗庄区"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371312",
            "enumName": "河东区"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371321",
            "enumName": "沂南县"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371322",
            "enumName": "郯城县"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371323",
            "enumName": "沂水县"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371324",
            "enumName": "兰陵县"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371325",
            "enumName": "费县"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371326",
            "enumName": "平邑县"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371327",
            "enumName": "莒南县"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371328",
            "enumName": "蒙阴县"
          },
          {
            "parentEnumValue": "3713",
            "enumValue": "371329",
            "enumName": "临沭县"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3714",
        "enumName": "德州市",
        "children": [
          {
            "parentEnumValue": "3714",
            "enumValue": "371400",
            "enumName": "德州市本级"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371402",
            "enumName": "德城区"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371421",
            "enumName": "陵县"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371422",
            "enumName": "宁津县"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371423",
            "enumName": "庆云县"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371424",
            "enumName": "临邑县"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371425",
            "enumName": "齐河县"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371426",
            "enumName": "平原县"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371427",
            "enumName": "夏津县"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371428",
            "enumName": "武城县"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371481",
            "enumName": "乐陵市"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371482",
            "enumName": "禹城市"
          },
          {
            "parentEnumValue": "3714",
            "enumValue": "371403",
            "enumName": "陵城区"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3715",
        "enumName": "聊城市",
        "children": [
          {
            "parentEnumValue": "3715",
            "enumValue": "371503",
            "enumName": "茌平区"
          },
          {
            "parentEnumValue": "3715",
            "enumValue": "371500",
            "enumName": "聊城市本级"
          },
          {
            "parentEnumValue": "3715",
            "enumValue": "371502",
            "enumName": "东昌府区"
          },
          {
            "parentEnumValue": "3715",
            "enumValue": "371521",
            "enumName": "阳谷县"
          },
          {
            "parentEnumValue": "3715",
            "enumValue": "371522",
            "enumName": "莘县"
          },
          {
            "parentEnumValue": "3715",
            "enumValue": "371523",
            "enumName": "茌平县"
          },
          {
            "parentEnumValue": "3715",
            "enumValue": "371524",
            "enumName": "东阿县"
          },
          {
            "parentEnumValue": "3715",
            "enumValue": "371525",
            "enumName": "冠县"
          },
          {
            "parentEnumValue": "3715",
            "enumValue": "371526",
            "enumName": "高唐县"
          },
          {
            "parentEnumValue": "3715",
            "enumValue": "371581",
            "enumName": "临清市"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3716",
        "enumName": "滨州市",
        "children": [
          {
            "parentEnumValue": "3716",
            "enumValue": "371681",
            "enumName": "邹平市"
          },
          {
            "parentEnumValue": "3716",
            "enumValue": "371603",
            "enumName": "沾化区"
          },
          {
            "parentEnumValue": "3716",
            "enumValue": "371600",
            "enumName": "滨州市本级"
          },
          {
            "parentEnumValue": "3716",
            "enumValue": "371602",
            "enumName": "滨城区"
          },
          {
            "parentEnumValue": "3716",
            "enumValue": "371621",
            "enumName": "惠民县"
          },
          {
            "parentEnumValue": "3716",
            "enumValue": "371622",
            "enumName": "阳信县"
          },
          {
            "parentEnumValue": "3716",
            "enumValue": "371623",
            "enumName": "无棣县"
          },
          {
            "parentEnumValue": "3716",
            "enumValue": "371624",
            "enumName": "沾化县"
          },
          {
            "parentEnumValue": "3716",
            "enumValue": "371625",
            "enumName": "博兴县"
          },
          {
            "parentEnumValue": "3716",
            "enumValue": "371626",
            "enumName": "邹平县"
          }
        ]
      },
      {
        "parentEnumValue": "37",
        "enumValue": "3717",
        "enumName": "菏泽市",
        "children": [
          {
            "parentEnumValue": "3717",
            "enumValue": "371703",
            "enumName": "定陶区"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371700",
            "enumName": "菏泽市本级"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371702",
            "enumName": "牡丹区"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371721",
            "enumName": "曹县"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371722",
            "enumName": "单县"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371723",
            "enumName": "成武县"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371724",
            "enumName": "巨野县"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371725",
            "enumName": "郓城县"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371726",
            "enumName": "鄄城县"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371727",
            "enumName": "定陶县"
          },
          {
            "parentEnumValue": "3717",
            "enumValue": "371728",
            "enumName": "东明县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "41",
    "enumName": "河南省",
    "children": [
      {
        "parentEnumValue": "41",
        "enumValue": "4190",
        "enumName": "省直辖县",
        "children": [
          {
            "parentEnumValue": "4190",
            "enumValue": "419000",
            "enumName": "省直辖县级行政单位本级"
          },
          {
            "parentEnumValue": "4190",
            "enumValue": "419001",
            "enumName": "济源市"
          },
          {
            "parentEnumValue": "4190",
            "enumValue": "410881",
            "enumName": "济源市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4101",
        "enumName": "郑州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4101",
            "enumValue": "410100",
            "enumName": "郑州市本级"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410102",
            "enumName": "中原区"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410103",
            "enumName": "二七区"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410104",
            "enumName": "管城回族区"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410105",
            "enumName": "金水区"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410106",
            "enumName": "上街区"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410108",
            "enumName": "惠济区"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410122",
            "enumName": "中牟县"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410181",
            "enumName": "巩义市"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410182",
            "enumName": "荥阳市"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410183",
            "enumName": "新密市"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410184",
            "enumName": "新郑市"
          },
          {
            "parentEnumValue": "4101",
            "enumValue": "410185",
            "enumName": "登封市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4102",
        "enumName": "开封市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4102",
            "enumValue": "410212",
            "enumName": "祥符区"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410200",
            "enumName": "开封市本级"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410202",
            "enumName": "龙亭区"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410203",
            "enumName": "顺河回族区"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410204",
            "enumName": "鼓楼区"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410205",
            "enumName": "禹王台区"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410211",
            "enumName": "金明区"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410221",
            "enumName": "杞县"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410222",
            "enumName": "通许县"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410223",
            "enumName": "尉氏县"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410224",
            "enumName": "开封县"
          },
          {
            "parentEnumValue": "4102",
            "enumValue": "410225",
            "enumName": "兰考县"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4103",
        "enumName": "洛阳市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4103",
            "enumValue": "410308",
            "enumName": "孟津区"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410307",
            "enumName": "偃师区"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410300",
            "enumName": "洛阳市本级"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410302",
            "enumName": "老城区"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410303",
            "enumName": "西工区"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410304",
            "enumName": "瀍河回族区"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410305",
            "enumName": "涧西区"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410306",
            "enumName": "吉利区"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410311",
            "enumName": "洛龙区"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410322",
            "enumName": "孟津县"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410323",
            "enumName": "新安县"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410324",
            "enumName": "栾川县"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410325",
            "enumName": "嵩县"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410326",
            "enumName": "汝阳县"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410327",
            "enumName": "宜阳县"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410328",
            "enumName": "洛宁县"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410329",
            "enumName": "伊川县"
          },
          {
            "parentEnumValue": "4103",
            "enumValue": "410381",
            "enumName": "偃师市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4104",
        "enumName": "平顶山市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4104",
            "enumValue": "410400",
            "enumName": "平顶山市本级"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410402",
            "enumName": "新华区"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410403",
            "enumName": "卫东区"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410404",
            "enumName": "石龙区"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410411",
            "enumName": "湛河区"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410421",
            "enumName": "宝丰县"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410422",
            "enumName": "叶县"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410423",
            "enumName": "鲁山县"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410425",
            "enumName": "郏县"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410481",
            "enumName": "舞钢市"
          },
          {
            "parentEnumValue": "4104",
            "enumValue": "410482",
            "enumName": "汝州市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4105",
        "enumName": "安阳市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4105",
            "enumValue": "410500",
            "enumName": "安阳市本级"
          },
          {
            "parentEnumValue": "4105",
            "enumValue": "410502",
            "enumName": "文峰区"
          },
          {
            "parentEnumValue": "4105",
            "enumValue": "410503",
            "enumName": "北关区"
          },
          {
            "parentEnumValue": "4105",
            "enumValue": "410505",
            "enumName": "殷都区"
          },
          {
            "parentEnumValue": "4105",
            "enumValue": "410506",
            "enumName": "龙安区"
          },
          {
            "parentEnumValue": "4105",
            "enumValue": "410522",
            "enumName": "安阳县"
          },
          {
            "parentEnumValue": "4105",
            "enumValue": "410523",
            "enumName": "汤阴县"
          },
          {
            "parentEnumValue": "4105",
            "enumValue": "410526",
            "enumName": "滑县"
          },
          {
            "parentEnumValue": "4105",
            "enumValue": "410527",
            "enumName": "内黄县"
          },
          {
            "parentEnumValue": "4105",
            "enumValue": "410581",
            "enumName": "林州市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4106",
        "enumName": "鹤壁市",
        "children": [
          {
            "parentEnumValue": "4106",
            "enumValue": "410600",
            "enumName": "鹤壁市本级"
          },
          {
            "parentEnumValue": "4106",
            "enumValue": "410602",
            "enumName": "鹤山区"
          },
          {
            "parentEnumValue": "4106",
            "enumValue": "410603",
            "enumName": "山城区"
          },
          {
            "parentEnumValue": "4106",
            "enumValue": "410611",
            "enumName": "淇滨区"
          },
          {
            "parentEnumValue": "4106",
            "enumValue": "410621",
            "enumName": "浚县"
          },
          {
            "parentEnumValue": "4106",
            "enumValue": "410622",
            "enumName": "淇县"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4107",
        "enumName": "新乡市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4107",
            "enumValue": "410783",
            "enumName": "长垣市"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410700",
            "enumName": "新乡市本级"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410702",
            "enumName": "红旗区"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410703",
            "enumName": "卫滨区"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410704",
            "enumName": "凤泉区"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410711",
            "enumName": "牧野区"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410721",
            "enumName": "新乡县"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410724",
            "enumName": "获嘉县"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410725",
            "enumName": "原阳县"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410726",
            "enumName": "延津县"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410727",
            "enumName": "封丘县"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410728",
            "enumName": "长垣县"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410781",
            "enumName": "卫辉市"
          },
          {
            "parentEnumValue": "4107",
            "enumValue": "410782",
            "enumName": "辉县市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4108",
        "enumName": "焦作市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4108",
            "enumValue": "410800",
            "enumName": "焦作市本级"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410802",
            "enumName": "解放区"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410803",
            "enumName": "中站区"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410804",
            "enumName": "马村区"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410811",
            "enumName": "山阳区"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410821",
            "enumName": "修武县"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410822",
            "enumName": "博爱县"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410823",
            "enumName": "武陟县"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410825",
            "enumName": "温县"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410882",
            "enumName": "沁阳市"
          },
          {
            "parentEnumValue": "4108",
            "enumValue": "410883",
            "enumName": "孟州市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4109",
        "enumName": "濮阳市",
        "children": [
          {
            "parentEnumValue": "4109",
            "enumValue": "410900",
            "enumName": "濮阳市本级"
          },
          {
            "parentEnumValue": "4109",
            "enumValue": "410902",
            "enumName": "华龙区"
          },
          {
            "parentEnumValue": "4109",
            "enumValue": "410922",
            "enumName": "清丰县"
          },
          {
            "parentEnumValue": "4109",
            "enumValue": "410923",
            "enumName": "南乐县"
          },
          {
            "parentEnumValue": "4109",
            "enumValue": "410926",
            "enumName": "范县"
          },
          {
            "parentEnumValue": "4109",
            "enumValue": "410927",
            "enumName": "台前县"
          },
          {
            "parentEnumValue": "4109",
            "enumValue": "410928",
            "enumName": "濮阳县"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4110",
        "enumName": "许昌市",
        "children": [
          {
            "parentEnumValue": "4110",
            "enumValue": "411000",
            "enumName": "许昌市本级"
          },
          {
            "parentEnumValue": "4110",
            "enumValue": "411002",
            "enumName": "魏都区"
          },
          {
            "parentEnumValue": "4110",
            "enumValue": "411023",
            "enumName": "许昌县"
          },
          {
            "parentEnumValue": "4110",
            "enumValue": "411024",
            "enumName": "鄢陵县"
          },
          {
            "parentEnumValue": "4110",
            "enumValue": "411025",
            "enumName": "襄城县"
          },
          {
            "parentEnumValue": "4110",
            "enumValue": "411081",
            "enumName": "禹州市"
          },
          {
            "parentEnumValue": "4110",
            "enumValue": "411082",
            "enumName": "长葛市"
          },
          {
            "parentEnumValue": "4110",
            "enumValue": "411003",
            "enumName": "建安区"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4111",
        "enumName": "漯河市",
        "children": [
          {
            "parentEnumValue": "4111",
            "enumValue": "411100",
            "enumName": "漯河市本级"
          },
          {
            "parentEnumValue": "4111",
            "enumValue": "411102",
            "enumName": "源汇区"
          },
          {
            "parentEnumValue": "4111",
            "enumValue": "411103",
            "enumName": "郾城区"
          },
          {
            "parentEnumValue": "4111",
            "enumValue": "411104",
            "enumName": "召陵区"
          },
          {
            "parentEnumValue": "4111",
            "enumValue": "411121",
            "enumName": "舞阳县"
          },
          {
            "parentEnumValue": "4111",
            "enumValue": "411122",
            "enumName": "临颍县"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4112",
        "enumName": "三门峡市",
        "children": [
          {
            "parentEnumValue": "4112",
            "enumValue": "411203",
            "enumName": "陕州区"
          },
          {
            "parentEnumValue": "4112",
            "enumValue": "411200",
            "enumName": "三门峡市本级"
          },
          {
            "parentEnumValue": "4112",
            "enumValue": "411202",
            "enumName": "湖滨区"
          },
          {
            "parentEnumValue": "4112",
            "enumValue": "411221",
            "enumName": "渑池县"
          },
          {
            "parentEnumValue": "4112",
            "enumValue": "411222",
            "enumName": "陕县"
          },
          {
            "parentEnumValue": "4112",
            "enumValue": "411224",
            "enumName": "卢氏县"
          },
          {
            "parentEnumValue": "4112",
            "enumValue": "411281",
            "enumName": "义马市"
          },
          {
            "parentEnumValue": "4112",
            "enumValue": "411282",
            "enumName": "灵宝市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4113",
        "enumName": "南阳市",
        "children": [
          {
            "parentEnumValue": "4113",
            "enumValue": "411300",
            "enumName": "南阳市本级"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411302",
            "enumName": "宛城区"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411303",
            "enumName": "卧龙区"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411321",
            "enumName": "南召县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411322",
            "enumName": "方城县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411323",
            "enumName": "西峡县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411324",
            "enumName": "镇平县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411325",
            "enumName": "内乡县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411326",
            "enumName": "淅川县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411327",
            "enumName": "社旗县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411328",
            "enumName": "唐河县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411329",
            "enumName": "新野县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411330",
            "enumName": "桐柏县"
          },
          {
            "parentEnumValue": "4113",
            "enumValue": "411381",
            "enumName": "邓州市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4114",
        "enumName": "商丘市",
        "children": [
          {
            "parentEnumValue": "4114",
            "enumValue": "411400",
            "enumName": "商丘市本级"
          },
          {
            "parentEnumValue": "4114",
            "enumValue": "411402",
            "enumName": "梁园区"
          },
          {
            "parentEnumValue": "4114",
            "enumValue": "411403",
            "enumName": "睢阳区"
          },
          {
            "parentEnumValue": "4114",
            "enumValue": "411421",
            "enumName": "民权县"
          },
          {
            "parentEnumValue": "4114",
            "enumValue": "411422",
            "enumName": "睢县"
          },
          {
            "parentEnumValue": "4114",
            "enumValue": "411423",
            "enumName": "宁陵县"
          },
          {
            "parentEnumValue": "4114",
            "enumValue": "411424",
            "enumName": "柘城县"
          },
          {
            "parentEnumValue": "4114",
            "enumValue": "411425",
            "enumName": "虞城县"
          },
          {
            "parentEnumValue": "4114",
            "enumValue": "411426",
            "enumName": "夏邑县"
          },
          {
            "parentEnumValue": "4114",
            "enumValue": "411481",
            "enumName": "永城市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4115",
        "enumName": "信阳市",
        "children": [
          {
            "parentEnumValue": "4115",
            "enumValue": "411500",
            "enumName": "信阳市本级"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411502",
            "enumName": "浉河区"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411503",
            "enumName": "平桥区"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411521",
            "enumName": "罗山县"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411522",
            "enumName": "光山县"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411523",
            "enumName": "新县"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411524",
            "enumName": "商城县"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411525",
            "enumName": "固始县"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411526",
            "enumName": "潢川县"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411527",
            "enumName": "淮滨县"
          },
          {
            "parentEnumValue": "4115",
            "enumValue": "411528",
            "enumName": "息县"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4116",
        "enumName": "周口市",
        "children": [
          {
            "parentEnumValue": "4116",
            "enumValue": "411603",
            "enumName": "淮阳区"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411600",
            "enumName": "周口市本级"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411602",
            "enumName": "川汇区"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411621",
            "enumName": "扶沟县"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411622",
            "enumName": "西华县"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411623",
            "enumName": "商水县"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411624",
            "enumName": "沈丘县"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411625",
            "enumName": "郸城县"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411626",
            "enumName": "淮阳县"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411627",
            "enumName": "太康县"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411628",
            "enumName": "鹿邑县"
          },
          {
            "parentEnumValue": "4116",
            "enumValue": "411681",
            "enumName": "项城市"
          }
        ]
      },
      {
        "parentEnumValue": "41",
        "enumValue": "4117",
        "enumName": "驻马店市",
        "children": [
          {
            "parentEnumValue": "4117",
            "enumValue": "411700",
            "enumName": "驻马店市本级"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411702",
            "enumName": "驿城区"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411721",
            "enumName": "西平县"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411722",
            "enumName": "上蔡县"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411723",
            "enumName": "平舆县"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411724",
            "enumName": "正阳县"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411725",
            "enumName": "确山县"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411726",
            "enumName": "泌阳县"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411727",
            "enumName": "汝南县"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411728",
            "enumName": "遂平县"
          },
          {
            "parentEnumValue": "4117",
            "enumValue": "411729",
            "enumName": "新蔡县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "42",
    "enumName": "湖北省",
    "children": [
      {
        "parentEnumValue": "42",
        "enumValue": "4201",
        "enumName": "武汉市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4201",
            "enumValue": "420100",
            "enumName": "武汉市本级"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420102",
            "enumName": "江岸区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420103",
            "enumName": "江汉区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420104",
            "enumName": "硚口区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420105",
            "enumName": "汉阳区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420106",
            "enumName": "武昌区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420107",
            "enumName": "青山区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420111",
            "enumName": "洪山区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420112",
            "enumName": "东西湖区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420113",
            "enumName": "汉南区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420114",
            "enumName": "蔡甸区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420115",
            "enumName": "江夏区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420116",
            "enumName": "黄陂区"
          },
          {
            "parentEnumValue": "4201",
            "enumValue": "420117",
            "enumName": "新洲区"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4202",
        "enumName": "黄石市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4202",
            "enumValue": "420200",
            "enumName": "黄石市本级"
          },
          {
            "parentEnumValue": "4202",
            "enumValue": "420202",
            "enumName": "黄石港区"
          },
          {
            "parentEnumValue": "4202",
            "enumValue": "420203",
            "enumName": "西塞山区"
          },
          {
            "parentEnumValue": "4202",
            "enumValue": "420204",
            "enumName": "下陆区"
          },
          {
            "parentEnumValue": "4202",
            "enumValue": "420205",
            "enumName": "铁山区"
          },
          {
            "parentEnumValue": "4202",
            "enumValue": "420222",
            "enumName": "阳新县"
          },
          {
            "parentEnumValue": "4202",
            "enumValue": "420281",
            "enumName": "大冶市"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4203",
        "enumName": "十堰市",
        "children": [
          {
            "parentEnumValue": "4203",
            "enumValue": "420300",
            "enumName": "十堰市本级"
          },
          {
            "parentEnumValue": "4203",
            "enumValue": "420302",
            "enumName": "茅箭区"
          },
          {
            "parentEnumValue": "4203",
            "enumValue": "420303",
            "enumName": "张湾区"
          },
          {
            "parentEnumValue": "4203",
            "enumValue": "420321",
            "enumName": "郧县"
          },
          {
            "parentEnumValue": "4203",
            "enumValue": "420322",
            "enumName": "郧西县"
          },
          {
            "parentEnumValue": "4203",
            "enumValue": "420323",
            "enumName": "竹山县"
          },
          {
            "parentEnumValue": "4203",
            "enumValue": "420324",
            "enumName": "竹溪县"
          },
          {
            "parentEnumValue": "4203",
            "enumValue": "420325",
            "enumName": "房县"
          },
          {
            "parentEnumValue": "4203",
            "enumValue": "420381",
            "enumName": "丹江口市"
          },
          {
            "parentEnumValue": "4203",
            "enumValue": "420304",
            "enumName": "郧阳区"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4205",
        "enumName": "宜昌市",
        "children": [
          {
            "parentEnumValue": "4205",
            "enumValue": "420500",
            "enumName": "宜昌市本级"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420502",
            "enumName": "西陵区"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420503",
            "enumName": "伍家岗区"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420504",
            "enumName": "点军区"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420505",
            "enumName": "猇亭区"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420506",
            "enumName": "夷陵区"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420525",
            "enumName": "远安县"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420526",
            "enumName": "兴山县"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420527",
            "enumName": "秭归县"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420528",
            "enumName": "长阳土家族自治县"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420529",
            "enumName": "五峰土家族自治县"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420581",
            "enumName": "宜都市"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420582",
            "enumName": "当阳市"
          },
          {
            "parentEnumValue": "4205",
            "enumValue": "420583",
            "enumName": "枝江市"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4206",
        "enumName": "襄阳市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4206",
            "enumValue": "420600",
            "enumName": "襄阳市本级"
          },
          {
            "parentEnumValue": "4206",
            "enumValue": "420602",
            "enumName": "襄城区"
          },
          {
            "parentEnumValue": "4206",
            "enumValue": "420606",
            "enumName": "樊城区"
          },
          {
            "parentEnumValue": "4206",
            "enumValue": "420607",
            "enumName": "襄州区"
          },
          {
            "parentEnumValue": "4206",
            "enumValue": "420624",
            "enumName": "南漳县"
          },
          {
            "parentEnumValue": "4206",
            "enumValue": "420625",
            "enumName": "谷城县"
          },
          {
            "parentEnumValue": "4206",
            "enumValue": "420626",
            "enumName": "保康县"
          },
          {
            "parentEnumValue": "4206",
            "enumValue": "420682",
            "enumName": "老河口市"
          },
          {
            "parentEnumValue": "4206",
            "enumValue": "420683",
            "enumName": "枣阳市"
          },
          {
            "parentEnumValue": "4206",
            "enumValue": "420684",
            "enumName": "宜城市"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4207",
        "enumName": "鄂州市",
        "children": [
          {
            "parentEnumValue": "4207",
            "enumValue": "420700",
            "enumName": "鄂州市本级"
          },
          {
            "parentEnumValue": "4207",
            "enumValue": "420702",
            "enumName": "梁子湖区"
          },
          {
            "parentEnumValue": "4207",
            "enumValue": "420703",
            "enumName": "华容区"
          },
          {
            "parentEnumValue": "4207",
            "enumValue": "420704",
            "enumName": "鄂城区"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4208",
        "enumName": "荆门市",
        "children": [
          {
            "parentEnumValue": "4208",
            "enumValue": "420882",
            "enumName": "京山市"
          },
          {
            "parentEnumValue": "4208",
            "enumValue": "420800",
            "enumName": "荆门市本级"
          },
          {
            "parentEnumValue": "4208",
            "enumValue": "420802",
            "enumName": "东宝区"
          },
          {
            "parentEnumValue": "4208",
            "enumValue": "420804",
            "enumName": "掇刀区"
          },
          {
            "parentEnumValue": "4208",
            "enumValue": "420821",
            "enumName": "京山县"
          },
          {
            "parentEnumValue": "4208",
            "enumValue": "420822",
            "enumName": "沙洋县"
          },
          {
            "parentEnumValue": "4208",
            "enumValue": "420881",
            "enumName": "钟祥市"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4209",
        "enumName": "孝感市",
        "children": [
          {
            "parentEnumValue": "4209",
            "enumValue": "420900",
            "enumName": "孝感市本级"
          },
          {
            "parentEnumValue": "4209",
            "enumValue": "420902",
            "enumName": "孝南区"
          },
          {
            "parentEnumValue": "4209",
            "enumValue": "420921",
            "enumName": "孝昌县"
          },
          {
            "parentEnumValue": "4209",
            "enumValue": "420922",
            "enumName": "大悟县"
          },
          {
            "parentEnumValue": "4209",
            "enumValue": "420923",
            "enumName": "云梦县"
          },
          {
            "parentEnumValue": "4209",
            "enumValue": "420981",
            "enumName": "应城市"
          },
          {
            "parentEnumValue": "4209",
            "enumValue": "420982",
            "enumName": "安陆市"
          },
          {
            "parentEnumValue": "4209",
            "enumValue": "420984",
            "enumName": "汉川市"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4210",
        "enumName": "荆州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4210",
            "enumValue": "421088",
            "enumName": "监利市"
          },
          {
            "parentEnumValue": "4210",
            "enumValue": "421000",
            "enumName": "荆州市本级"
          },
          {
            "parentEnumValue": "4210",
            "enumValue": "421002",
            "enumName": "沙市区"
          },
          {
            "parentEnumValue": "4210",
            "enumValue": "421003",
            "enumName": "荆州区"
          },
          {
            "parentEnumValue": "4210",
            "enumValue": "421022",
            "enumName": "公安县"
          },
          {
            "parentEnumValue": "4210",
            "enumValue": "421023",
            "enumName": "监利县"
          },
          {
            "parentEnumValue": "4210",
            "enumValue": "421024",
            "enumName": "江陵县"
          },
          {
            "parentEnumValue": "4210",
            "enumValue": "421081",
            "enumName": "石首市"
          },
          {
            "parentEnumValue": "4210",
            "enumValue": "421083",
            "enumName": "洪湖市"
          },
          {
            "parentEnumValue": "4210",
            "enumValue": "421087",
            "enumName": "松滋市"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4211",
        "enumName": "黄冈市",
        "children": [
          {
            "parentEnumValue": "4211",
            "enumValue": "421100",
            "enumName": "黄冈市本级"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421102",
            "enumName": "黄州区"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421121",
            "enumName": "团风县"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421122",
            "enumName": "红安县"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421123",
            "enumName": "罗田县"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421124",
            "enumName": "英山县"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421125",
            "enumName": "浠水县"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421126",
            "enumName": "蕲春县"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421127",
            "enumName": "黄梅县"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421181",
            "enumName": "麻城市"
          },
          {
            "parentEnumValue": "4211",
            "enumValue": "421182",
            "enumName": "武穴市"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4212",
        "enumName": "咸宁市",
        "children": [
          {
            "parentEnumValue": "4212",
            "enumValue": "421200",
            "enumName": "咸宁市本级"
          },
          {
            "parentEnumValue": "4212",
            "enumValue": "421202",
            "enumName": "咸安区"
          },
          {
            "parentEnumValue": "4212",
            "enumValue": "421221",
            "enumName": "嘉鱼县"
          },
          {
            "parentEnumValue": "4212",
            "enumValue": "421222",
            "enumName": "通城县"
          },
          {
            "parentEnumValue": "4212",
            "enumValue": "421223",
            "enumName": "崇阳县"
          },
          {
            "parentEnumValue": "4212",
            "enumValue": "421224",
            "enumName": "通山县"
          },
          {
            "parentEnumValue": "4212",
            "enumValue": "421281",
            "enumName": "赤壁市"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4213",
        "enumName": "随州市",
        "children": [
          {
            "parentEnumValue": "4213",
            "enumValue": "421300",
            "enumName": "随州市本级"
          },
          {
            "parentEnumValue": "4213",
            "enumValue": "421302",
            "enumName": "曾都区"
          },
          {
            "parentEnumValue": "4213",
            "enumValue": "421381",
            "enumName": "广水市"
          },
          {
            "parentEnumValue": "4213",
            "enumValue": "421303",
            "enumName": "曾都区"
          },
          {
            "parentEnumValue": "4213",
            "enumValue": "421321",
            "enumName": "随县"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4228",
        "enumName": "恩施土家族苗族自治州",
        "children": [
          {
            "parentEnumValue": "4228",
            "enumValue": "422800",
            "enumName": "恩施土家族苗族自治州本级"
          },
          {
            "parentEnumValue": "4228",
            "enumValue": "422801",
            "enumName": "恩施市"
          },
          {
            "parentEnumValue": "4228",
            "enumValue": "422802",
            "enumName": "利川市"
          },
          {
            "parentEnumValue": "4228",
            "enumValue": "422822",
            "enumName": "建始县"
          },
          {
            "parentEnumValue": "4228",
            "enumValue": "422823",
            "enumName": "巴东县"
          },
          {
            "parentEnumValue": "4228",
            "enumValue": "422825",
            "enumName": "宣恩县"
          },
          {
            "parentEnumValue": "4228",
            "enumValue": "422826",
            "enumName": "咸丰县"
          },
          {
            "parentEnumValue": "4228",
            "enumValue": "422827",
            "enumName": "来凤县"
          },
          {
            "parentEnumValue": "4228",
            "enumValue": "422828",
            "enumName": "鹤峰县"
          }
        ]
      },
      {
        "parentEnumValue": "42",
        "enumValue": "4290",
        "enumName": "省级直辖县",
        "children": [
          {
            "parentEnumValue": "4290",
            "enumValue": "429000",
            "enumName": "省直辖县级行政单位本级"
          },
          {
            "parentEnumValue": "4290",
            "enumValue": "429004",
            "enumName": "仙桃市"
          },
          {
            "parentEnumValue": "4290",
            "enumValue": "429005",
            "enumName": "潜江市"
          },
          {
            "parentEnumValue": "4290",
            "enumValue": "429006",
            "enumName": "天门市"
          },
          {
            "parentEnumValue": "4290",
            "enumValue": "429021",
            "enumName": "神农架区"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "43",
    "enumName": "湖南省",
    "children": [
      {
        "parentEnumValue": "43",
        "enumValue": "4301",
        "enumName": "长沙市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4301",
            "enumValue": "430100",
            "enumName": "长沙市本级"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430182",
            "enumName": "宁乡市"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430102",
            "enumName": "芙蓉区"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430103",
            "enumName": "天心区"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430104",
            "enumName": "岳麓区"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430105",
            "enumName": "开福区"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430111",
            "enumName": "雨花区"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430121",
            "enumName": "长沙县"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430122",
            "enumName": "望城县"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430124",
            "enumName": "宁乡县"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430112",
            "enumName": "望城区"
          },
          {
            "parentEnumValue": "4301",
            "enumValue": "430181",
            "enumName": "浏阳市"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4302",
        "enumName": "株洲市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4302",
            "enumValue": "430212",
            "enumName": "渌口区"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430200",
            "enumName": "株洲市本级"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430202",
            "enumName": "荷塘区"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430203",
            "enumName": "芦淞区"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430204",
            "enumName": "石峰区"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430211",
            "enumName": "天元区"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430221",
            "enumName": "株洲县"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430223",
            "enumName": "攸县"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430224",
            "enumName": "茶陵县"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430225",
            "enumName": "炎陵县"
          },
          {
            "parentEnumValue": "4302",
            "enumValue": "430281",
            "enumName": "醴陵市"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4303",
        "enumName": "湘潭市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4303",
            "enumValue": "430300",
            "enumName": "湘潭市本级"
          },
          {
            "parentEnumValue": "4303",
            "enumValue": "430302",
            "enumName": "雨湖区"
          },
          {
            "parentEnumValue": "4303",
            "enumValue": "430304",
            "enumName": "岳塘区"
          },
          {
            "parentEnumValue": "4303",
            "enumValue": "430321",
            "enumName": "湘潭县"
          },
          {
            "parentEnumValue": "4303",
            "enumValue": "430381",
            "enumName": "湘乡市"
          },
          {
            "parentEnumValue": "4303",
            "enumValue": "430382",
            "enumName": "韶山市"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4304",
        "enumName": "衡阳市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4304",
            "enumValue": "430400",
            "enumName": "衡阳市本级"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430405",
            "enumName": "珠晖区"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430406",
            "enumName": "雁峰区"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430407",
            "enumName": "石鼓区"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430408",
            "enumName": "蒸湘区"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430412",
            "enumName": "南岳区"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430421",
            "enumName": "衡阳县"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430422",
            "enumName": "衡南县"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430423",
            "enumName": "衡山县"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430424",
            "enumName": "衡东县"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430426",
            "enumName": "祁东县"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430481",
            "enumName": "耒阳市"
          },
          {
            "parentEnumValue": "4304",
            "enumValue": "430482",
            "enumName": "常宁市"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4305",
        "enumName": "邵阳市",
        "children": [
          {
            "parentEnumValue": "4305",
            "enumValue": "430582",
            "enumName": "邵东市"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430500",
            "enumName": "邵阳市本级"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430502",
            "enumName": "双清区"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430503",
            "enumName": "大祥区"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430511",
            "enumName": "北塔区"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430521",
            "enumName": "邵东县"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430522",
            "enumName": "新邵县"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430523",
            "enumName": "邵阳县"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430524",
            "enumName": "隆回县"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430525",
            "enumName": "洞口县"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430527",
            "enumName": "绥宁县"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430528",
            "enumName": "新宁县"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430529",
            "enumName": "城步苗族自治县"
          },
          {
            "parentEnumValue": "4305",
            "enumValue": "430581",
            "enumName": "武冈市"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4306",
        "enumName": "岳阳市",
        "children": [
          {
            "parentEnumValue": "4306",
            "enumValue": "430600",
            "enumName": "岳阳市本级"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430602",
            "enumName": "岳阳楼区"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430603",
            "enumName": "云溪区"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430611",
            "enumName": "君山区"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430621",
            "enumName": "岳阳县"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430623",
            "enumName": "华容县"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430624",
            "enumName": "湘阴县"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430626",
            "enumName": "平江县"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430681",
            "enumName": "汨罗市"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430682",
            "enumName": "临湘市"
          },
          {
            "parentEnumValue": "4306",
            "enumValue": "430671",
            "enumName": "岳阳市屈原管理区"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4307",
        "enumName": "常德市",
        "children": [
          {
            "parentEnumValue": "4307",
            "enumValue": "430700",
            "enumName": "常德市本级"
          },
          {
            "parentEnumValue": "4307",
            "enumValue": "430702",
            "enumName": "武陵区"
          },
          {
            "parentEnumValue": "4307",
            "enumValue": "430703",
            "enumName": "鼎城区"
          },
          {
            "parentEnumValue": "4307",
            "enumValue": "430721",
            "enumName": "安乡县"
          },
          {
            "parentEnumValue": "4307",
            "enumValue": "430722",
            "enumName": "汉寿县"
          },
          {
            "parentEnumValue": "4307",
            "enumValue": "430723",
            "enumName": "澧县"
          },
          {
            "parentEnumValue": "4307",
            "enumValue": "430724",
            "enumName": "临澧县"
          },
          {
            "parentEnumValue": "4307",
            "enumValue": "430725",
            "enumName": "桃源县"
          },
          {
            "parentEnumValue": "4307",
            "enumValue": "430726",
            "enumName": "石门县"
          },
          {
            "parentEnumValue": "4307",
            "enumValue": "430781",
            "enumName": "津市市"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4308",
        "enumName": "张家界市",
        "children": [
          {
            "parentEnumValue": "4308",
            "enumValue": "430800",
            "enumName": "张家界市本级"
          },
          {
            "parentEnumValue": "4308",
            "enumValue": "430802",
            "enumName": "永定区"
          },
          {
            "parentEnumValue": "4308",
            "enumValue": "430811",
            "enumName": "武陵源区"
          },
          {
            "parentEnumValue": "4308",
            "enumValue": "430821",
            "enumName": "慈利县"
          },
          {
            "parentEnumValue": "4308",
            "enumValue": "430822",
            "enumName": "桑植县"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4309",
        "enumName": "益阳市",
        "children": [
          {
            "parentEnumValue": "4309",
            "enumValue": "430900",
            "enumName": "益阳市本级"
          },
          {
            "parentEnumValue": "4309",
            "enumValue": "430902",
            "enumName": "资阳区"
          },
          {
            "parentEnumValue": "4309",
            "enumValue": "430903",
            "enumName": "赫山区"
          },
          {
            "parentEnumValue": "4309",
            "enumValue": "430921",
            "enumName": "南县"
          },
          {
            "parentEnumValue": "4309",
            "enumValue": "430922",
            "enumName": "桃江县"
          },
          {
            "parentEnumValue": "4309",
            "enumValue": "430923",
            "enumName": "安化县"
          },
          {
            "parentEnumValue": "4309",
            "enumValue": "430981",
            "enumName": "沅江市"
          },
          {
            "parentEnumValue": "4309",
            "enumValue": "430971",
            "enumName": "益阳市大通湖管理区"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4310",
        "enumName": "郴州市",
        "children": [
          {
            "parentEnumValue": "4310",
            "enumValue": "431000",
            "enumName": "郴州市本级"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431002",
            "enumName": "北湖区"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431003",
            "enumName": "苏仙区"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431021",
            "enumName": "桂阳县"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431022",
            "enumName": "宜章县"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431023",
            "enumName": "永兴县"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431024",
            "enumName": "嘉禾县"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431025",
            "enumName": "临武县"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431026",
            "enumName": "汝城县"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431027",
            "enumName": "桂东县"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431028",
            "enumName": "安仁县"
          },
          {
            "parentEnumValue": "4310",
            "enumValue": "431081",
            "enumName": "资兴市"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4311",
        "enumName": "永州市",
        "children": [
          {
            "parentEnumValue": "4311",
            "enumValue": "431181",
            "enumName": "祁阳市"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431100",
            "enumName": "永州市本级"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431102",
            "enumName": "零陵区"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431103",
            "enumName": "冷水滩区"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431121",
            "enumName": "祁阳县"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431122",
            "enumName": "东安县"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431123",
            "enumName": "双牌县"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431124",
            "enumName": "道县"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431125",
            "enumName": "江永县"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431126",
            "enumName": "宁远县"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431127",
            "enumName": "蓝山县"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431128",
            "enumName": "新田县"
          },
          {
            "parentEnumValue": "4311",
            "enumValue": "431129",
            "enumName": "江华瑶族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4312",
        "enumName": "怀化市",
        "children": [
          {
            "parentEnumValue": "4312",
            "enumValue": "431200",
            "enumName": "怀化市本级"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431202",
            "enumName": "鹤城区"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431221",
            "enumName": "中方县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431222",
            "enumName": "沅陵县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431223",
            "enumName": "辰溪县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431224",
            "enumName": "溆浦县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431225",
            "enumName": "会同县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431226",
            "enumName": "麻阳苗族自治县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431227",
            "enumName": "新晃侗族自治县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431228",
            "enumName": "芷江侗族自治县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431229",
            "enumName": "靖州苗族侗族自治县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431230",
            "enumName": "通道侗族自治县"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431281",
            "enumName": "洪江市"
          },
          {
            "parentEnumValue": "4312",
            "enumValue": "431271",
            "enumName": "怀化市洪江管理区"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4313",
        "enumName": "娄底市",
        "children": [
          {
            "parentEnumValue": "4313",
            "enumValue": "431300",
            "enumName": "娄底市本级"
          },
          {
            "parentEnumValue": "4313",
            "enumValue": "431302",
            "enumName": "娄星区"
          },
          {
            "parentEnumValue": "4313",
            "enumValue": "431321",
            "enumName": "双峰县"
          },
          {
            "parentEnumValue": "4313",
            "enumValue": "431322",
            "enumName": "新化县"
          },
          {
            "parentEnumValue": "4313",
            "enumValue": "431381",
            "enumName": "冷水江市"
          },
          {
            "parentEnumValue": "4313",
            "enumValue": "431382",
            "enumName": "涟源市"
          }
        ]
      },
      {
        "parentEnumValue": "43",
        "enumValue": "4331",
        "enumName": "湘西土家族苗族自治州",
        "children": [
          {
            "parentEnumValue": "4331",
            "enumValue": "433100",
            "enumName": "湘西土家族苗族自治州本级"
          },
          {
            "parentEnumValue": "4331",
            "enumValue": "433101",
            "enumName": "吉首市"
          },
          {
            "parentEnumValue": "4331",
            "enumValue": "433122",
            "enumName": "泸溪县"
          },
          {
            "parentEnumValue": "4331",
            "enumValue": "433123",
            "enumName": "凤凰县"
          },
          {
            "parentEnumValue": "4331",
            "enumValue": "433124",
            "enumName": "花垣县"
          },
          {
            "parentEnumValue": "4331",
            "enumValue": "433125",
            "enumName": "保靖县"
          },
          {
            "parentEnumValue": "4331",
            "enumValue": "433126",
            "enumName": "古丈县"
          },
          {
            "parentEnumValue": "4331",
            "enumValue": "433127",
            "enumName": "永顺县"
          },
          {
            "parentEnumValue": "4331",
            "enumValue": "433130",
            "enumName": "龙山县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "44",
    "enumName": "广东省",
    "children": [
      {
        "parentEnumValue": "44",
        "enumValue": "4401",
        "enumName": "广州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4401",
            "enumValue": "440100",
            "enumName": "广州市本级"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440118",
            "enumName": "增城区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440117",
            "enumName": "从化区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440103",
            "enumName": "荔湾区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440104",
            "enumName": "越秀区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440105",
            "enumName": "海珠区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440106",
            "enumName": "天河区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440111",
            "enumName": "白云区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440112",
            "enumName": "黄埔区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440113",
            "enumName": "番禺区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440114",
            "enumName": "花都区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440115",
            "enumName": "南沙区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440116",
            "enumName": "萝岗区"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440183",
            "enumName": "增城市"
          },
          {
            "parentEnumValue": "4401",
            "enumValue": "440184",
            "enumName": "从化市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4402",
        "enumName": "韶关市",
        "children": [
          {
            "parentEnumValue": "4402",
            "enumValue": "440200",
            "enumName": "韶关市本级"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440203",
            "enumName": "武江区"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440204",
            "enumName": "浈江区"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440205",
            "enumName": "曲江区"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440222",
            "enumName": "始兴县"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440224",
            "enumName": "仁化县"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440229",
            "enumName": "翁源县"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440232",
            "enumName": "乳源瑶族自治县"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440233",
            "enumName": "新丰县"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440281",
            "enumName": "乐昌市"
          },
          {
            "parentEnumValue": "4402",
            "enumValue": "440282",
            "enumName": "南雄市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4403",
        "enumName": "深圳市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4403",
            "enumValue": "440300",
            "enumName": "深圳市本级"
          },
          {
            "parentEnumValue": "4403",
            "enumValue": "440303",
            "enumName": "罗湖区"
          },
          {
            "parentEnumValue": "4403",
            "enumValue": "440304",
            "enumName": "福田区"
          },
          {
            "parentEnumValue": "4403",
            "enumValue": "440305",
            "enumName": "南山区"
          },
          {
            "parentEnumValue": "4403",
            "enumValue": "440306",
            "enumName": "宝安区"
          },
          {
            "parentEnumValue": "4403",
            "enumValue": "440307",
            "enumName": "龙岗区"
          },
          {
            "parentEnumValue": "4403",
            "enumValue": "440308",
            "enumName": "盐田区"
          },
          {
            "parentEnumValue": "4403",
            "enumValue": "440309",
            "enumName": "龙华区"
          },
          {
            "parentEnumValue": "4403",
            "enumValue": "440310",
            "enumName": "坪山区"
          },
          {
            "parentEnumValue": "4403",
            "enumValue": "440311",
            "enumName": "光明区"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4404",
        "enumName": "珠海市",
        "children": [
          {
            "parentEnumValue": "4404",
            "enumValue": "440400",
            "enumName": "珠海市本级"
          },
          {
            "parentEnumValue": "4404",
            "enumValue": "440402",
            "enumName": "香洲区"
          },
          {
            "parentEnumValue": "4404",
            "enumValue": "440403",
            "enumName": "斗门区"
          },
          {
            "parentEnumValue": "4404",
            "enumValue": "440404",
            "enumName": "金湾区"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4405",
        "enumName": "汕头市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4405",
            "enumValue": "440500",
            "enumName": "汕头市本级"
          },
          {
            "parentEnumValue": "4405",
            "enumValue": "440507",
            "enumName": "龙湖区"
          },
          {
            "parentEnumValue": "4405",
            "enumValue": "440511",
            "enumName": "金平区"
          },
          {
            "parentEnumValue": "4405",
            "enumValue": "440512",
            "enumName": "濠江区"
          },
          {
            "parentEnumValue": "4405",
            "enumValue": "440513",
            "enumName": "潮阳区"
          },
          {
            "parentEnumValue": "4405",
            "enumValue": "440514",
            "enumName": "潮南区"
          },
          {
            "parentEnumValue": "4405",
            "enumValue": "440515",
            "enumName": "澄海区"
          },
          {
            "parentEnumValue": "4405",
            "enumValue": "440523",
            "enumName": "南澳县"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4406",
        "enumName": "佛山市",
        "children": [
          {
            "parentEnumValue": "4406",
            "enumValue": "440600",
            "enumName": "佛山市本级"
          },
          {
            "parentEnumValue": "4406",
            "enumValue": "440604",
            "enumName": "禅城区"
          },
          {
            "parentEnumValue": "4406",
            "enumValue": "440605",
            "enumName": "南海区"
          },
          {
            "parentEnumValue": "4406",
            "enumValue": "440606",
            "enumName": "顺德区"
          },
          {
            "parentEnumValue": "4406",
            "enumValue": "440607",
            "enumName": "三水区"
          },
          {
            "parentEnumValue": "4406",
            "enumValue": "440608",
            "enumName": "高明区"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4407",
        "enumName": "江门市",
        "children": [
          {
            "parentEnumValue": "4407",
            "enumValue": "440700",
            "enumName": "江门市本级"
          },
          {
            "parentEnumValue": "4407",
            "enumValue": "440703",
            "enumName": "蓬江区"
          },
          {
            "parentEnumValue": "4407",
            "enumValue": "440704",
            "enumName": "江海区"
          },
          {
            "parentEnumValue": "4407",
            "enumValue": "440705",
            "enumName": "新会区"
          },
          {
            "parentEnumValue": "4407",
            "enumValue": "440781",
            "enumName": "台山市"
          },
          {
            "parentEnumValue": "4407",
            "enumValue": "440783",
            "enumName": "开平市"
          },
          {
            "parentEnumValue": "4407",
            "enumValue": "440784",
            "enumName": "鹤山市"
          },
          {
            "parentEnumValue": "4407",
            "enumValue": "440785",
            "enumName": "恩平市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4408",
        "enumName": "湛江市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4408",
            "enumValue": "440800",
            "enumName": "湛江市本级"
          },
          {
            "parentEnumValue": "4408",
            "enumValue": "440802",
            "enumName": "赤坎区"
          },
          {
            "parentEnumValue": "4408",
            "enumValue": "440803",
            "enumName": "霞山区"
          },
          {
            "parentEnumValue": "4408",
            "enumValue": "440804",
            "enumName": "坡头区"
          },
          {
            "parentEnumValue": "4408",
            "enumValue": "440811",
            "enumName": "麻章区"
          },
          {
            "parentEnumValue": "4408",
            "enumValue": "440823",
            "enumName": "遂溪县"
          },
          {
            "parentEnumValue": "4408",
            "enumValue": "440825",
            "enumName": "徐闻县"
          },
          {
            "parentEnumValue": "4408",
            "enumValue": "440881",
            "enumName": "廉江市"
          },
          {
            "parentEnumValue": "4408",
            "enumValue": "440882",
            "enumName": "雷州市"
          },
          {
            "parentEnumValue": "4408",
            "enumValue": "440883",
            "enumName": "吴川市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4409",
        "enumName": "茂名市",
        "children": [
          {
            "parentEnumValue": "4409",
            "enumValue": "440904",
            "enumName": "电白区"
          },
          {
            "parentEnumValue": "4409",
            "enumValue": "440900",
            "enumName": "茂名市本级"
          },
          {
            "parentEnumValue": "4409",
            "enumValue": "440902",
            "enumName": "茂南区"
          },
          {
            "parentEnumValue": "4409",
            "enumValue": "440903",
            "enumName": "茂港区"
          },
          {
            "parentEnumValue": "4409",
            "enumValue": "440923",
            "enumName": "电白县"
          },
          {
            "parentEnumValue": "4409",
            "enumValue": "440981",
            "enumName": "高州市"
          },
          {
            "parentEnumValue": "4409",
            "enumValue": "440982",
            "enumName": "化州市"
          },
          {
            "parentEnumValue": "4409",
            "enumValue": "440983",
            "enumName": "信宜市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4412",
        "enumName": "肇庆市",
        "children": [
          {
            "parentEnumValue": "4412",
            "enumValue": "441204",
            "enumName": "高要区"
          },
          {
            "parentEnumValue": "4412",
            "enumValue": "441200",
            "enumName": "肇庆市本级"
          },
          {
            "parentEnumValue": "4412",
            "enumValue": "441202",
            "enumName": "端州区"
          },
          {
            "parentEnumValue": "4412",
            "enumValue": "441203",
            "enumName": "鼎湖区"
          },
          {
            "parentEnumValue": "4412",
            "enumValue": "441223",
            "enumName": "广宁县"
          },
          {
            "parentEnumValue": "4412",
            "enumValue": "441224",
            "enumName": "怀集县"
          },
          {
            "parentEnumValue": "4412",
            "enumValue": "441225",
            "enumName": "封开县"
          },
          {
            "parentEnumValue": "4412",
            "enumValue": "441226",
            "enumName": "德庆县"
          },
          {
            "parentEnumValue": "4412",
            "enumValue": "441283",
            "enumName": "高要市"
          },
          {
            "parentEnumValue": "4412",
            "enumValue": "441284",
            "enumName": "四会市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4413",
        "enumName": "惠州市",
        "children": [
          {
            "parentEnumValue": "4413",
            "enumValue": "441300",
            "enumName": "惠州市本级"
          },
          {
            "parentEnumValue": "4413",
            "enumValue": "441302",
            "enumName": "惠城区"
          },
          {
            "parentEnumValue": "4413",
            "enumValue": "441303",
            "enumName": "惠阳区"
          },
          {
            "parentEnumValue": "4413",
            "enumValue": "441322",
            "enumName": "博罗县"
          },
          {
            "parentEnumValue": "4413",
            "enumValue": "441323",
            "enumName": "惠东县"
          },
          {
            "parentEnumValue": "4413",
            "enumValue": "441324",
            "enumName": "龙门县"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4414",
        "enumName": "梅州市",
        "children": [
          {
            "parentEnumValue": "4414",
            "enumValue": "441403",
            "enumName": "梅县区"
          },
          {
            "parentEnumValue": "4414",
            "enumValue": "441400",
            "enumName": "梅州市本级"
          },
          {
            "parentEnumValue": "4414",
            "enumValue": "441402",
            "enumName": "梅江区"
          },
          {
            "parentEnumValue": "4414",
            "enumValue": "441421",
            "enumName": "梅县"
          },
          {
            "parentEnumValue": "4414",
            "enumValue": "441422",
            "enumName": "大埔县"
          },
          {
            "parentEnumValue": "4414",
            "enumValue": "441423",
            "enumName": "丰顺县"
          },
          {
            "parentEnumValue": "4414",
            "enumValue": "441424",
            "enumName": "五华县"
          },
          {
            "parentEnumValue": "4414",
            "enumValue": "441426",
            "enumName": "平远县"
          },
          {
            "parentEnumValue": "4414",
            "enumValue": "441427",
            "enumName": "蕉岭县"
          },
          {
            "parentEnumValue": "4414",
            "enumValue": "441481",
            "enumName": "兴宁市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4415",
        "enumName": "汕尾市",
        "children": [
          {
            "parentEnumValue": "4415",
            "enumValue": "441500",
            "enumName": "汕尾市本级"
          },
          {
            "parentEnumValue": "4415",
            "enumValue": "441502",
            "enumName": "城区"
          },
          {
            "parentEnumValue": "4415",
            "enumValue": "441521",
            "enumName": "海丰县"
          },
          {
            "parentEnumValue": "4415",
            "enumValue": "441523",
            "enumName": "陆河县"
          },
          {
            "parentEnumValue": "4415",
            "enumValue": "441581",
            "enumName": "陆丰市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4416",
        "enumName": "河源市",
        "children": [
          {
            "parentEnumValue": "4416",
            "enumValue": "441600",
            "enumName": "河源市本级"
          },
          {
            "parentEnumValue": "4416",
            "enumValue": "441602",
            "enumName": "源城区"
          },
          {
            "parentEnumValue": "4416",
            "enumValue": "441621",
            "enumName": "紫金县"
          },
          {
            "parentEnumValue": "4416",
            "enumValue": "441622",
            "enumName": "龙川县"
          },
          {
            "parentEnumValue": "4416",
            "enumValue": "441623",
            "enumName": "连平县"
          },
          {
            "parentEnumValue": "4416",
            "enumValue": "441624",
            "enumName": "和平县"
          },
          {
            "parentEnumValue": "4416",
            "enumValue": "441625",
            "enumName": "东源县"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4417",
        "enumName": "阳江市",
        "children": [
          {
            "parentEnumValue": "4417",
            "enumValue": "441704",
            "enumName": "阳东区"
          },
          {
            "parentEnumValue": "4417",
            "enumValue": "441700",
            "enumName": "阳江市本级"
          },
          {
            "parentEnumValue": "4417",
            "enumValue": "441702",
            "enumName": "江城区"
          },
          {
            "parentEnumValue": "4417",
            "enumValue": "441721",
            "enumName": "阳西县"
          },
          {
            "parentEnumValue": "4417",
            "enumValue": "441723",
            "enumName": "阳东县"
          },
          {
            "parentEnumValue": "4417",
            "enumValue": "441781",
            "enumName": "阳春市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4418",
        "enumName": "清远市",
        "children": [
          {
            "parentEnumValue": "4418",
            "enumValue": "441803",
            "enumName": "清新区"
          },
          {
            "parentEnumValue": "4418",
            "enumValue": "441800",
            "enumName": "清远市本级"
          },
          {
            "parentEnumValue": "4418",
            "enumValue": "441802",
            "enumName": "清城区"
          },
          {
            "parentEnumValue": "4418",
            "enumValue": "441821",
            "enumName": "佛冈县"
          },
          {
            "parentEnumValue": "4418",
            "enumValue": "441823",
            "enumName": "阳山县"
          },
          {
            "parentEnumValue": "4418",
            "enumValue": "441825",
            "enumName": "连山壮族瑶族自治县"
          },
          {
            "parentEnumValue": "4418",
            "enumValue": "441826",
            "enumName": "连南瑶族自治县"
          },
          {
            "parentEnumValue": "4418",
            "enumValue": "441827",
            "enumName": "清新县"
          },
          {
            "parentEnumValue": "4418",
            "enumValue": "441881",
            "enumName": "英德市"
          },
          {
            "parentEnumValue": "4418",
            "enumValue": "441882",
            "enumName": "连州市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4419",
        "enumName": "东莞市",
        "children": [
          {
            "parentEnumValue": "4419",
            "enumValue": "441900",
            "enumName": "东莞市本级"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4420",
        "enumName": "中山市",
        "children": [
          {
            "parentEnumValue": "4420",
            "enumValue": "442000",
            "enumName": "中山市本级"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4451",
        "enumName": "潮州市",
        "children": [
          {
            "parentEnumValue": "4451",
            "enumValue": "445103",
            "enumName": "潮安区"
          },
          {
            "parentEnumValue": "4451",
            "enumValue": "445100",
            "enumName": "潮州市本级"
          },
          {
            "parentEnumValue": "4451",
            "enumValue": "445102",
            "enumName": "湘桥区"
          },
          {
            "parentEnumValue": "4451",
            "enumValue": "445121",
            "enumName": "潮安县"
          },
          {
            "parentEnumValue": "4451",
            "enumValue": "445122",
            "enumName": "饶平县"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4452",
        "enumName": "揭阳市",
        "children": [
          {
            "parentEnumValue": "4452",
            "enumValue": "445203",
            "enumName": "揭东区"
          },
          {
            "parentEnumValue": "4452",
            "enumValue": "445200",
            "enumName": "揭阳市本级"
          },
          {
            "parentEnumValue": "4452",
            "enumValue": "445202",
            "enumName": "榕城区"
          },
          {
            "parentEnumValue": "4452",
            "enumValue": "445221",
            "enumName": "揭东县"
          },
          {
            "parentEnumValue": "4452",
            "enumValue": "445222",
            "enumName": "揭西县"
          },
          {
            "parentEnumValue": "4452",
            "enumValue": "445224",
            "enumName": "惠来县"
          },
          {
            "parentEnumValue": "4452",
            "enumValue": "445281",
            "enumName": "普宁市"
          }
        ]
      },
      {
        "parentEnumValue": "44",
        "enumValue": "4453",
        "enumName": "云浮市",
        "children": [
          {
            "parentEnumValue": "4453",
            "enumValue": "445303",
            "enumName": "云安区"
          },
          {
            "parentEnumValue": "4453",
            "enumValue": "445300",
            "enumName": "云浮市本级"
          },
          {
            "parentEnumValue": "4453",
            "enumValue": "445302",
            "enumName": "云城区"
          },
          {
            "parentEnumValue": "4453",
            "enumValue": "445321",
            "enumName": "新兴县"
          },
          {
            "parentEnumValue": "4453",
            "enumValue": "445322",
            "enumName": "郁南县"
          },
          {
            "parentEnumValue": "4453",
            "enumValue": "445323",
            "enumName": "云安县"
          },
          {
            "parentEnumValue": "4453",
            "enumValue": "445381",
            "enumName": "罗定市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "45",
    "enumName": "广西壮族自治区",
    "children": [
      {
        "parentEnumValue": "45",
        "enumValue": "4501",
        "enumName": "南宁市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4501",
            "enumValue": "450100",
            "enumName": "南宁市本级"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450181",
            "enumName": "横州市"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450110",
            "enumName": "武鸣区"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450102",
            "enumName": "兴宁区"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450103",
            "enumName": "青秀区"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450105",
            "enumName": "江南区"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450107",
            "enumName": "西乡塘区"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450108",
            "enumName": "良庆区"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450109",
            "enumName": "邕宁区"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450122",
            "enumName": "武鸣县"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450123",
            "enumName": "隆安县"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450124",
            "enumName": "马山县"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450125",
            "enumName": "上林县"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450126",
            "enumName": "宾阳县"
          },
          {
            "parentEnumValue": "4501",
            "enumValue": "450127",
            "enumName": "横县"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4502",
        "enumName": "柳州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4502",
            "enumValue": "450200",
            "enumName": "柳州市本级"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450202",
            "enumName": "城中区"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450203",
            "enumName": "鱼峰区"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450204",
            "enumName": "柳南区"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450205",
            "enumName": "柳北区"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450221",
            "enumName": "柳江县"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450222",
            "enumName": "柳城县"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450223",
            "enumName": "鹿寨县"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450224",
            "enumName": "融安县"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450225",
            "enumName": "融水苗族自治县"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450226",
            "enumName": "三江侗族自治县"
          },
          {
            "parentEnumValue": "4502",
            "enumValue": "450206",
            "enumName": "柳江区"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4503",
        "enumName": "桂林市",
        "children": [
          {
            "parentEnumValue": "4503",
            "enumValue": "450381",
            "enumName": "荔浦市"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450312",
            "enumName": "临桂区"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450300",
            "enumName": "桂林市本级"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450302",
            "enumName": "秀峰区"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450303",
            "enumName": "叠彩区"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450304",
            "enumName": "象山区"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450305",
            "enumName": "七星区"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450311",
            "enumName": "雁山区"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450321",
            "enumName": "阳朔县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450322",
            "enumName": "临桂县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450323",
            "enumName": "灵川县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450324",
            "enumName": "全州县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450325",
            "enumName": "兴安县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450326",
            "enumName": "永福县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450327",
            "enumName": "灌阳县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450328",
            "enumName": "龙胜各族自治县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450329",
            "enumName": "资源县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450330",
            "enumName": "平乐县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450331",
            "enumName": "荔浦县"
          },
          {
            "parentEnumValue": "4503",
            "enumValue": "450332",
            "enumName": "恭城瑶族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4504",
        "enumName": "梧州市",
        "children": [
          {
            "parentEnumValue": "4504",
            "enumValue": "450400",
            "enumName": "梧州市本级"
          },
          {
            "parentEnumValue": "4504",
            "enumValue": "450403",
            "enumName": "万秀区"
          },
          {
            "parentEnumValue": "4504",
            "enumValue": "450404",
            "enumName": "蝶山区"
          },
          {
            "parentEnumValue": "4504",
            "enumValue": "450405",
            "enumName": "长洲区"
          },
          {
            "parentEnumValue": "4504",
            "enumValue": "450421",
            "enumName": "苍梧县"
          },
          {
            "parentEnumValue": "4504",
            "enumValue": "450422",
            "enumName": "藤县"
          },
          {
            "parentEnumValue": "4504",
            "enumValue": "450423",
            "enumName": "蒙山县"
          },
          {
            "parentEnumValue": "4504",
            "enumValue": "450481",
            "enumName": "岑溪市"
          },
          {
            "parentEnumValue": "4504",
            "enumValue": "450406",
            "enumName": "龙圩区"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4505",
        "enumName": "北海市",
        "children": [
          {
            "parentEnumValue": "4505",
            "enumValue": "450500",
            "enumName": "北海市本级"
          },
          {
            "parentEnumValue": "4505",
            "enumValue": "450502",
            "enumName": "海城区"
          },
          {
            "parentEnumValue": "4505",
            "enumValue": "450503",
            "enumName": "银海区"
          },
          {
            "parentEnumValue": "4505",
            "enumValue": "450512",
            "enumName": "铁山港区"
          },
          {
            "parentEnumValue": "4505",
            "enumValue": "450521",
            "enumName": "合浦县"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4506",
        "enumName": "防城港市",
        "children": [
          {
            "parentEnumValue": "4506",
            "enumValue": "450600",
            "enumName": "防城港市本级"
          },
          {
            "parentEnumValue": "4506",
            "enumValue": "450602",
            "enumName": "港口区"
          },
          {
            "parentEnumValue": "4506",
            "enumValue": "450603",
            "enumName": "防城区"
          },
          {
            "parentEnumValue": "4506",
            "enumValue": "450621",
            "enumName": "上思县"
          },
          {
            "parentEnumValue": "4506",
            "enumValue": "450681",
            "enumName": "东兴市"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4507",
        "enumName": "钦州市",
        "children": [
          {
            "parentEnumValue": "4507",
            "enumValue": "450700",
            "enumName": "钦州市本级"
          },
          {
            "parentEnumValue": "4507",
            "enumValue": "450702",
            "enumName": "钦南区"
          },
          {
            "parentEnumValue": "4507",
            "enumValue": "450703",
            "enumName": "钦北区"
          },
          {
            "parentEnumValue": "4507",
            "enumValue": "450721",
            "enumName": "灵山县"
          },
          {
            "parentEnumValue": "4507",
            "enumValue": "450722",
            "enumName": "浦北县"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4508",
        "enumName": "贵港市",
        "children": [
          {
            "parentEnumValue": "4508",
            "enumValue": "450800",
            "enumName": "贵港市本级"
          },
          {
            "parentEnumValue": "4508",
            "enumValue": "450802",
            "enumName": "港北区"
          },
          {
            "parentEnumValue": "4508",
            "enumValue": "450803",
            "enumName": "港南区"
          },
          {
            "parentEnumValue": "4508",
            "enumValue": "450804",
            "enumName": "覃塘区"
          },
          {
            "parentEnumValue": "4508",
            "enumValue": "450821",
            "enumName": "平南县"
          },
          {
            "parentEnumValue": "4508",
            "enumValue": "450881",
            "enumName": "桂平市"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4509",
        "enumName": "玉林市",
        "children": [
          {
            "parentEnumValue": "4509",
            "enumValue": "450903",
            "enumName": "福绵区"
          },
          {
            "parentEnumValue": "4509",
            "enumValue": "450900",
            "enumName": "玉林市本级"
          },
          {
            "parentEnumValue": "4509",
            "enumValue": "450902",
            "enumName": "玉州区"
          },
          {
            "parentEnumValue": "4509",
            "enumValue": "450921",
            "enumName": "容县"
          },
          {
            "parentEnumValue": "4509",
            "enumValue": "450922",
            "enumName": "陆川县"
          },
          {
            "parentEnumValue": "4509",
            "enumValue": "450923",
            "enumName": "博白县"
          },
          {
            "parentEnumValue": "4509",
            "enumValue": "450924",
            "enumName": "兴业县"
          },
          {
            "parentEnumValue": "4509",
            "enumValue": "450981",
            "enumName": "北流市"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4510",
        "enumName": "百色市",
        "children": [
          {
            "parentEnumValue": "4510",
            "enumValue": "451082",
            "enumName": "平果市"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451081",
            "enumName": "靖西市"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451003",
            "enumName": "田阳区"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451000",
            "enumName": "百色市本级"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451002",
            "enumName": "右江区"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451021",
            "enumName": "田阳县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451022",
            "enumName": "田东县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451023",
            "enumName": "平果县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451024",
            "enumName": "德保县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451025",
            "enumName": "靖西县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451026",
            "enumName": "那坡县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451027",
            "enumName": "凌云县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451028",
            "enumName": "乐业县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451029",
            "enumName": "田林县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451030",
            "enumName": "西林县"
          },
          {
            "parentEnumValue": "4510",
            "enumValue": "451031",
            "enumName": "隆林各族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4511",
        "enumName": "贺州市",
        "children": [
          {
            "parentEnumValue": "4511",
            "enumValue": "451103",
            "enumName": "平桂区"
          },
          {
            "parentEnumValue": "4511",
            "enumValue": "451100",
            "enumName": "贺州市本级"
          },
          {
            "parentEnumValue": "4511",
            "enumValue": "451102",
            "enumName": "八步区"
          },
          {
            "parentEnumValue": "4511",
            "enumValue": "451121",
            "enumName": "昭平县"
          },
          {
            "parentEnumValue": "4511",
            "enumValue": "451122",
            "enumName": "钟山县"
          },
          {
            "parentEnumValue": "4511",
            "enumValue": "451123",
            "enumName": "富川瑶族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4512",
        "enumName": "河池市",
        "children": [
          {
            "parentEnumValue": "4512",
            "enumValue": "451203",
            "enumName": "宜州区"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451200",
            "enumName": "河池市本级"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451202",
            "enumName": "金城江区"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451221",
            "enumName": "南丹县"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451222",
            "enumName": "天峨县"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451223",
            "enumName": "凤山县"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451224",
            "enumName": "东兰县"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451225",
            "enumName": "罗城仫佬族自治县"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451226",
            "enumName": "环江毛南族自治县"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451227",
            "enumName": "巴马瑶族自治县"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451228",
            "enumName": "都安瑶族自治县"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451229",
            "enumName": "大化瑶族自治县"
          },
          {
            "parentEnumValue": "4512",
            "enumValue": "451281",
            "enumName": "宜州市"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4513",
        "enumName": "来宾市",
        "children": [
          {
            "parentEnumValue": "4513",
            "enumValue": "451300",
            "enumName": "来宾市本级"
          },
          {
            "parentEnumValue": "4513",
            "enumValue": "451302",
            "enumName": "兴宾区"
          },
          {
            "parentEnumValue": "4513",
            "enumValue": "451321",
            "enumName": "忻城县"
          },
          {
            "parentEnumValue": "4513",
            "enumValue": "451322",
            "enumName": "象州县"
          },
          {
            "parentEnumValue": "4513",
            "enumValue": "451323",
            "enumName": "武宣县"
          },
          {
            "parentEnumValue": "4513",
            "enumValue": "451324",
            "enumName": "金秀瑶族自治县"
          },
          {
            "parentEnumValue": "4513",
            "enumValue": "451381",
            "enumName": "合山市"
          }
        ]
      },
      {
        "parentEnumValue": "45",
        "enumValue": "4514",
        "enumName": "崇左市",
        "children": [
          {
            "parentEnumValue": "4514",
            "enumValue": "451400",
            "enumName": "崇左市本级"
          },
          {
            "parentEnumValue": "4514",
            "enumValue": "451402",
            "enumName": "江州区"
          },
          {
            "parentEnumValue": "4514",
            "enumValue": "451421",
            "enumName": "扶绥县"
          },
          {
            "parentEnumValue": "4514",
            "enumValue": "451422",
            "enumName": "宁明县"
          },
          {
            "parentEnumValue": "4514",
            "enumValue": "451423",
            "enumName": "龙州县"
          },
          {
            "parentEnumValue": "4514",
            "enumValue": "451424",
            "enumName": "大新县"
          },
          {
            "parentEnumValue": "4514",
            "enumValue": "451425",
            "enumName": "天等县"
          },
          {
            "parentEnumValue": "4514",
            "enumValue": "451481",
            "enumName": "凭祥市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "46",
    "enumName": "海南省",
    "children": [
      {
        "parentEnumValue": "46",
        "enumValue": "4601",
        "enumName": "海口市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "4601",
            "enumValue": "460100",
            "enumName": "海口市本级"
          },
          {
            "parentEnumValue": "4601",
            "enumValue": "460105",
            "enumName": "秀英区"
          },
          {
            "parentEnumValue": "4601",
            "enumValue": "460106",
            "enumName": "龙华区"
          },
          {
            "parentEnumValue": "4601",
            "enumValue": "460107",
            "enumName": "琼山区"
          },
          {
            "parentEnumValue": "4601",
            "enumValue": "460108",
            "enumName": "美兰区"
          }
        ]
      },
      {
        "parentEnumValue": "46",
        "enumValue": "4603",
        "enumName": "三沙市",
        "children": [
          {
            "parentEnumValue": "4603",
            "enumValue": "460300",
            "enumName": "三沙市本级"
          },
          {
            "parentEnumValue": "4603",
            "enumValue": "469033",
            "enumName": "乐东黎族自治县"
          },
          {
            "parentEnumValue": "4603",
            "enumValue": "469031",
            "enumName": "昌江黎族自治县"
          },
          {
            "parentEnumValue": "4603",
            "enumValue": "469032",
            "enumName": "南沙群岛"
          },
          {
            "parentEnumValue": "4603",
            "enumValue": "460301",
            "enumName": "西沙区"
          },
          {
            "parentEnumValue": "4603",
            "enumValue": "460302",
            "enumName": "南沙区"
          }
        ]
      },
      {
        "parentEnumValue": "46",
        "enumValue": "4604",
        "enumName": "儋州市",
        "children": [
          {
            "parentEnumValue": "4604",
            "enumValue": "460400",
            "enumName": "儋州市本级"
          }
        ]
      },
      {
        "parentEnumValue": "46",
        "enumValue": "4602",
        "enumName": "三亚市",
        "children": [
          {
            "parentEnumValue": "4602",
            "enumValue": "460201",
            "enumName": "市辖区"
          },
          {
            "parentEnumValue": "4602",
            "enumValue": "460202",
            "enumName": "海棠区"
          },
          {
            "parentEnumValue": "4602",
            "enumValue": "460203",
            "enumName": "吉阳区"
          },
          {
            "parentEnumValue": "4602",
            "enumValue": "460204",
            "enumName": "天涯区"
          },
          {
            "parentEnumValue": "4602",
            "enumValue": "460205",
            "enumName": "崖州区"
          },
          {
            "parentEnumValue": "4602",
            "enumValue": "460200",
            "enumName": "三亚市本级"
          }
        ]
      },
      {
        "parentEnumValue": "46",
        "enumValue": "4690",
        "enumName": "省直辖县",
        "children": [
          {
            "parentEnumValue": "4690",
            "enumValue": "469000",
            "enumName": "省直辖县级行政单位本级"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469036",
            "enumName": "琼中黎族苗族自治县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469034",
            "enumName": "陵水黎族自治县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469035",
            "enumName": "保亭黎族苗族自治县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469001",
            "enumName": "五指山市"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469002",
            "enumName": "琼海市"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469003",
            "enumName": "儋州市"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469005",
            "enumName": "文昌市"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469006",
            "enumName": "万宁市"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469007",
            "enumName": "东方市"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469021",
            "enumName": "定安县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469022",
            "enumName": "屯昌县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469023",
            "enumName": "澄迈县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469024",
            "enumName": "临高县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469025",
            "enumName": "白沙黎族自治县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469026",
            "enumName": "昌江黎族自治县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469027",
            "enumName": "乐东黎族自治县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469028",
            "enumName": "陵水黎族自治县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469029",
            "enumName": "保亭黎族苗族自治县"
          },
          {
            "parentEnumValue": "4690",
            "enumValue": "469030",
            "enumName": "琼中黎族苗族自治县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "50",
    "enumName": "重庆市",
    "enumDesc": "zdcs",
    "children": [
      {
        "parentEnumValue": "50",
        "enumValue": "5001",
        "enumName": "市辖区",
        "children": [
          {
            "parentEnumValue": "5001",
            "enumValue": "500100",
            "enumName": "重庆市本级"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500101",
            "enumName": "万州区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500223",
            "enumName": "潼南县"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500226",
            "enumName": "荣昌县"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500227",
            "enumName": "璧山县"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500120",
            "enumName": "璧山区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500152",
            "enumName": "潼南区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500153",
            "enumName": "荣昌区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500155",
            "enumName": "梁平区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500156",
            "enumName": "武隆区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500102",
            "enumName": "涪陵区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500103",
            "enumName": "渝中区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500104",
            "enumName": "大渡口区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500105",
            "enumName": "江北区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500106",
            "enumName": "沙坪坝区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500107",
            "enumName": "九龙坡区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500108",
            "enumName": "南岸区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500109",
            "enumName": "北碚区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500110",
            "enumName": "綦江区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500111",
            "enumName": "大足区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500112",
            "enumName": "渝北区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500113",
            "enumName": "巴南区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500114",
            "enumName": "黔江区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500115",
            "enumName": "长寿区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500116",
            "enumName": "江津区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500117",
            "enumName": "合川区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500118",
            "enumName": "永川区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500119",
            "enumName": "南川区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500151",
            "enumName": "铜梁区"
          },
          {
            "parentEnumValue": "5001",
            "enumValue": "500154",
            "enumName": "开州区"
          }
        ]
      },
      {
        "parentEnumValue": "50",
        "enumValue": "5002",
        "enumName": "重庆市辖县",
        "children": [
          {
            "parentEnumValue": "5002",
            "enumValue": "500222",
            "enumName": "綦江县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500224",
            "enumName": "铜梁县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500225",
            "enumName": "大足县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500228",
            "enumName": "梁平县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500229",
            "enumName": "城口县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500230",
            "enumName": "丰都县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500231",
            "enumName": "垫江县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500232",
            "enumName": "武隆县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500233",
            "enumName": "忠县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500234",
            "enumName": "开县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500235",
            "enumName": "云阳县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500236",
            "enumName": "奉节县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500237",
            "enumName": "巫山县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500238",
            "enumName": "巫溪县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500240",
            "enumName": "石柱土家族自治县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500241",
            "enumName": "秀山土家族苗族自治县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500242",
            "enumName": "酉阳土家族苗族自治县"
          },
          {
            "parentEnumValue": "5002",
            "enumValue": "500243",
            "enumName": "彭水苗族土家族自治县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "51",
    "enumName": "四川省",
    "children": [
      {
        "parentEnumValue": "51",
        "enumValue": "5101",
        "enumName": "成都市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "5101",
            "enumValue": "510117",
            "enumName": "郫都区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510100",
            "enumName": "成都市本级"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "512081",
            "enumName": "简阳市"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510185",
            "enumName": "简阳市"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510118",
            "enumName": "新津区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510116",
            "enumName": "双流区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510104",
            "enumName": "锦江区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510105",
            "enumName": "青羊区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510106",
            "enumName": "金牛区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510107",
            "enumName": "武侯区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510108",
            "enumName": "成华区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510112",
            "enumName": "龙泉驿区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510113",
            "enumName": "青白江区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510114",
            "enumName": "新都区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510115",
            "enumName": "温江区"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510121",
            "enumName": "金堂县"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510122",
            "enumName": "双流县"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510124",
            "enumName": "郫县"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510129",
            "enumName": "大邑县"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510131",
            "enumName": "蒲江县"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510132",
            "enumName": "新津县"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510181",
            "enumName": "都江堰市"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510182",
            "enumName": "彭州市"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510183",
            "enumName": "邛崃市"
          },
          {
            "parentEnumValue": "5101",
            "enumValue": "510184",
            "enumName": "崇州市"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5103",
        "enumName": "自贡市",
        "children": [
          {
            "parentEnumValue": "5103",
            "enumValue": "510300",
            "enumName": "自贡市本级"
          },
          {
            "parentEnumValue": "5103",
            "enumValue": "510302",
            "enumName": "自流井区"
          },
          {
            "parentEnumValue": "5103",
            "enumValue": "510303",
            "enumName": "贡井区"
          },
          {
            "parentEnumValue": "5103",
            "enumValue": "510304",
            "enumName": "大安区"
          },
          {
            "parentEnumValue": "5103",
            "enumValue": "510311",
            "enumName": "沿滩区"
          },
          {
            "parentEnumValue": "5103",
            "enumValue": "510321",
            "enumName": "荣县"
          },
          {
            "parentEnumValue": "5103",
            "enumValue": "510322",
            "enumName": "富顺县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5104",
        "enumName": "攀枝花市",
        "children": [
          {
            "parentEnumValue": "5104",
            "enumValue": "510400",
            "enumName": "攀枝花市本级"
          },
          {
            "parentEnumValue": "5104",
            "enumValue": "510402",
            "enumName": "东区"
          },
          {
            "parentEnumValue": "5104",
            "enumValue": "510403",
            "enumName": "西区"
          },
          {
            "parentEnumValue": "5104",
            "enumValue": "510411",
            "enumName": "仁和区"
          },
          {
            "parentEnumValue": "5104",
            "enumValue": "510421",
            "enumName": "米易县"
          },
          {
            "parentEnumValue": "5104",
            "enumValue": "510422",
            "enumName": "盐边县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5105",
        "enumName": "泸州市",
        "children": [
          {
            "parentEnumValue": "5105",
            "enumValue": "510500",
            "enumName": "泸州市本级"
          },
          {
            "parentEnumValue": "5105",
            "enumValue": "510502",
            "enumName": "江阳区"
          },
          {
            "parentEnumValue": "5105",
            "enumValue": "510503",
            "enumName": "纳溪区"
          },
          {
            "parentEnumValue": "5105",
            "enumValue": "510504",
            "enumName": "龙马潭区"
          },
          {
            "parentEnumValue": "5105",
            "enumValue": "510521",
            "enumName": "泸县"
          },
          {
            "parentEnumValue": "5105",
            "enumValue": "510522",
            "enumName": "合江县"
          },
          {
            "parentEnumValue": "5105",
            "enumValue": "510524",
            "enumName": "叙永县"
          },
          {
            "parentEnumValue": "5105",
            "enumValue": "510525",
            "enumName": "古蔺县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5106",
        "enumName": "德阳市",
        "children": [
          {
            "parentEnumValue": "5106",
            "enumValue": "510604",
            "enumName": "罗江区"
          },
          {
            "parentEnumValue": "5106",
            "enumValue": "510600",
            "enumName": "德阳市本级"
          },
          {
            "parentEnumValue": "5106",
            "enumValue": "510603",
            "enumName": "旌阳区"
          },
          {
            "parentEnumValue": "5106",
            "enumValue": "510623",
            "enumName": "中江县"
          },
          {
            "parentEnumValue": "5106",
            "enumValue": "510626",
            "enumName": "罗江县"
          },
          {
            "parentEnumValue": "5106",
            "enumValue": "510681",
            "enumName": "广汉市"
          },
          {
            "parentEnumValue": "5106",
            "enumValue": "510682",
            "enumName": "什邡市"
          },
          {
            "parentEnumValue": "5106",
            "enumValue": "510683",
            "enumName": "绵竹市"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5107",
        "enumName": "绵阳市",
        "children": [
          {
            "parentEnumValue": "5107",
            "enumValue": "510705",
            "enumName": "安州区"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510700",
            "enumName": "绵阳市本级"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510703",
            "enumName": "涪城区"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510704",
            "enumName": "游仙区"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510722",
            "enumName": "三台县"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510723",
            "enumName": "盐亭县"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510724",
            "enumName": "安县"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510725",
            "enumName": "梓潼县"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510726",
            "enumName": "北川羌族自治县"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510727",
            "enumName": "平武县"
          },
          {
            "parentEnumValue": "5107",
            "enumValue": "510781",
            "enumName": "江油市"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5108",
        "enumName": "广元市",
        "children": [
          {
            "parentEnumValue": "5108",
            "enumValue": "510800",
            "enumName": "广元市本级"
          },
          {
            "parentEnumValue": "5108",
            "enumValue": "510802",
            "enumName": "利州区"
          },
          {
            "parentEnumValue": "5108",
            "enumValue": "510811",
            "enumName": "昭化区"
          },
          {
            "parentEnumValue": "5108",
            "enumValue": "510812",
            "enumName": "朝天区"
          },
          {
            "parentEnumValue": "5108",
            "enumValue": "510821",
            "enumName": "旺苍县"
          },
          {
            "parentEnumValue": "5108",
            "enumValue": "510822",
            "enumName": "青川县"
          },
          {
            "parentEnumValue": "5108",
            "enumValue": "510823",
            "enumName": "剑阁县"
          },
          {
            "parentEnumValue": "5108",
            "enumValue": "510824",
            "enumName": "苍溪县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5109",
        "enumName": "遂宁市",
        "children": [
          {
            "parentEnumValue": "5109",
            "enumValue": "510981",
            "enumName": "射洪市"
          },
          {
            "parentEnumValue": "5109",
            "enumValue": "510900",
            "enumName": "遂宁市本级"
          },
          {
            "parentEnumValue": "5109",
            "enumValue": "510903",
            "enumName": "船山区"
          },
          {
            "parentEnumValue": "5109",
            "enumValue": "510904",
            "enumName": "安居区"
          },
          {
            "parentEnumValue": "5109",
            "enumValue": "510921",
            "enumName": "蓬溪县"
          },
          {
            "parentEnumValue": "5109",
            "enumValue": "510922",
            "enumName": "射洪县"
          },
          {
            "parentEnumValue": "5109",
            "enumValue": "510923",
            "enumName": "大英县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5110",
        "enumName": "内江市",
        "children": [
          {
            "parentEnumValue": "5110",
            "enumValue": "511083",
            "enumName": "隆昌市"
          },
          {
            "parentEnumValue": "5110",
            "enumValue": "511000",
            "enumName": "内江市本级"
          },
          {
            "parentEnumValue": "5110",
            "enumValue": "511002",
            "enumName": "市中区"
          },
          {
            "parentEnumValue": "5110",
            "enumValue": "511011",
            "enumName": "东兴区"
          },
          {
            "parentEnumValue": "5110",
            "enumValue": "511024",
            "enumName": "威远县"
          },
          {
            "parentEnumValue": "5110",
            "enumValue": "511025",
            "enumName": "资中县"
          },
          {
            "parentEnumValue": "5110",
            "enumValue": "511028",
            "enumName": "隆昌县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5111",
        "enumName": "乐山市",
        "children": [
          {
            "parentEnumValue": "5111",
            "enumValue": "511100",
            "enumName": "乐山市本级"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511102",
            "enumName": "市中区"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511111",
            "enumName": "沙湾区"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511112",
            "enumName": "五通桥区"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511113",
            "enumName": "金口河区"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511123",
            "enumName": "犍为县"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511124",
            "enumName": "井研县"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511126",
            "enumName": "夹江县"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511129",
            "enumName": "沐川县"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511132",
            "enumName": "峨边彝族自治县"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511133",
            "enumName": "马边彝族自治县"
          },
          {
            "parentEnumValue": "5111",
            "enumValue": "511181",
            "enumName": "峨眉山市"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5113",
        "enumName": "南充市",
        "children": [
          {
            "parentEnumValue": "5113",
            "enumValue": "511300",
            "enumName": "南充市本级"
          },
          {
            "parentEnumValue": "5113",
            "enumValue": "511302",
            "enumName": "顺庆区"
          },
          {
            "parentEnumValue": "5113",
            "enumValue": "511303",
            "enumName": "高坪区"
          },
          {
            "parentEnumValue": "5113",
            "enumValue": "511304",
            "enumName": "嘉陵区"
          },
          {
            "parentEnumValue": "5113",
            "enumValue": "511321",
            "enumName": "南部县"
          },
          {
            "parentEnumValue": "5113",
            "enumValue": "511322",
            "enumName": "营山县"
          },
          {
            "parentEnumValue": "5113",
            "enumValue": "511323",
            "enumName": "蓬安县"
          },
          {
            "parentEnumValue": "5113",
            "enumValue": "511324",
            "enumName": "仪陇县"
          },
          {
            "parentEnumValue": "5113",
            "enumValue": "511325",
            "enumName": "西充县"
          },
          {
            "parentEnumValue": "5113",
            "enumValue": "511381",
            "enumName": "阆中市"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5114",
        "enumName": "眉山市",
        "children": [
          {
            "parentEnumValue": "5114",
            "enumValue": "511403",
            "enumName": "彭山区"
          },
          {
            "parentEnumValue": "5114",
            "enumValue": "511400",
            "enumName": "眉山市本级"
          },
          {
            "parentEnumValue": "5114",
            "enumValue": "511402",
            "enumName": "东坡区"
          },
          {
            "parentEnumValue": "5114",
            "enumValue": "511421",
            "enumName": "仁寿县"
          },
          {
            "parentEnumValue": "5114",
            "enumValue": "511422",
            "enumName": "彭山县"
          },
          {
            "parentEnumValue": "5114",
            "enumValue": "511423",
            "enumName": "洪雅县"
          },
          {
            "parentEnumValue": "5114",
            "enumValue": "511424",
            "enumName": "丹棱县"
          },
          {
            "parentEnumValue": "5114",
            "enumValue": "511425",
            "enumName": "青神县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5115",
        "enumName": "宜宾市",
        "children": [
          {
            "parentEnumValue": "5115",
            "enumValue": "511503",
            "enumName": "南溪区"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511500",
            "enumName": "宜宾市本级"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511502",
            "enumName": "翠屏区"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511504",
            "enumName": "叙州区"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511521",
            "enumName": "宜宾县"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511522",
            "enumName": "南溪县"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511523",
            "enumName": "江安县"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511524",
            "enumName": "长宁县"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511525",
            "enumName": "高县"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511526",
            "enumName": "珙县"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511527",
            "enumName": "筠连县"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511528",
            "enumName": "兴文县"
          },
          {
            "parentEnumValue": "5115",
            "enumValue": "511529",
            "enumName": "屏山县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5116",
        "enumName": "广安市",
        "children": [
          {
            "parentEnumValue": "5116",
            "enumValue": "511600",
            "enumName": "广安市本级"
          },
          {
            "parentEnumValue": "5116",
            "enumValue": "511602",
            "enumName": "广安区"
          },
          {
            "parentEnumValue": "5116",
            "enumValue": "511621",
            "enumName": "岳池县"
          },
          {
            "parentEnumValue": "5116",
            "enumValue": "511622",
            "enumName": "武胜县"
          },
          {
            "parentEnumValue": "5116",
            "enumValue": "511623",
            "enumName": "邻水县"
          },
          {
            "parentEnumValue": "5116",
            "enumValue": "511681",
            "enumName": "华蓥市"
          },
          {
            "parentEnumValue": "5116",
            "enumValue": "511603",
            "enumName": "前锋区"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5117",
        "enumName": "达州市",
        "children": [
          {
            "parentEnumValue": "5117",
            "enumValue": "511703",
            "enumName": "达川区"
          },
          {
            "parentEnumValue": "5117",
            "enumValue": "511700",
            "enumName": "达州市本级"
          },
          {
            "parentEnumValue": "5117",
            "enumValue": "511702",
            "enumName": "通川区"
          },
          {
            "parentEnumValue": "5117",
            "enumValue": "511721",
            "enumName": "达县"
          },
          {
            "parentEnumValue": "5117",
            "enumValue": "511722",
            "enumName": "宣汉县"
          },
          {
            "parentEnumValue": "5117",
            "enumValue": "511723",
            "enumName": "开江县"
          },
          {
            "parentEnumValue": "5117",
            "enumValue": "511724",
            "enumName": "大竹县"
          },
          {
            "parentEnumValue": "5117",
            "enumValue": "511725",
            "enumName": "渠县"
          },
          {
            "parentEnumValue": "5117",
            "enumValue": "511781",
            "enumName": "万源市"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5118",
        "enumName": "雅安市",
        "children": [
          {
            "parentEnumValue": "5118",
            "enumValue": "511803",
            "enumName": "名山区"
          },
          {
            "parentEnumValue": "5118",
            "enumValue": "511800",
            "enumName": "雅安市本级"
          },
          {
            "parentEnumValue": "5118",
            "enumValue": "511802",
            "enumName": "雨城区"
          },
          {
            "parentEnumValue": "5118",
            "enumValue": "511821",
            "enumName": "名山县"
          },
          {
            "parentEnumValue": "5118",
            "enumValue": "511822",
            "enumName": "荥经县"
          },
          {
            "parentEnumValue": "5118",
            "enumValue": "511823",
            "enumName": "汉源县"
          },
          {
            "parentEnumValue": "5118",
            "enumValue": "511824",
            "enumName": "石棉县"
          },
          {
            "parentEnumValue": "5118",
            "enumValue": "511825",
            "enumName": "天全县"
          },
          {
            "parentEnumValue": "5118",
            "enumValue": "511826",
            "enumName": "芦山县"
          },
          {
            "parentEnumValue": "5118",
            "enumValue": "511827",
            "enumName": "宝兴县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5119",
        "enumName": "巴中市",
        "children": [
          {
            "parentEnumValue": "5119",
            "enumValue": "511900",
            "enumName": "巴中市本级"
          },
          {
            "parentEnumValue": "5119",
            "enumValue": "511902",
            "enumName": "巴州区"
          },
          {
            "parentEnumValue": "5119",
            "enumValue": "511921",
            "enumName": "通江县"
          },
          {
            "parentEnumValue": "5119",
            "enumValue": "511922",
            "enumName": "南江县"
          },
          {
            "parentEnumValue": "5119",
            "enumValue": "511923",
            "enumName": "平昌县"
          },
          {
            "parentEnumValue": "5119",
            "enumValue": "511903",
            "enumName": "恩阳区"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5120",
        "enumName": "资阳市",
        "children": [
          {
            "parentEnumValue": "5120",
            "enumValue": "512000",
            "enumName": "资阳市本级"
          },
          {
            "parentEnumValue": "5120",
            "enumValue": "512002",
            "enumName": "雁江区"
          },
          {
            "parentEnumValue": "5120",
            "enumValue": "512021",
            "enumName": "安岳县"
          },
          {
            "parentEnumValue": "5120",
            "enumValue": "512022",
            "enumName": "乐至县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5132",
        "enumName": "阿坝藏族羌族自治州",
        "children": [
          {
            "parentEnumValue": "5132",
            "enumValue": "513200",
            "enumName": "阿坝藏族羌族自治州本级"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513201",
            "enumName": "马尔康市"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513221",
            "enumName": "汶川县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513222",
            "enumName": "理县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513223",
            "enumName": "茂县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513224",
            "enumName": "松潘县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513225",
            "enumName": "九寨沟县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513226",
            "enumName": "金川县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513227",
            "enumName": "小金县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513228",
            "enumName": "黑水县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513229",
            "enumName": "马尔康县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513230",
            "enumName": "壤塘县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513231",
            "enumName": "阿坝县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513232",
            "enumName": "若尔盖县"
          },
          {
            "parentEnumValue": "5132",
            "enumValue": "513233",
            "enumName": "红原县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5133",
        "enumName": "甘孜藏族自治州",
        "children": [
          {
            "parentEnumValue": "5133",
            "enumValue": "513300",
            "enumName": "甘孜藏族自治州本级"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513301",
            "enumName": "康定市"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513321",
            "enumName": "康定县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513322",
            "enumName": "泸定县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513323",
            "enumName": "丹巴县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513324",
            "enumName": "九龙县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513325",
            "enumName": "雅江县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513326",
            "enumName": "道孚县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513327",
            "enumName": "炉霍县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513328",
            "enumName": "甘孜县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513329",
            "enumName": "新龙县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513330",
            "enumName": "德格县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513331",
            "enumName": "白玉县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513332",
            "enumName": "石渠县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513333",
            "enumName": "色达县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513334",
            "enumName": "理塘县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513335",
            "enumName": "巴塘县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513336",
            "enumName": "乡城县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513337",
            "enumName": "稻城县"
          },
          {
            "parentEnumValue": "5133",
            "enumValue": "513338",
            "enumName": "得荣县"
          }
        ]
      },
      {
        "parentEnumValue": "51",
        "enumValue": "5134",
        "enumName": "凉山彝族自治州",
        "children": [
          {
            "parentEnumValue": "5134",
            "enumValue": "513400",
            "enumName": "凉山彝族自治州本级"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513402",
            "enumName": "会理市"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513401",
            "enumName": "西昌市"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513422",
            "enumName": "木里藏族自治县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513423",
            "enumName": "盐源县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513424",
            "enumName": "德昌县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513425",
            "enumName": "会理县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513426",
            "enumName": "会东县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513427",
            "enumName": "宁南县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513428",
            "enumName": "普格县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513429",
            "enumName": "布拖县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513430",
            "enumName": "金阳县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513431",
            "enumName": "昭觉县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513432",
            "enumName": "喜德县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513433",
            "enumName": "冕宁县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513434",
            "enumName": "越西县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513435",
            "enumName": "甘洛县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513436",
            "enumName": "美姑县"
          },
          {
            "parentEnumValue": "5134",
            "enumValue": "513437",
            "enumName": "雷波县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "52",
    "enumName": "贵州省",
    "children": [
      {
        "parentEnumValue": "52",
        "enumValue": "5201",
        "enumName": "贵阳市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "5201",
            "enumValue": "520100",
            "enumName": "贵阳市本级"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520115",
            "enumName": "观山湖区"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520102",
            "enumName": "南明区"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520103",
            "enumName": "云岩区"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520111",
            "enumName": "花溪区"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520112",
            "enumName": "乌当区"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520113",
            "enumName": "白云区"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520114",
            "enumName": "小河区"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520121",
            "enumName": "开阳县"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520122",
            "enumName": "息烽县"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520123",
            "enumName": "修文县"
          },
          {
            "parentEnumValue": "5201",
            "enumValue": "520181",
            "enumName": "清镇市"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5206",
        "enumName": "铜仁市",
        "children": [
          {
            "parentEnumValue": "5206",
            "enumValue": "522225",
            "enumName": "思南县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "522227",
            "enumName": "德江县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "522229",
            "enumName": "松桃苗族自治县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "522228",
            "enumName": "沿河土家族自治县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520602",
            "enumName": "碧江区"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520600",
            "enumName": "铜仁市本级"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "522222",
            "enumName": "江口县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "522223",
            "enumName": "玉屏侗族自治县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "522224",
            "enumName": "石阡县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "522226",
            "enumName": "印江土家族苗族自治县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520603",
            "enumName": "万山区"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520621",
            "enumName": "江口县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520628",
            "enumName": "松桃苗族自治县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520624",
            "enumName": "思南县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520627",
            "enumName": "沿河土家族自治县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520622",
            "enumName": "玉屏侗族自治县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520626",
            "enumName": "德江县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520625",
            "enumName": "印江土家族苗族自治县"
          },
          {
            "parentEnumValue": "5206",
            "enumValue": "520623",
            "enumName": "石阡县"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5205",
        "enumName": "毕节市",
        "children": [
          {
            "parentEnumValue": "5205",
            "enumValue": "522422",
            "enumName": "大方县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520502",
            "enumName": "七星关区"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520500",
            "enumName": "毕节市本级"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "522424",
            "enumName": "金沙县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "522423",
            "enumName": "黔西县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "522428",
            "enumName": "赫章县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "522427",
            "enumName": "威宁彝族回族苗族自治县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "522425",
            "enumName": "织金县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "522426",
            "enumName": "纳雍县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520581",
            "enumName": "黔西市"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520521",
            "enumName": "大方县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520522",
            "enumName": "黔西县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520523",
            "enumName": "金沙县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520524",
            "enumName": "织金县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520525",
            "enumName": "纳雍县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520526",
            "enumName": "威宁彝族回族苗族自治县"
          },
          {
            "parentEnumValue": "5205",
            "enumValue": "520527",
            "enumName": "赫章县"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5202",
        "enumName": "六盘水市",
        "children": [
          {
            "parentEnumValue": "5202",
            "enumValue": "520200",
            "enumName": "六盘水市本级"
          },
          {
            "parentEnumValue": "5202",
            "enumValue": "520204",
            "enumName": "水城区"
          },
          {
            "parentEnumValue": "5202",
            "enumValue": "520281",
            "enumName": "盘州市"
          },
          {
            "parentEnumValue": "5202",
            "enumValue": "520201",
            "enumName": "钟山区"
          },
          {
            "parentEnumValue": "5202",
            "enumValue": "520203",
            "enumName": "六枝特区"
          },
          {
            "parentEnumValue": "5202",
            "enumValue": "520221",
            "enumName": "水城县"
          },
          {
            "parentEnumValue": "5202",
            "enumValue": "520222",
            "enumName": "盘县"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5203",
        "enumName": "遵义市",
        "children": [
          {
            "parentEnumValue": "5203",
            "enumValue": "520300",
            "enumName": "遵义市本级"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520302",
            "enumName": "红花岗区"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520303",
            "enumName": "汇川区"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520321",
            "enumName": "遵义县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520304",
            "enumName": "播州区"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520322",
            "enumName": "桐梓县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520323",
            "enumName": "绥阳县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520324",
            "enumName": "正安县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520325",
            "enumName": "道真仡佬族苗族自治县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520326",
            "enumName": "务川仡佬族苗族自治县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520327",
            "enumName": "凤冈县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520328",
            "enumName": "湄潭县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520329",
            "enumName": "余庆县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520330",
            "enumName": "习水县"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520381",
            "enumName": "赤水市"
          },
          {
            "parentEnumValue": "5203",
            "enumValue": "520382",
            "enumName": "仁怀市"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5204",
        "enumName": "安顺市",
        "children": [
          {
            "parentEnumValue": "5204",
            "enumValue": "520403",
            "enumName": "平坝区"
          },
          {
            "parentEnumValue": "5204",
            "enumValue": "520400",
            "enumName": "安顺市本级"
          },
          {
            "parentEnumValue": "5204",
            "enumValue": "520402",
            "enumName": "西秀区"
          },
          {
            "parentEnumValue": "5204",
            "enumValue": "520421",
            "enumName": "平坝县"
          },
          {
            "parentEnumValue": "5204",
            "enumValue": "520422",
            "enumName": "普定县"
          },
          {
            "parentEnumValue": "5204",
            "enumValue": "520423",
            "enumName": "镇宁布依族苗族自治县"
          },
          {
            "parentEnumValue": "5204",
            "enumValue": "520424",
            "enumName": "关岭布依族苗族自治县"
          },
          {
            "parentEnumValue": "5204",
            "enumValue": "520425",
            "enumName": "紫云苗族布依族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5222",
        "enumName": "铜仁地区",
        "children": [
          {
            "parentEnumValue": "5222",
            "enumValue": "522200",
            "enumName": "铜仁地区本级(撤)"
          },
          {
            "parentEnumValue": "5222",
            "enumValue": "522201",
            "enumName": "铜仁市(撤)"
          },
          {
            "parentEnumValue": "5222",
            "enumValue": "522230",
            "enumName": "万山特区"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5223",
        "enumName": "黔西南布依族苗族自治州",
        "children": [
          {
            "parentEnumValue": "5223",
            "enumValue": "522300",
            "enumName": "黔西南布依族苗族自治州本级"
          },
          {
            "parentEnumValue": "5223",
            "enumValue": "522302",
            "enumName": "兴仁市"
          },
          {
            "parentEnumValue": "5223",
            "enumValue": "522301",
            "enumName": "兴义市"
          },
          {
            "parentEnumValue": "5223",
            "enumValue": "522322",
            "enumName": "兴仁县"
          },
          {
            "parentEnumValue": "5223",
            "enumValue": "522323",
            "enumName": "普安县"
          },
          {
            "parentEnumValue": "5223",
            "enumValue": "522324",
            "enumName": "晴隆县"
          },
          {
            "parentEnumValue": "5223",
            "enumValue": "522325",
            "enumName": "贞丰县"
          },
          {
            "parentEnumValue": "5223",
            "enumValue": "522326",
            "enumName": "望谟县"
          },
          {
            "parentEnumValue": "5223",
            "enumValue": "522327",
            "enumName": "册亨县"
          },
          {
            "parentEnumValue": "5223",
            "enumValue": "522328",
            "enumName": "安龙县"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5224",
        "enumName": "毕节地区",
        "children": [
          {
            "parentEnumValue": "5224",
            "enumValue": "522400",
            "enumName": "毕节地区本级"
          },
          {
            "parentEnumValue": "5224",
            "enumValue": "522401",
            "enumName": "毕节市"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5226",
        "enumName": "黔东南苗族侗族自治州",
        "children": [
          {
            "parentEnumValue": "5226",
            "enumValue": "522600",
            "enumName": "黔东南苗族侗族自治州本级"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522601",
            "enumName": "凯里市"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522622",
            "enumName": "黄平县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522623",
            "enumName": "施秉县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522624",
            "enumName": "三穗县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522625",
            "enumName": "镇远县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522626",
            "enumName": "岑巩县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522627",
            "enumName": "天柱县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522628",
            "enumName": "锦屏县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522629",
            "enumName": "剑河县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522630",
            "enumName": "台江县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522631",
            "enumName": "黎平县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522632",
            "enumName": "榕江县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522633",
            "enumName": "从江县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522634",
            "enumName": "雷山县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522635",
            "enumName": "麻江县"
          },
          {
            "parentEnumValue": "5226",
            "enumValue": "522636",
            "enumName": "丹寨县"
          }
        ]
      },
      {
        "parentEnumValue": "52",
        "enumValue": "5227",
        "enumName": "黔南布依族苗族自治州",
        "children": [
          {
            "parentEnumValue": "5227",
            "enumValue": "522700",
            "enumName": "黔南布依族苗族自治州本级"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522701",
            "enumName": "都匀市"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522702",
            "enumName": "福泉市"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522722",
            "enumName": "荔波县"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522723",
            "enumName": "贵定县"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522725",
            "enumName": "瓮安县"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522726",
            "enumName": "独山县"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522727",
            "enumName": "平塘县"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522728",
            "enumName": "罗甸县"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522729",
            "enumName": "长顺县"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522730",
            "enumName": "龙里县"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522731",
            "enumName": "惠水县"
          },
          {
            "parentEnumValue": "5227",
            "enumValue": "522732",
            "enumName": "三都水族自治县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "53",
    "enumName": "云南省",
    "children": [
      {
        "parentEnumValue": "53",
        "enumValue": "5301",
        "enumName": "昆明市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "5301",
            "enumValue": "530100",
            "enumName": "昆明市本级"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530115",
            "enumName": "晋宁区"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530114",
            "enumName": "呈贡区"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530102",
            "enumName": "五华区"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530103",
            "enumName": "盘龙区"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530111",
            "enumName": "官渡区"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530112",
            "enumName": "西山区"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530113",
            "enumName": "东川区"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530121",
            "enumName": "呈贡县"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530122",
            "enumName": "晋宁县"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530124",
            "enumName": "富民县"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530125",
            "enumName": "宜良县"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530126",
            "enumName": "石林彝族自治县"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530127",
            "enumName": "嵩明县"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530128",
            "enumName": "禄劝彝族苗族自治县"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530129",
            "enumName": "寻甸回族彝族自治县"
          },
          {
            "parentEnumValue": "5301",
            "enumValue": "530181",
            "enumName": "安宁市"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5303",
        "enumName": "曲靖市",
        "children": [
          {
            "parentEnumValue": "5303",
            "enumValue": "530303",
            "enumName": "沾益区"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530304",
            "enumName": "马龙区"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530300",
            "enumName": "曲靖市本级"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530302",
            "enumName": "麒麟区"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530321",
            "enumName": "马龙县"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530322",
            "enumName": "陆良县"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530323",
            "enumName": "师宗县"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530324",
            "enumName": "罗平县"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530325",
            "enumName": "富源县"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530326",
            "enumName": "会泽县"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530328",
            "enumName": "沾益县"
          },
          {
            "parentEnumValue": "5303",
            "enumValue": "530381",
            "enumName": "宣威市"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5304",
        "enumName": "玉溪市",
        "children": [
          {
            "parentEnumValue": "5304",
            "enumValue": "530403",
            "enumName": "江川区"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530481",
            "enumName": "澄江市"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530400",
            "enumName": "玉溪市本级"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530402",
            "enumName": "红塔区"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530421",
            "enumName": "江川县"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530422",
            "enumName": "澄江县"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530423",
            "enumName": "通海县"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530424",
            "enumName": "华宁县"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530425",
            "enumName": "易门县"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530426",
            "enumName": "峨山彝族自治县"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530427",
            "enumName": "新平彝族傣族自治县"
          },
          {
            "parentEnumValue": "5304",
            "enumValue": "530428",
            "enumName": "元江哈尼族彝族傣族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5305",
        "enumName": "保山市",
        "children": [
          {
            "parentEnumValue": "5305",
            "enumValue": "530581",
            "enumName": "腾冲市"
          },
          {
            "parentEnumValue": "5305",
            "enumValue": "530500",
            "enumName": "保山市本级"
          },
          {
            "parentEnumValue": "5305",
            "enumValue": "530502",
            "enumName": "隆阳区"
          },
          {
            "parentEnumValue": "5305",
            "enumValue": "530521",
            "enumName": "施甸县"
          },
          {
            "parentEnumValue": "5305",
            "enumValue": "530522",
            "enumName": "腾冲县"
          },
          {
            "parentEnumValue": "5305",
            "enumValue": "530523",
            "enumName": "龙陵县"
          },
          {
            "parentEnumValue": "5305",
            "enumValue": "530524",
            "enumName": "昌宁县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5306",
        "enumName": "昭通市",
        "children": [
          {
            "parentEnumValue": "5306",
            "enumValue": "530681",
            "enumName": "水富市"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530600",
            "enumName": "昭通市本级"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530602",
            "enumName": "昭阳区"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530621",
            "enumName": "鲁甸县"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530622",
            "enumName": "巧家县"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530623",
            "enumName": "盐津县"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530624",
            "enumName": "大关县"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530625",
            "enumName": "永善县"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530626",
            "enumName": "绥江县"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530627",
            "enumName": "镇雄县"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530628",
            "enumName": "彝良县"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530629",
            "enumName": "威信县"
          },
          {
            "parentEnumValue": "5306",
            "enumValue": "530630",
            "enumName": "水富县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5307",
        "enumName": "丽江市",
        "children": [
          {
            "parentEnumValue": "5307",
            "enumValue": "530700",
            "enumName": "丽江市本级"
          },
          {
            "parentEnumValue": "5307",
            "enumValue": "530702",
            "enumName": "古城区"
          },
          {
            "parentEnumValue": "5307",
            "enumValue": "530721",
            "enumName": "玉龙纳西族自治县"
          },
          {
            "parentEnumValue": "5307",
            "enumValue": "530722",
            "enumName": "永胜县"
          },
          {
            "parentEnumValue": "5307",
            "enumValue": "530723",
            "enumName": "华坪县"
          },
          {
            "parentEnumValue": "5307",
            "enumValue": "530724",
            "enumName": "宁蒗彝族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5308",
        "enumName": "普洱市",
        "children": [
          {
            "parentEnumValue": "5308",
            "enumValue": "530800",
            "enumName": "普洱市本级"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530802",
            "enumName": "思茅区"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530821",
            "enumName": "宁洱哈尼族彝族自治县"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530822",
            "enumName": "墨江哈尼族自治县"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530823",
            "enumName": "景东彝族自治县"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530824",
            "enumName": "景谷傣族彝族自治县"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530825",
            "enumName": "镇沅彝族哈尼族拉祜族自治县"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530826",
            "enumName": "江城哈尼族彝族自治县"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530827",
            "enumName": "孟连傣族拉祜族佤族自治县"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530828",
            "enumName": "澜沧拉祜族自治县"
          },
          {
            "parentEnumValue": "5308",
            "enumValue": "530829",
            "enumName": "西盟佤族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5309",
        "enumName": "临沧市",
        "children": [
          {
            "parentEnumValue": "5309",
            "enumValue": "530900",
            "enumName": "临沧市本级"
          },
          {
            "parentEnumValue": "5309",
            "enumValue": "530902",
            "enumName": "临翔区"
          },
          {
            "parentEnumValue": "5309",
            "enumValue": "530921",
            "enumName": "凤庆县"
          },
          {
            "parentEnumValue": "5309",
            "enumValue": "530922",
            "enumName": "云县"
          },
          {
            "parentEnumValue": "5309",
            "enumValue": "530923",
            "enumName": "永德县"
          },
          {
            "parentEnumValue": "5309",
            "enumValue": "530924",
            "enumName": "镇康县"
          },
          {
            "parentEnumValue": "5309",
            "enumValue": "530925",
            "enumName": "双江拉祜族佤族布朗族傣族自治县"
          },
          {
            "parentEnumValue": "5309",
            "enumValue": "530926",
            "enumName": "耿马傣族佤族自治县"
          },
          {
            "parentEnumValue": "5309",
            "enumValue": "530927",
            "enumName": "沧源佤族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5323",
        "enumName": "楚雄彝族自治州",
        "children": [
          {
            "parentEnumValue": "5323",
            "enumValue": "532300",
            "enumName": "楚雄彝族自治州本级"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532302",
            "enumName": "禄丰市"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532301",
            "enumName": "楚雄市"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532322",
            "enumName": "双柏县"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532323",
            "enumName": "牟定县"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532324",
            "enumName": "南华县"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532325",
            "enumName": "姚安县"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532326",
            "enumName": "大姚县"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532327",
            "enumName": "永仁县"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532328",
            "enumName": "元谋县"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532329",
            "enumName": "武定县"
          },
          {
            "parentEnumValue": "5323",
            "enumValue": "532331",
            "enumName": "禄丰县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5325",
        "enumName": "红河哈尼族彝族自治州",
        "children": [
          {
            "parentEnumValue": "5325",
            "enumValue": "532500",
            "enumName": "红河哈尼族彝族自治州本级"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532504",
            "enumName": "弥勒市"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532501",
            "enumName": "个旧市"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532502",
            "enumName": "开远市"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532503",
            "enumName": "蒙自市"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532522",
            "enumName": "蒙自市"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532523",
            "enumName": "屏边苗族自治县"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532524",
            "enumName": "建水县"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532525",
            "enumName": "石屏县"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532526",
            "enumName": "弥勒县"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532527",
            "enumName": "泸西县"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532528",
            "enumName": "元阳县"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532529",
            "enumName": "红河县"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532530",
            "enumName": "金平苗族瑶族傣族自治县"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532531",
            "enumName": "绿春县"
          },
          {
            "parentEnumValue": "5325",
            "enumValue": "532532",
            "enumName": "河口瑶族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5326",
        "enumName": "文山壮族苗族自治州",
        "children": [
          {
            "parentEnumValue": "5326",
            "enumValue": "532600",
            "enumName": "文山壮族苗族自治州本级"
          },
          {
            "parentEnumValue": "5326",
            "enumValue": "532601",
            "enumName": "文山市"
          },
          {
            "parentEnumValue": "5326",
            "enumValue": "532621",
            "enumName": "文山县"
          },
          {
            "parentEnumValue": "5326",
            "enumValue": "532622",
            "enumName": "砚山县"
          },
          {
            "parentEnumValue": "5326",
            "enumValue": "532623",
            "enumName": "西畴县"
          },
          {
            "parentEnumValue": "5326",
            "enumValue": "532624",
            "enumName": "麻栗坡县"
          },
          {
            "parentEnumValue": "5326",
            "enumValue": "532625",
            "enumName": "马关县"
          },
          {
            "parentEnumValue": "5326",
            "enumValue": "532626",
            "enumName": "丘北县"
          },
          {
            "parentEnumValue": "5326",
            "enumValue": "532627",
            "enumName": "广南县"
          },
          {
            "parentEnumValue": "5326",
            "enumValue": "532628",
            "enumName": "富宁县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5328",
        "enumName": "西双版纳傣族自治州",
        "children": [
          {
            "parentEnumValue": "5328",
            "enumValue": "532800",
            "enumName": "西双版纳傣族自治州本级"
          },
          {
            "parentEnumValue": "5328",
            "enumValue": "532801",
            "enumName": "景洪市"
          },
          {
            "parentEnumValue": "5328",
            "enumValue": "532822",
            "enumName": "勐海县"
          },
          {
            "parentEnumValue": "5328",
            "enumValue": "532823",
            "enumName": "勐腊县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5329",
        "enumName": "大理白族自治州",
        "children": [
          {
            "parentEnumValue": "5329",
            "enumValue": "532900",
            "enumName": "大理白族自治州本级"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532901",
            "enumName": "大理市"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532922",
            "enumName": "漾濞彝族自治县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532923",
            "enumName": "祥云县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532924",
            "enumName": "宾川县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532925",
            "enumName": "弥渡县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532926",
            "enumName": "南涧彝族自治县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532927",
            "enumName": "巍山彝族回族自治县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532928",
            "enumName": "永平县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532929",
            "enumName": "云龙县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532930",
            "enumName": "洱源县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532931",
            "enumName": "剑川县"
          },
          {
            "parentEnumValue": "5329",
            "enumValue": "532932",
            "enumName": "鹤庆县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5331",
        "enumName": "德宏傣族景颇族自治州",
        "children": [
          {
            "parentEnumValue": "5331",
            "enumValue": "533100",
            "enumName": "德宏傣族景颇族自治州本级"
          },
          {
            "parentEnumValue": "5331",
            "enumValue": "533102",
            "enumName": "瑞丽市"
          },
          {
            "parentEnumValue": "5331",
            "enumValue": "533103",
            "enumName": "芒市"
          },
          {
            "parentEnumValue": "5331",
            "enumValue": "533122",
            "enumName": "梁河县"
          },
          {
            "parentEnumValue": "5331",
            "enumValue": "533123",
            "enumName": "盈江县"
          },
          {
            "parentEnumValue": "5331",
            "enumValue": "533124",
            "enumName": "陇川县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5333",
        "enumName": "怒江傈僳族自治州",
        "children": [
          {
            "parentEnumValue": "5333",
            "enumValue": "533300",
            "enumName": "怒江傈僳族自治州本级"
          },
          {
            "parentEnumValue": "5333",
            "enumValue": "533301",
            "enumName": "泸水市"
          },
          {
            "parentEnumValue": "5333",
            "enumValue": "533321",
            "enumName": "泸水县"
          },
          {
            "parentEnumValue": "5333",
            "enumValue": "533323",
            "enumName": "福贡县"
          },
          {
            "parentEnumValue": "5333",
            "enumValue": "533324",
            "enumName": "贡山独龙族怒族自治县"
          },
          {
            "parentEnumValue": "5333",
            "enumValue": "533325",
            "enumName": "兰坪白族普米族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "53",
        "enumValue": "5334",
        "enumName": "迪庆藏族自治州",
        "children": [
          {
            "parentEnumValue": "5334",
            "enumValue": "533400",
            "enumName": "迪庆藏族自治州本级"
          },
          {
            "parentEnumValue": "5334",
            "enumValue": "533401",
            "enumName": "香格里拉市"
          },
          {
            "parentEnumValue": "5334",
            "enumValue": "533421",
            "enumName": "香格里拉县"
          },
          {
            "parentEnumValue": "5334",
            "enumValue": "533422",
            "enumName": "德钦县"
          },
          {
            "parentEnumValue": "5334",
            "enumValue": "533423",
            "enumName": "维西傈僳族自治县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "54",
    "enumName": "西藏自治区",
    "children": [
      {
        "parentEnumValue": "54",
        "enumValue": "5401",
        "enumName": "拉萨市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "5401",
            "enumValue": "540100",
            "enumName": "拉萨市本级"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540103",
            "enumName": "堆龙德庆区"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540104",
            "enumName": "达孜区"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540102",
            "enumName": "城关区"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540121",
            "enumName": "林周县"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540122",
            "enumName": "当雄县"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540123",
            "enumName": "尼木县"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540124",
            "enumName": "曲水县"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540125",
            "enumName": "堆龙德庆县"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540126",
            "enumName": "达孜县"
          },
          {
            "parentEnumValue": "5401",
            "enumValue": "540127",
            "enumName": "墨竹工卡县"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5405",
        "enumName": "山南市",
        "children": [
          {
            "parentEnumValue": "5405",
            "enumValue": "542224",
            "enumName": "桑日县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542223",
            "enumName": "贡嘎县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542225",
            "enumName": "琼结县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542226",
            "enumName": "曲松县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542227",
            "enumName": "措美县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542228",
            "enumName": "洛扎县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542229",
            "enumName": "加查县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540500",
            "enumName": "山南市本级"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542231",
            "enumName": "隆子县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542232",
            "enumName": "错那县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542233",
            "enumName": "浪卡子县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542222",
            "enumName": "扎囊县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "542221",
            "enumName": "乃东县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540502",
            "enumName": "乃东区"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540521",
            "enumName": "扎囊县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540522",
            "enumName": "贡嘎县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540523",
            "enumName": "桑日县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540524",
            "enumName": "琼结县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540525",
            "enumName": "曲松县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540526",
            "enumName": "措美县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540527",
            "enumName": "洛扎县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540528",
            "enumName": "加查县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540529",
            "enumName": "隆子县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540530",
            "enumName": "错那县"
          },
          {
            "parentEnumValue": "5405",
            "enumValue": "540531",
            "enumName": "浪卡子县"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5404",
        "enumName": "林芝市",
        "children": [
          {
            "parentEnumValue": "5404",
            "enumValue": "540402",
            "enumName": "巴宜区"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "542627",
            "enumName": "朗县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "542626",
            "enumName": "察隅县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "542625",
            "enumName": "波密县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "542624",
            "enumName": "墨脱县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "542623",
            "enumName": "米林县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "542622",
            "enumName": "工布江达县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "540400",
            "enumName": "林芝市本级"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "540421",
            "enumName": "工布江达县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "540422",
            "enumName": "米林县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "540423",
            "enumName": "墨脱县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "540424",
            "enumName": "波密县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "540425",
            "enumName": "察隅县"
          },
          {
            "parentEnumValue": "5404",
            "enumValue": "540426",
            "enumName": "朗县"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5403",
        "enumName": "昌都市",
        "children": [
          {
            "parentEnumValue": "5403",
            "enumValue": "542126",
            "enumName": "察雅县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "542125",
            "enumName": "丁青县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "542124",
            "enumName": "类乌齐县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "542123",
            "enumName": "贡觉县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "542122",
            "enumName": "江达县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540302",
            "enumName": "卡若区"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "542133",
            "enumName": "边坝县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "542132",
            "enumName": "洛隆县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "542129",
            "enumName": "芒康县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "542128",
            "enumName": "左贡县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "542127",
            "enumName": "八宿县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540300",
            "enumName": "昌都市本级"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540321",
            "enumName": "江达县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540322",
            "enumName": "贡觉县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540323",
            "enumName": "类乌齐县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540324",
            "enumName": "丁青县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540325",
            "enumName": "察雅县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540326",
            "enumName": "八宿县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540327",
            "enumName": "左贡县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540328",
            "enumName": "芒康县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540329",
            "enumName": "洛隆县"
          },
          {
            "parentEnumValue": "5403",
            "enumValue": "540330",
            "enumName": "边坝县"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5402",
        "enumName": "日喀则市",
        "children": [
          {
            "parentEnumValue": "5402",
            "enumValue": "542332",
            "enumName": "定结县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542333",
            "enumName": "仲巴县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542334",
            "enumName": "亚东县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542335",
            "enumName": "吉隆县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542338",
            "enumName": "岗巴县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542336",
            "enumName": "聂拉木县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542337",
            "enumName": "萨嘎县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542325",
            "enumName": "萨迦县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542326",
            "enumName": "拉孜县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542327",
            "enumName": "昂仁县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542328",
            "enumName": "谢通门县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542329",
            "enumName": "白朗县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542330",
            "enumName": "仁布县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542331",
            "enumName": "康马县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540202",
            "enumName": "桑珠孜区"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542322",
            "enumName": "南木林县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542323",
            "enumName": "江孜县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "542324",
            "enumName": "定日县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540227",
            "enumName": "谢通门县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540228",
            "enumName": "白朗县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540229",
            "enumName": "仁布县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540230",
            "enumName": "康马县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540231",
            "enumName": "定结县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540232",
            "enumName": "仲巴县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540233",
            "enumName": "亚东县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540234",
            "enumName": "吉隆县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540200",
            "enumName": "日喀则市本级"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540221",
            "enumName": "南木林县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540222",
            "enumName": "江孜县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540223",
            "enumName": "定日县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540224",
            "enumName": "萨迦县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540225",
            "enumName": "拉孜县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540226",
            "enumName": "昂仁县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540235",
            "enumName": "聂拉木县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540236",
            "enumName": "萨嘎县"
          },
          {
            "parentEnumValue": "5402",
            "enumValue": "540237",
            "enumName": "岗巴县"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5406",
        "enumName": "那曲市",
        "children": [
          {
            "parentEnumValue": "5406",
            "enumValue": "540600",
            "enumName": "那曲市本级"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540602",
            "enumName": "色尼区"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540621",
            "enumName": "嘉黎县"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540622",
            "enumName": "比如县"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540623",
            "enumName": "聂荣县"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540624",
            "enumName": "安多县"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540625",
            "enumName": "申扎县"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540626",
            "enumName": "索县"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540627",
            "enumName": "班戈县"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540628",
            "enumName": "巴青县"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540629",
            "enumName": "尼玛县"
          },
          {
            "parentEnumValue": "5406",
            "enumValue": "540630",
            "enumName": "双湖县"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5421",
        "enumName": "昌都地区",
        "children": [
          {
            "parentEnumValue": "5421",
            "enumValue": "542100",
            "enumName": "昌都地区本级"
          },
          {
            "parentEnumValue": "5421",
            "enumValue": "542121",
            "enumName": "昌都县"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5422",
        "enumName": "山南地区",
        "children": [
          {
            "parentEnumValue": "5422",
            "enumValue": "542200",
            "enumName": "山南地区本级"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5423",
        "enumName": "日喀则地区",
        "children": [
          {
            "parentEnumValue": "5423",
            "enumValue": "542300",
            "enumName": "日喀则地区本级"
          },
          {
            "parentEnumValue": "5423",
            "enumValue": "542301",
            "enumName": "日喀则市"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5424",
        "enumName": "那曲地区",
        "children": [
          {
            "parentEnumValue": "5424",
            "enumValue": "542400",
            "enumName": "那曲地区本级"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542431",
            "enumName": "双湖县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542421",
            "enumName": "那曲县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542422",
            "enumName": "嘉黎县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542423",
            "enumName": "比如县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542424",
            "enumName": "聂荣县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542425",
            "enumName": "安多县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542426",
            "enumName": "申扎县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542427",
            "enumName": "索县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542428",
            "enumName": "班戈县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542429",
            "enumName": "巴青县"
          },
          {
            "parentEnumValue": "5424",
            "enumValue": "542430",
            "enumName": "尼玛县"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5425",
        "enumName": "阿里地区",
        "children": [
          {
            "parentEnumValue": "5425",
            "enumValue": "542500",
            "enumName": "阿里地区本级"
          },
          {
            "parentEnumValue": "5425",
            "enumValue": "542521",
            "enumName": "普兰县"
          },
          {
            "parentEnumValue": "5425",
            "enumValue": "542522",
            "enumName": "札达县"
          },
          {
            "parentEnumValue": "5425",
            "enumValue": "542523",
            "enumName": "噶尔县"
          },
          {
            "parentEnumValue": "5425",
            "enumValue": "542524",
            "enumName": "日土县"
          },
          {
            "parentEnumValue": "5425",
            "enumValue": "542525",
            "enumName": "革吉县"
          },
          {
            "parentEnumValue": "5425",
            "enumValue": "542526",
            "enumName": "改则县"
          },
          {
            "parentEnumValue": "5425",
            "enumValue": "542527",
            "enumName": "措勤县"
          }
        ]
      },
      {
        "parentEnumValue": "54",
        "enumValue": "5426",
        "enumName": "林芝地区",
        "children": [
          {
            "parentEnumValue": "5426",
            "enumValue": "542600",
            "enumName": "林芝地区本级"
          },
          {
            "parentEnumValue": "5426",
            "enumValue": "542621",
            "enumName": "林芝县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "61",
    "enumName": "陕西省",
    "children": [
      {
        "parentEnumValue": "61",
        "enumValue": "6101",
        "enumName": "西安市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "6101",
            "enumValue": "610100",
            "enumName": "西安市本级"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610118",
            "enumName": "鄠邑区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610117",
            "enumName": "高陵区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610102",
            "enumName": "新城区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610103",
            "enumName": "碑林区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610104",
            "enumName": "莲湖区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610111",
            "enumName": "灞桥区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610112",
            "enumName": "未央区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610113",
            "enumName": "雁塔区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610114",
            "enumName": "阎良区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610115",
            "enumName": "临潼区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610116",
            "enumName": "长安区"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610122",
            "enumName": "蓝田县"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610124",
            "enumName": "周至县"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610125",
            "enumName": "户县"
          },
          {
            "parentEnumValue": "6101",
            "enumValue": "610126",
            "enumName": "高陵县"
          }
        ]
      },
      {
        "parentEnumValue": "61",
        "enumValue": "6102",
        "enumName": "铜川市",
        "children": [
          {
            "parentEnumValue": "6102",
            "enumValue": "610200",
            "enumName": "铜川市本级"
          },
          {
            "parentEnumValue": "6102",
            "enumValue": "610202",
            "enumName": "王益区"
          },
          {
            "parentEnumValue": "6102",
            "enumValue": "610203",
            "enumName": "印台区"
          },
          {
            "parentEnumValue": "6102",
            "enumValue": "610204",
            "enumName": "耀州区"
          },
          {
            "parentEnumValue": "6102",
            "enumValue": "610222",
            "enumName": "宜君县"
          }
        ]
      },
      {
        "parentEnumValue": "61",
        "enumValue": "6103",
        "enumName": "宝鸡市",
        "children": [
          {
            "parentEnumValue": "6103",
            "enumValue": "610305",
            "enumName": "凤翔区"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610300",
            "enumName": "宝鸡市本级"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610302",
            "enumName": "渭滨区"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610303",
            "enumName": "金台区"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610304",
            "enumName": "陈仓区"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610322",
            "enumName": "凤翔县"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610323",
            "enumName": "岐山县"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610324",
            "enumName": "扶风县"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610326",
            "enumName": "眉县"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610327",
            "enumName": "陇县"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610328",
            "enumName": "千阳县"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610329",
            "enumName": "麟游县"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610330",
            "enumName": "凤县"
          },
          {
            "parentEnumValue": "6103",
            "enumValue": "610331",
            "enumName": "太白县"
          }
        ]
      },
      {
        "parentEnumValue": "61",
        "enumValue": "6104",
        "enumName": "咸阳市",
        "children": [
          {
            "parentEnumValue": "6104",
            "enumValue": "610482",
            "enumName": "彬州市"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610400",
            "enumName": "咸阳市本级"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610402",
            "enumName": "秦都区"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610403",
            "enumName": "杨陵区"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610404",
            "enumName": "渭城区"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610422",
            "enumName": "三原县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610423",
            "enumName": "泾阳县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610424",
            "enumName": "乾县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610425",
            "enumName": "礼泉县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610426",
            "enumName": "永寿县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610427",
            "enumName": "彬县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610428",
            "enumName": "长武县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610429",
            "enumName": "旬邑县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610430",
            "enumName": "淳化县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610431",
            "enumName": "武功县"
          },
          {
            "parentEnumValue": "6104",
            "enumValue": "610481",
            "enumName": "兴平市"
          }
        ]
      },
      {
        "parentEnumValue": "61",
        "enumValue": "6105",
        "enumName": "渭南市",
        "children": [
          {
            "parentEnumValue": "6105",
            "enumValue": "610503",
            "enumName": "华州区"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610500",
            "enumName": "渭南市本级"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610502",
            "enumName": "临渭区"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610521",
            "enumName": "华县"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610522",
            "enumName": "潼关县"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610523",
            "enumName": "大荔县"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610524",
            "enumName": "合阳县"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610525",
            "enumName": "澄城县"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610526",
            "enumName": "蒲城县"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610527",
            "enumName": "白水县"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610528",
            "enumName": "富平县"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610581",
            "enumName": "韩城市"
          },
          {
            "parentEnumValue": "6105",
            "enumValue": "610582",
            "enumName": "华阴市"
          }
        ]
      },
      {
        "parentEnumValue": "61",
        "enumValue": "6106",
        "enumName": "延安市",
        "children": [
          {
            "parentEnumValue": "6106",
            "enumValue": "610681",
            "enumName": "子长市"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610600",
            "enumName": "延安市本级"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610602",
            "enumName": "宝塔区"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610603",
            "enumName": "安塞区"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610621",
            "enumName": "延长县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610622",
            "enumName": "延川县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610623",
            "enumName": "子长县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610624",
            "enumName": "安塞县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610625",
            "enumName": "志丹县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610626",
            "enumName": "吴起县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610627",
            "enumName": "甘泉县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610628",
            "enumName": "富县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610629",
            "enumName": "洛川县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610630",
            "enumName": "宜川县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610631",
            "enumName": "黄龙县"
          },
          {
            "parentEnumValue": "6106",
            "enumValue": "610632",
            "enumName": "黄陵县"
          }
        ]
      },
      {
        "parentEnumValue": "61",
        "enumValue": "6107",
        "enumName": "汉中市",
        "children": [
          {
            "parentEnumValue": "6107",
            "enumValue": "610703",
            "enumName": "南郑区"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610700",
            "enumName": "汉中市本级"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610702",
            "enumName": "汉台区"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610721",
            "enumName": "南郑县"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610722",
            "enumName": "城固县"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610723",
            "enumName": "洋县"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610724",
            "enumName": "西乡县"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610725",
            "enumName": "勉县"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610726",
            "enumName": "宁强县"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610727",
            "enumName": "略阳县"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610728",
            "enumName": "镇巴县"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610729",
            "enumName": "留坝县"
          },
          {
            "parentEnumValue": "6107",
            "enumValue": "610730",
            "enumName": "佛坪县"
          }
        ]
      },
      {
        "parentEnumValue": "61",
        "enumValue": "6108",
        "enumName": "榆林市",
        "children": [
          {
            "parentEnumValue": "6108",
            "enumValue": "610881",
            "enumName": "神木市"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610803",
            "enumName": "横山区"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610800",
            "enumName": "榆林市本级"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610802",
            "enumName": "榆阳区"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610821",
            "enumName": "神木县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610822",
            "enumName": "府谷县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610823",
            "enumName": "横山县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610824",
            "enumName": "靖边县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610825",
            "enumName": "定边县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610826",
            "enumName": "绥德县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610827",
            "enumName": "米脂县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610828",
            "enumName": "佳县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610829",
            "enumName": "吴堡县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610830",
            "enumName": "清涧县"
          },
          {
            "parentEnumValue": "6108",
            "enumValue": "610831",
            "enumName": "子洲县"
          }
        ]
      },
      {
        "parentEnumValue": "61",
        "enumValue": "6109",
        "enumName": "安康市",
        "children": [
          {
            "parentEnumValue": "6109",
            "enumValue": "610981",
            "enumName": "旬阳市"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610900",
            "enumName": "安康市本级"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610902",
            "enumName": "汉滨区"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610921",
            "enumName": "汉阴县"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610922",
            "enumName": "石泉县"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610923",
            "enumName": "宁陕县"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610924",
            "enumName": "紫阳县"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610925",
            "enumName": "岚皋县"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610926",
            "enumName": "平利县"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610927",
            "enumName": "镇坪县"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610928",
            "enumName": "旬阳县"
          },
          {
            "parentEnumValue": "6109",
            "enumValue": "610929",
            "enumName": "白河县"
          }
        ]
      },
      {
        "parentEnumValue": "61",
        "enumValue": "6110",
        "enumName": "商洛市",
        "children": [
          {
            "parentEnumValue": "6110",
            "enumValue": "611000",
            "enumName": "商洛市本级"
          },
          {
            "parentEnumValue": "6110",
            "enumValue": "611002",
            "enumName": "商州区"
          },
          {
            "parentEnumValue": "6110",
            "enumValue": "611021",
            "enumName": "洛南县"
          },
          {
            "parentEnumValue": "6110",
            "enumValue": "611022",
            "enumName": "丹凤县"
          },
          {
            "parentEnumValue": "6110",
            "enumValue": "611023",
            "enumName": "商南县"
          },
          {
            "parentEnumValue": "6110",
            "enumValue": "611024",
            "enumName": "山阳县"
          },
          {
            "parentEnumValue": "6110",
            "enumValue": "611025",
            "enumName": "镇安县"
          },
          {
            "parentEnumValue": "6110",
            "enumValue": "611026",
            "enumName": "柞水县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "62",
    "enumName": "甘肃省",
    "children": [
      {
        "parentEnumValue": "62",
        "enumValue": "6201",
        "enumName": "兰州市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "6201",
            "enumValue": "620100",
            "enumName": "兰州市本级"
          },
          {
            "parentEnumValue": "6201",
            "enumValue": "620102",
            "enumName": "城关区"
          },
          {
            "parentEnumValue": "6201",
            "enumValue": "620103",
            "enumName": "七里河区"
          },
          {
            "parentEnumValue": "6201",
            "enumValue": "620104",
            "enumName": "西固区"
          },
          {
            "parentEnumValue": "6201",
            "enumValue": "620105",
            "enumName": "安宁区"
          },
          {
            "parentEnumValue": "6201",
            "enumValue": "620111",
            "enumName": "红古区"
          },
          {
            "parentEnumValue": "6201",
            "enumValue": "620121",
            "enumName": "永登县"
          },
          {
            "parentEnumValue": "6201",
            "enumValue": "620122",
            "enumName": "皋兰县"
          },
          {
            "parentEnumValue": "6201",
            "enumValue": "620123",
            "enumName": "榆中县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6202",
        "enumName": "嘉峪关市",
        "children": [
          {
            "parentEnumValue": "6202",
            "enumValue": "620200",
            "enumName": "嘉峪关市本级"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6203",
        "enumName": "金昌市",
        "children": [
          {
            "parentEnumValue": "6203",
            "enumValue": "620300",
            "enumName": "金昌市本级"
          },
          {
            "parentEnumValue": "6203",
            "enumValue": "620302",
            "enumName": "金川区"
          },
          {
            "parentEnumValue": "6203",
            "enumValue": "620321",
            "enumName": "永昌县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6204",
        "enumName": "白银市",
        "children": [
          {
            "parentEnumValue": "6204",
            "enumValue": "620400",
            "enumName": "白银市本级"
          },
          {
            "parentEnumValue": "6204",
            "enumValue": "620402",
            "enumName": "白银区"
          },
          {
            "parentEnumValue": "6204",
            "enumValue": "620403",
            "enumName": "平川区"
          },
          {
            "parentEnumValue": "6204",
            "enumValue": "620421",
            "enumName": "靖远县"
          },
          {
            "parentEnumValue": "6204",
            "enumValue": "620422",
            "enumName": "会宁县"
          },
          {
            "parentEnumValue": "6204",
            "enumValue": "620423",
            "enumName": "景泰县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6205",
        "enumName": "天水市",
        "children": [
          {
            "parentEnumValue": "6205",
            "enumValue": "620500",
            "enumName": "天水市本级"
          },
          {
            "parentEnumValue": "6205",
            "enumValue": "620502",
            "enumName": "秦州区"
          },
          {
            "parentEnumValue": "6205",
            "enumValue": "620503",
            "enumName": "麦积区"
          },
          {
            "parentEnumValue": "6205",
            "enumValue": "620521",
            "enumName": "清水县"
          },
          {
            "parentEnumValue": "6205",
            "enumValue": "620522",
            "enumName": "秦安县"
          },
          {
            "parentEnumValue": "6205",
            "enumValue": "620523",
            "enumName": "甘谷县"
          },
          {
            "parentEnumValue": "6205",
            "enumValue": "620524",
            "enumName": "武山县"
          },
          {
            "parentEnumValue": "6205",
            "enumValue": "620525",
            "enumName": "张家川回族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6206",
        "enumName": "武威市",
        "children": [
          {
            "parentEnumValue": "6206",
            "enumValue": "620600",
            "enumName": "武威市本级"
          },
          {
            "parentEnumValue": "6206",
            "enumValue": "620602",
            "enumName": "凉州区"
          },
          {
            "parentEnumValue": "6206",
            "enumValue": "620621",
            "enumName": "民勤县"
          },
          {
            "parentEnumValue": "6206",
            "enumValue": "620622",
            "enumName": "古浪县"
          },
          {
            "parentEnumValue": "6206",
            "enumValue": "620623",
            "enumName": "天祝藏族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6207",
        "enumName": "张掖市",
        "children": [
          {
            "parentEnumValue": "6207",
            "enumValue": "620700",
            "enumName": "张掖市本级"
          },
          {
            "parentEnumValue": "6207",
            "enumValue": "620702",
            "enumName": "甘州区"
          },
          {
            "parentEnumValue": "6207",
            "enumValue": "620721",
            "enumName": "肃南裕固族自治县"
          },
          {
            "parentEnumValue": "6207",
            "enumValue": "620722",
            "enumName": "民乐县"
          },
          {
            "parentEnumValue": "6207",
            "enumValue": "620723",
            "enumName": "临泽县"
          },
          {
            "parentEnumValue": "6207",
            "enumValue": "620724",
            "enumName": "高台县"
          },
          {
            "parentEnumValue": "6207",
            "enumValue": "620725",
            "enumName": "山丹县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6208",
        "enumName": "平凉市",
        "children": [
          {
            "parentEnumValue": "6208",
            "enumValue": "620881",
            "enumName": "华亭市"
          },
          {
            "parentEnumValue": "6208",
            "enumValue": "620800",
            "enumName": "平凉市本级"
          },
          {
            "parentEnumValue": "6208",
            "enumValue": "620802",
            "enumName": "崆峒区"
          },
          {
            "parentEnumValue": "6208",
            "enumValue": "620821",
            "enumName": "泾川县"
          },
          {
            "parentEnumValue": "6208",
            "enumValue": "620822",
            "enumName": "灵台县"
          },
          {
            "parentEnumValue": "6208",
            "enumValue": "620823",
            "enumName": "崇信县"
          },
          {
            "parentEnumValue": "6208",
            "enumValue": "620824",
            "enumName": "华亭县"
          },
          {
            "parentEnumValue": "6208",
            "enumValue": "620825",
            "enumName": "庄浪县"
          },
          {
            "parentEnumValue": "6208",
            "enumValue": "620826",
            "enumName": "静宁县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6209",
        "enumName": "酒泉市",
        "children": [
          {
            "parentEnumValue": "6209",
            "enumValue": "620900",
            "enumName": "酒泉市本级"
          },
          {
            "parentEnumValue": "6209",
            "enumValue": "620902",
            "enumName": "肃州区"
          },
          {
            "parentEnumValue": "6209",
            "enumValue": "620921",
            "enumName": "金塔县"
          },
          {
            "parentEnumValue": "6209",
            "enumValue": "620922",
            "enumName": "瓜州县"
          },
          {
            "parentEnumValue": "6209",
            "enumValue": "620923",
            "enumName": "肃北蒙古族自治县"
          },
          {
            "parentEnumValue": "6209",
            "enumValue": "620924",
            "enumName": "阿克塞哈萨克族自治县"
          },
          {
            "parentEnumValue": "6209",
            "enumValue": "620981",
            "enumName": "玉门市"
          },
          {
            "parentEnumValue": "6209",
            "enumValue": "620982",
            "enumName": "敦煌市"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6210",
        "enumName": "庆阳市",
        "children": [
          {
            "parentEnumValue": "6210",
            "enumValue": "621000",
            "enumName": "庆阳市本级"
          },
          {
            "parentEnumValue": "6210",
            "enumValue": "621002",
            "enumName": "西峰区"
          },
          {
            "parentEnumValue": "6210",
            "enumValue": "621021",
            "enumName": "庆城县"
          },
          {
            "parentEnumValue": "6210",
            "enumValue": "621022",
            "enumName": "环县"
          },
          {
            "parentEnumValue": "6210",
            "enumValue": "621023",
            "enumName": "华池县"
          },
          {
            "parentEnumValue": "6210",
            "enumValue": "621024",
            "enumName": "合水县"
          },
          {
            "parentEnumValue": "6210",
            "enumValue": "621025",
            "enumName": "正宁县"
          },
          {
            "parentEnumValue": "6210",
            "enumValue": "621026",
            "enumName": "宁县"
          },
          {
            "parentEnumValue": "6210",
            "enumValue": "621027",
            "enumName": "镇原县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6211",
        "enumName": "定西市",
        "children": [
          {
            "parentEnumValue": "6211",
            "enumValue": "621100",
            "enumName": "定西市本级"
          },
          {
            "parentEnumValue": "6211",
            "enumValue": "621102",
            "enumName": "安定区"
          },
          {
            "parentEnumValue": "6211",
            "enumValue": "621121",
            "enumName": "通渭县"
          },
          {
            "parentEnumValue": "6211",
            "enumValue": "621122",
            "enumName": "陇西县"
          },
          {
            "parentEnumValue": "6211",
            "enumValue": "621123",
            "enumName": "渭源县"
          },
          {
            "parentEnumValue": "6211",
            "enumValue": "621124",
            "enumName": "临洮县"
          },
          {
            "parentEnumValue": "6211",
            "enumValue": "621125",
            "enumName": "漳县"
          },
          {
            "parentEnumValue": "6211",
            "enumValue": "621126",
            "enumName": "岷县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6212",
        "enumName": "陇南市",
        "children": [
          {
            "parentEnumValue": "6212",
            "enumValue": "621200",
            "enumName": "陇南市本级"
          },
          {
            "parentEnumValue": "6212",
            "enumValue": "621202",
            "enumName": "武都区"
          },
          {
            "parentEnumValue": "6212",
            "enumValue": "621221",
            "enumName": "成县"
          },
          {
            "parentEnumValue": "6212",
            "enumValue": "621222",
            "enumName": "文县"
          },
          {
            "parentEnumValue": "6212",
            "enumValue": "621223",
            "enumName": "宕昌县"
          },
          {
            "parentEnumValue": "6212",
            "enumValue": "621224",
            "enumName": "康县"
          },
          {
            "parentEnumValue": "6212",
            "enumValue": "621225",
            "enumName": "西和县"
          },
          {
            "parentEnumValue": "6212",
            "enumValue": "621226",
            "enumName": "礼县"
          },
          {
            "parentEnumValue": "6212",
            "enumValue": "621227",
            "enumName": "徽县"
          },
          {
            "parentEnumValue": "6212",
            "enumValue": "621228",
            "enumName": "两当县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6229",
        "enumName": "临夏回族自治州",
        "children": [
          {
            "parentEnumValue": "6229",
            "enumValue": "622900",
            "enumName": "临夏回族自治州本级"
          },
          {
            "parentEnumValue": "6229",
            "enumValue": "622901",
            "enumName": "临夏市"
          },
          {
            "parentEnumValue": "6229",
            "enumValue": "622921",
            "enumName": "临夏县"
          },
          {
            "parentEnumValue": "6229",
            "enumValue": "622922",
            "enumName": "康乐县"
          },
          {
            "parentEnumValue": "6229",
            "enumValue": "622923",
            "enumName": "永靖县"
          },
          {
            "parentEnumValue": "6229",
            "enumValue": "622924",
            "enumName": "广河县"
          },
          {
            "parentEnumValue": "6229",
            "enumValue": "622925",
            "enumName": "和政县"
          },
          {
            "parentEnumValue": "6229",
            "enumValue": "622926",
            "enumName": "东乡族自治县"
          },
          {
            "parentEnumValue": "6229",
            "enumValue": "622927",
            "enumName": "积石山保安族东乡族撒拉族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "62",
        "enumValue": "6230",
        "enumName": "甘南藏族自治州",
        "children": [
          {
            "parentEnumValue": "6230",
            "enumValue": "623000",
            "enumName": "甘南藏族自治州本级"
          },
          {
            "parentEnumValue": "6230",
            "enumValue": "623001",
            "enumName": "合作市"
          },
          {
            "parentEnumValue": "6230",
            "enumValue": "623021",
            "enumName": "临潭县"
          },
          {
            "parentEnumValue": "6230",
            "enumValue": "623022",
            "enumName": "卓尼县"
          },
          {
            "parentEnumValue": "6230",
            "enumValue": "623023",
            "enumName": "舟曲县"
          },
          {
            "parentEnumValue": "6230",
            "enumValue": "623024",
            "enumName": "迭部县"
          },
          {
            "parentEnumValue": "6230",
            "enumValue": "623025",
            "enumName": "玛曲县"
          },
          {
            "parentEnumValue": "6230",
            "enumValue": "623026",
            "enumName": "碌曲县"
          },
          {
            "parentEnumValue": "6230",
            "enumValue": "623027",
            "enumName": "夏河县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "63",
    "enumName": "青海省",
    "children": [
      {
        "parentEnumValue": "63",
        "enumValue": "6302",
        "enumName": "海东市",
        "children": [
          {
            "parentEnumValue": "6302",
            "enumValue": "632128",
            "enumName": "循化撒拉族自治县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "632127",
            "enumName": "化隆回族自治县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "632126",
            "enumName": "互助土族自治县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "632123",
            "enumName": "乐都县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "632122",
            "enumName": "民和回族土族自治县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "632121",
            "enumName": "平安县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "630200",
            "enumName": "海东市本级"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "630202",
            "enumName": "乐都区"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "630203",
            "enumName": "平安区"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "630221",
            "enumName": "平安县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "630222",
            "enumName": "民和回族土族自治县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "630223",
            "enumName": "互助土族自治县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "630224",
            "enumName": "化隆回族自治县"
          },
          {
            "parentEnumValue": "6302",
            "enumValue": "630225",
            "enumName": "循化撒拉族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "63",
        "enumValue": "6301",
        "enumName": "西宁市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "6301",
            "enumValue": "630100",
            "enumName": "西宁市本级"
          },
          {
            "parentEnumValue": "6301",
            "enumValue": "630106",
            "enumName": "湟中区"
          },
          {
            "parentEnumValue": "6301",
            "enumValue": "630102",
            "enumName": "城东区"
          },
          {
            "parentEnumValue": "6301",
            "enumValue": "630103",
            "enumName": "城中区"
          },
          {
            "parentEnumValue": "6301",
            "enumValue": "630104",
            "enumName": "城西区"
          },
          {
            "parentEnumValue": "6301",
            "enumValue": "630105",
            "enumName": "城北区"
          },
          {
            "parentEnumValue": "6301",
            "enumValue": "630121",
            "enumName": "大通回族土族自治县"
          },
          {
            "parentEnumValue": "6301",
            "enumValue": "630122",
            "enumName": "湟中县"
          },
          {
            "parentEnumValue": "6301",
            "enumValue": "630123",
            "enumName": "湟源县"
          }
        ]
      },
      {
        "parentEnumValue": "63",
        "enumValue": "6321",
        "enumName": "海东地区",
        "children": [
          {
            "parentEnumValue": "6321",
            "enumValue": "632100",
            "enumName": "海东市"
          }
        ]
      },
      {
        "parentEnumValue": "63",
        "enumValue": "6322",
        "enumName": "海北藏族自治州",
        "children": [
          {
            "parentEnumValue": "6322",
            "enumValue": "632200",
            "enumName": "海北藏族自治州本级"
          },
          {
            "parentEnumValue": "6322",
            "enumValue": "632221",
            "enumName": "门源回族自治县"
          },
          {
            "parentEnumValue": "6322",
            "enumValue": "632222",
            "enumName": "祁连县"
          },
          {
            "parentEnumValue": "6322",
            "enumValue": "632223",
            "enumName": "海晏县"
          },
          {
            "parentEnumValue": "6322",
            "enumValue": "632224",
            "enumName": "刚察县"
          }
        ]
      },
      {
        "parentEnumValue": "63",
        "enumValue": "6323",
        "enumName": "黄南藏族自治州",
        "children": [
          {
            "parentEnumValue": "6323",
            "enumValue": "632300",
            "enumName": "黄南藏族自治州本级"
          },
          {
            "parentEnumValue": "6323",
            "enumValue": "632301",
            "enumName": "同仁市"
          },
          {
            "parentEnumValue": "6323",
            "enumValue": "632321",
            "enumName": "同仁县"
          },
          {
            "parentEnumValue": "6323",
            "enumValue": "632322",
            "enumName": "尖扎县"
          },
          {
            "parentEnumValue": "6323",
            "enumValue": "632323",
            "enumName": "泽库县"
          },
          {
            "parentEnumValue": "6323",
            "enumValue": "632324",
            "enumName": "河南蒙古族自治县"
          }
        ]
      },
      {
        "parentEnumValue": "63",
        "enumValue": "6325",
        "enumName": "海南藏族自治州",
        "children": [
          {
            "parentEnumValue": "6325",
            "enumValue": "632500",
            "enumName": "海南藏族自治州本级"
          },
          {
            "parentEnumValue": "6325",
            "enumValue": "632521",
            "enumName": "共和县"
          },
          {
            "parentEnumValue": "6325",
            "enumValue": "632522",
            "enumName": "同德县"
          },
          {
            "parentEnumValue": "6325",
            "enumValue": "632523",
            "enumName": "贵德县"
          },
          {
            "parentEnumValue": "6325",
            "enumValue": "632524",
            "enumName": "兴海县"
          },
          {
            "parentEnumValue": "6325",
            "enumValue": "632525",
            "enumName": "贵南县"
          }
        ]
      },
      {
        "parentEnumValue": "63",
        "enumValue": "6326",
        "enumName": "果洛藏族自治州",
        "children": [
          {
            "parentEnumValue": "6326",
            "enumValue": "632600",
            "enumName": "果洛藏族自治州本级"
          },
          {
            "parentEnumValue": "6326",
            "enumValue": "632621",
            "enumName": "玛沁县"
          },
          {
            "parentEnumValue": "6326",
            "enumValue": "632622",
            "enumName": "班玛县"
          },
          {
            "parentEnumValue": "6326",
            "enumValue": "632623",
            "enumName": "甘德县"
          },
          {
            "parentEnumValue": "6326",
            "enumValue": "632624",
            "enumName": "达日县"
          },
          {
            "parentEnumValue": "6326",
            "enumValue": "632625",
            "enumName": "久治县"
          },
          {
            "parentEnumValue": "6326",
            "enumValue": "632626",
            "enumName": "玛多县"
          }
        ]
      },
      {
        "parentEnumValue": "63",
        "enumValue": "6327",
        "enumName": "玉树藏族自治州",
        "children": [
          {
            "parentEnumValue": "6327",
            "enumValue": "632700",
            "enumName": "玉树藏族自治州本级"
          },
          {
            "parentEnumValue": "6327",
            "enumValue": "632701",
            "enumName": "玉树市"
          },
          {
            "parentEnumValue": "6327",
            "enumValue": "632721",
            "enumName": "玉树县"
          },
          {
            "parentEnumValue": "6327",
            "enumValue": "632722",
            "enumName": "杂多县"
          },
          {
            "parentEnumValue": "6327",
            "enumValue": "632723",
            "enumName": "称多县"
          },
          {
            "parentEnumValue": "6327",
            "enumValue": "632724",
            "enumName": "治多县"
          },
          {
            "parentEnumValue": "6327",
            "enumValue": "632725",
            "enumName": "囊谦县"
          },
          {
            "parentEnumValue": "6327",
            "enumValue": "632726",
            "enumName": "曲麻莱县"
          }
        ]
      },
      {
        "parentEnumValue": "63",
        "enumValue": "6328",
        "enumName": "海西蒙古族藏族自治州",
        "children": [
          {
            "parentEnumValue": "6328",
            "enumValue": "632803",
            "enumName": "茫崖市"
          },
          {
            "parentEnumValue": "6328",
            "enumValue": "632800",
            "enumName": "海西蒙古族藏族自治州本级"
          },
          {
            "parentEnumValue": "6328",
            "enumValue": "632857",
            "enumName": "大柴旦行政委员会"
          },
          {
            "parentEnumValue": "6328",
            "enumValue": "632825",
            "enumName": "大柴旦行政委员会"
          },
          {
            "parentEnumValue": "6328",
            "enumValue": "632801",
            "enumName": "格尔木市"
          },
          {
            "parentEnumValue": "6328",
            "enumValue": "632802",
            "enumName": "德令哈市"
          },
          {
            "parentEnumValue": "6328",
            "enumValue": "632821",
            "enumName": "乌兰县"
          },
          {
            "parentEnumValue": "6328",
            "enumValue": "632822",
            "enumName": "都兰县"
          },
          {
            "parentEnumValue": "6328",
            "enumValue": "632823",
            "enumName": "天峻县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "64",
    "enumName": "宁夏回族自治区",
    "children": [
      {
        "parentEnumValue": "64",
        "enumValue": "6401",
        "enumName": "银川市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "6401",
            "enumValue": "640100",
            "enumName": "银川市本级"
          },
          {
            "parentEnumValue": "6401",
            "enumValue": "640104",
            "enumName": "兴庆区"
          },
          {
            "parentEnumValue": "6401",
            "enumValue": "640105",
            "enumName": "西夏区"
          },
          {
            "parentEnumValue": "6401",
            "enumValue": "640106",
            "enumName": "金凤区"
          },
          {
            "parentEnumValue": "6401",
            "enumValue": "640121",
            "enumName": "永宁县"
          },
          {
            "parentEnumValue": "6401",
            "enumValue": "640122",
            "enumName": "贺兰县"
          },
          {
            "parentEnumValue": "6401",
            "enumValue": "640181",
            "enumName": "灵武市"
          }
        ]
      },
      {
        "parentEnumValue": "64",
        "enumValue": "6402",
        "enumName": "石嘴山市",
        "children": [
          {
            "parentEnumValue": "6402",
            "enumValue": "640200",
            "enumName": "石嘴山市本级"
          },
          {
            "parentEnumValue": "6402",
            "enumValue": "640202",
            "enumName": "大武口区"
          },
          {
            "parentEnumValue": "6402",
            "enumValue": "640205",
            "enumName": "惠农区"
          },
          {
            "parentEnumValue": "6402",
            "enumValue": "640221",
            "enumName": "平罗县"
          }
        ]
      },
      {
        "parentEnumValue": "64",
        "enumValue": "6403",
        "enumName": "吴忠市",
        "children": [
          {
            "parentEnumValue": "6403",
            "enumValue": "640300",
            "enumName": "吴忠市本级"
          },
          {
            "parentEnumValue": "6403",
            "enumValue": "640302",
            "enumName": "利通区"
          },
          {
            "parentEnumValue": "6403",
            "enumValue": "640323",
            "enumName": "盐池县"
          },
          {
            "parentEnumValue": "6403",
            "enumValue": "640324",
            "enumName": "同心县"
          },
          {
            "parentEnumValue": "6403",
            "enumValue": "640381",
            "enumName": "青铜峡市"
          },
          {
            "parentEnumValue": "6403",
            "enumValue": "640303",
            "enumName": "红寺堡区"
          }
        ]
      },
      {
        "parentEnumValue": "64",
        "enumValue": "6404",
        "enumName": "固原市",
        "children": [
          {
            "parentEnumValue": "6404",
            "enumValue": "640400",
            "enumName": "固原市本级"
          },
          {
            "parentEnumValue": "6404",
            "enumValue": "640402",
            "enumName": "原州区"
          },
          {
            "parentEnumValue": "6404",
            "enumValue": "640422",
            "enumName": "西吉县"
          },
          {
            "parentEnumValue": "6404",
            "enumValue": "640423",
            "enumName": "隆德县"
          },
          {
            "parentEnumValue": "6404",
            "enumValue": "640424",
            "enumName": "泾源县"
          },
          {
            "parentEnumValue": "6404",
            "enumValue": "640425",
            "enumName": "彭阳县"
          }
        ]
      },
      {
        "parentEnumValue": "64",
        "enumValue": "6405",
        "enumName": "中卫市",
        "children": [
          {
            "parentEnumValue": "6405",
            "enumValue": "640500",
            "enumName": "中卫市本级"
          },
          {
            "parentEnumValue": "6405",
            "enumValue": "640502",
            "enumName": "沙坡头区"
          },
          {
            "parentEnumValue": "6405",
            "enumValue": "640521",
            "enumName": "中宁县"
          },
          {
            "parentEnumValue": "6405",
            "enumValue": "640522",
            "enumName": "海原县"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "65",
    "enumName": "新疆维吾尔自治区",
    "children": [
      {
        "parentEnumValue": "65",
        "enumValue": "6501",
        "enumName": "乌鲁木齐市",
        "enumDesc": "zdcs",
        "children": [
          {
            "parentEnumValue": "6501",
            "enumValue": "650100",
            "enumName": "乌鲁木齐市本级"
          },
          {
            "parentEnumValue": "6501",
            "enumValue": "650102",
            "enumName": "天山区"
          },
          {
            "parentEnumValue": "6501",
            "enumValue": "650103",
            "enumName": "沙依巴克区"
          },
          {
            "parentEnumValue": "6501",
            "enumValue": "650104",
            "enumName": "新市区"
          },
          {
            "parentEnumValue": "6501",
            "enumValue": "650105",
            "enumName": "水磨沟区"
          },
          {
            "parentEnumValue": "6501",
            "enumValue": "650106",
            "enumName": "头屯河区"
          },
          {
            "parentEnumValue": "6501",
            "enumValue": "650107",
            "enumName": "达坂城区"
          },
          {
            "parentEnumValue": "6501",
            "enumValue": "650109",
            "enumName": "米东区"
          },
          {
            "parentEnumValue": "6501",
            "enumValue": "650121",
            "enumName": "乌鲁木齐县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6505",
        "enumName": "哈密市",
        "children": [
          {
            "parentEnumValue": "6505",
            "enumValue": "650500",
            "enumName": "哈密市本级"
          },
          {
            "parentEnumValue": "6505",
            "enumValue": "650521",
            "enumName": "巴里坤哈萨克自治县"
          },
          {
            "parentEnumValue": "6505",
            "enumValue": "650522",
            "enumName": "伊吾县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6504",
        "enumName": "吐鲁番市",
        "children": [
          {
            "parentEnumValue": "6504",
            "enumValue": "650422",
            "enumName": "托克逊县"
          },
          {
            "parentEnumValue": "6504",
            "enumValue": "650421",
            "enumName": "鄯善县"
          },
          {
            "parentEnumValue": "6504",
            "enumValue": "650402",
            "enumName": "高昌区"
          },
          {
            "parentEnumValue": "6504",
            "enumValue": "650400",
            "enumName": "吐鲁番市本级"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6502",
        "enumName": "克拉玛依市",
        "children": [
          {
            "parentEnumValue": "6502",
            "enumValue": "650200",
            "enumName": "克拉玛依市本级"
          },
          {
            "parentEnumValue": "6502",
            "enumValue": "650202",
            "enumName": "独山子区"
          },
          {
            "parentEnumValue": "6502",
            "enumValue": "650203",
            "enumName": "克拉玛依区"
          },
          {
            "parentEnumValue": "6502",
            "enumValue": "650204",
            "enumName": "白碱滩区"
          },
          {
            "parentEnumValue": "6502",
            "enumValue": "650205",
            "enumName": "乌尔禾区"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6521",
        "enumName": "吐鲁番地区",
        "children": [
          {
            "parentEnumValue": "6521",
            "enumValue": "652100",
            "enumName": "吐鲁番地区本级"
          },
          {
            "parentEnumValue": "6521",
            "enumValue": "652101",
            "enumName": "吐鲁番市"
          },
          {
            "parentEnumValue": "6521",
            "enumValue": "652122",
            "enumName": "鄯善县"
          },
          {
            "parentEnumValue": "6521",
            "enumValue": "652123",
            "enumName": "托克逊县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6522",
        "enumName": "哈密地区",
        "children": [
          {
            "parentEnumValue": "6522",
            "enumValue": "652200",
            "enumName": "哈密地区本级"
          },
          {
            "parentEnumValue": "6522",
            "enumValue": "652201",
            "enumName": "哈密市"
          },
          {
            "parentEnumValue": "6522",
            "enumValue": "652222",
            "enumName": "巴里坤哈萨克自治县"
          },
          {
            "parentEnumValue": "6522",
            "enumValue": "652223",
            "enumName": "伊吾县"
          },
          {
            "parentEnumValue": "6522",
            "enumValue": "650502",
            "enumName": "伊州区"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6523",
        "enumName": "昌吉回族自治州",
        "children": [
          {
            "parentEnumValue": "6523",
            "enumValue": "652300",
            "enumName": "昌吉回族自治州本级"
          },
          {
            "parentEnumValue": "6523",
            "enumValue": "652301",
            "enumName": "昌吉市"
          },
          {
            "parentEnumValue": "6523",
            "enumValue": "652302",
            "enumName": "阜康市"
          },
          {
            "parentEnumValue": "6523",
            "enumValue": "652323",
            "enumName": "呼图壁县"
          },
          {
            "parentEnumValue": "6523",
            "enumValue": "652324",
            "enumName": "玛纳斯县"
          },
          {
            "parentEnumValue": "6523",
            "enumValue": "652325",
            "enumName": "奇台县"
          },
          {
            "parentEnumValue": "6523",
            "enumValue": "652327",
            "enumName": "吉木萨尔县"
          },
          {
            "parentEnumValue": "6523",
            "enumValue": "652328",
            "enumName": "木垒哈萨克自治县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6527",
        "enumName": "博尔塔拉蒙古自治州",
        "children": [
          {
            "parentEnumValue": "6527",
            "enumValue": "652702",
            "enumName": "阿拉山口市"
          },
          {
            "parentEnumValue": "6527",
            "enumValue": "652700",
            "enumName": "博尔塔拉蒙古自治州本级"
          },
          {
            "parentEnumValue": "6527",
            "enumValue": "652701",
            "enumName": "博乐市"
          },
          {
            "parentEnumValue": "6527",
            "enumValue": "652722",
            "enumName": "精河县"
          },
          {
            "parentEnumValue": "6527",
            "enumValue": "652723",
            "enumName": "温泉县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6528",
        "enumName": "巴音郭楞蒙古自治州",
        "children": [
          {
            "parentEnumValue": "6528",
            "enumValue": "652800",
            "enumName": "巴音郭楞蒙古自治州本级"
          },
          {
            "parentEnumValue": "6528",
            "enumValue": "652801",
            "enumName": "库尔勒市"
          },
          {
            "parentEnumValue": "6528",
            "enumValue": "652822",
            "enumName": "轮台县"
          },
          {
            "parentEnumValue": "6528",
            "enumValue": "652823",
            "enumName": "尉犁县"
          },
          {
            "parentEnumValue": "6528",
            "enumValue": "652824",
            "enumName": "若羌县"
          },
          {
            "parentEnumValue": "6528",
            "enumValue": "652825",
            "enumName": "且末县"
          },
          {
            "parentEnumValue": "6528",
            "enumValue": "652826",
            "enumName": "焉耆回族自治县"
          },
          {
            "parentEnumValue": "6528",
            "enumValue": "652827",
            "enumName": "和静县"
          },
          {
            "parentEnumValue": "6528",
            "enumValue": "652828",
            "enumName": "和硕县"
          },
          {
            "parentEnumValue": "6528",
            "enumValue": "652829",
            "enumName": "博湖县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6529",
        "enumName": "阿克苏地区",
        "children": [
          {
            "parentEnumValue": "6529",
            "enumValue": "652900",
            "enumName": "阿克苏地区本级"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652902",
            "enumName": "库车市"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652901",
            "enumName": "阿克苏市"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652922",
            "enumName": "温宿县"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652923",
            "enumName": "库车县"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652924",
            "enumName": "沙雅县"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652925",
            "enumName": "新和县"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652926",
            "enumName": "拜城县"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652927",
            "enumName": "乌什县"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652928",
            "enumName": "阿瓦提县"
          },
          {
            "parentEnumValue": "6529",
            "enumValue": "652929",
            "enumName": "柯坪县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6530",
        "enumName": "克孜勒苏柯尔克孜自治州",
        "children": [
          {
            "parentEnumValue": "6530",
            "enumValue": "653000",
            "enumName": "克孜勒苏柯尔克孜自治州本级"
          },
          {
            "parentEnumValue": "6530",
            "enumValue": "653001",
            "enumName": "阿图什市"
          },
          {
            "parentEnumValue": "6530",
            "enumValue": "653022",
            "enumName": "阿克陶县"
          },
          {
            "parentEnumValue": "6530",
            "enumValue": "653023",
            "enumName": "阿合奇县"
          },
          {
            "parentEnumValue": "6530",
            "enumValue": "653024",
            "enumName": "乌恰县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6531",
        "enumName": "喀什地区",
        "children": [
          {
            "parentEnumValue": "6531",
            "enumValue": "653100",
            "enumName": "喀什地区本级"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653101",
            "enumName": "喀什市"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653121",
            "enumName": "疏附县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653122",
            "enumName": "疏勒县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653123",
            "enumName": "英吉沙县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653124",
            "enumName": "泽普县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653125",
            "enumName": "莎车县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653126",
            "enumName": "叶城县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653127",
            "enumName": "麦盖提县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653128",
            "enumName": "岳普湖县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653129",
            "enumName": "伽师县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653130",
            "enumName": "巴楚县"
          },
          {
            "parentEnumValue": "6531",
            "enumValue": "653131",
            "enumName": "塔什库尔干塔吉克自治县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6532",
        "enumName": "和田地区",
        "children": [
          {
            "parentEnumValue": "6532",
            "enumValue": "653200",
            "enumName": "和田地区本级"
          },
          {
            "parentEnumValue": "6532",
            "enumValue": "653201",
            "enumName": "和田市"
          },
          {
            "parentEnumValue": "6532",
            "enumValue": "653221",
            "enumName": "和田县"
          },
          {
            "parentEnumValue": "6532",
            "enumValue": "653222",
            "enumName": "墨玉县"
          },
          {
            "parentEnumValue": "6532",
            "enumValue": "653223",
            "enumName": "皮山县"
          },
          {
            "parentEnumValue": "6532",
            "enumValue": "653224",
            "enumName": "洛浦县"
          },
          {
            "parentEnumValue": "6532",
            "enumValue": "653225",
            "enumName": "策勒县"
          },
          {
            "parentEnumValue": "6532",
            "enumValue": "653226",
            "enumName": "于田县"
          },
          {
            "parentEnumValue": "6532",
            "enumValue": "653227",
            "enumName": "民丰县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6540",
        "enumName": "伊犁哈萨克自治州",
        "children": [
          {
            "parentEnumValue": "6540",
            "enumValue": "654000",
            "enumName": "伊犁哈萨克自治州本级"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654004",
            "enumName": "霍尔果斯市"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654002",
            "enumName": "伊宁市"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654003",
            "enumName": "奎屯市"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654021",
            "enumName": "伊宁县"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654022",
            "enumName": "察布查尔锡伯自治县"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654023",
            "enumName": "霍城县"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654024",
            "enumName": "巩留县"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654025",
            "enumName": "新源县"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654026",
            "enumName": "昭苏县"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654027",
            "enumName": "特克斯县"
          },
          {
            "parentEnumValue": "6540",
            "enumValue": "654028",
            "enumName": "尼勒克县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6542",
        "enumName": "塔城地区",
        "children": [
          {
            "parentEnumValue": "6542",
            "enumValue": "654200",
            "enumName": "塔城地区本级"
          },
          {
            "parentEnumValue": "6542",
            "enumValue": "654203",
            "enumName": "沙湾市"
          },
          {
            "parentEnumValue": "6542",
            "enumValue": "654201",
            "enumName": "塔城市"
          },
          {
            "parentEnumValue": "6542",
            "enumValue": "654202",
            "enumName": "乌苏市"
          },
          {
            "parentEnumValue": "6542",
            "enumValue": "654221",
            "enumName": "额敏县"
          },
          {
            "parentEnumValue": "6542",
            "enumValue": "654223",
            "enumName": "沙湾县"
          },
          {
            "parentEnumValue": "6542",
            "enumValue": "654224",
            "enumName": "托里县"
          },
          {
            "parentEnumValue": "6542",
            "enumValue": "654225",
            "enumName": "裕民县"
          },
          {
            "parentEnumValue": "6542",
            "enumValue": "654226",
            "enumName": "和布克赛尔蒙古自治县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6543",
        "enumName": "阿勒泰地区",
        "children": [
          {
            "parentEnumValue": "6543",
            "enumValue": "654300",
            "enumName": "阿勒泰地区本级"
          },
          {
            "parentEnumValue": "6543",
            "enumValue": "654301",
            "enumName": "阿勒泰市"
          },
          {
            "parentEnumValue": "6543",
            "enumValue": "654321",
            "enumName": "布尔津县"
          },
          {
            "parentEnumValue": "6543",
            "enumValue": "654322",
            "enumName": "富蕴县"
          },
          {
            "parentEnumValue": "6543",
            "enumValue": "654323",
            "enumName": "福海县"
          },
          {
            "parentEnumValue": "6543",
            "enumValue": "654324",
            "enumName": "哈巴河县"
          },
          {
            "parentEnumValue": "6543",
            "enumValue": "654325",
            "enumName": "青河县"
          },
          {
            "parentEnumValue": "6543",
            "enumValue": "654326",
            "enumName": "吉木乃县"
          }
        ]
      },
      {
        "parentEnumValue": "65",
        "enumValue": "6590",
        "enumName": "新疆直辖县",
        "children": [
          {
            "parentEnumValue": "6590",
            "enumValue": "659000",
            "enumName": "自治区直辖县级行政单位本级"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659008",
            "enumName": "可克达拉市"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659010",
            "enumName": "胡杨河市"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659011",
            "enumName": "新星市"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659006",
            "enumName": "铁门关市"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659007",
            "enumName": "双河市"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659009",
            "enumName": "昆玉市"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659001",
            "enumName": "石河子市"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659002",
            "enumName": "阿拉尔市"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659003",
            "enumName": "图木舒克市"
          },
          {
            "parentEnumValue": "6590",
            "enumValue": "659004",
            "enumName": "五家渠市"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "66",
    "enumName": "新疆兵团",
    "children": [
      {
        "parentEnumValue": "66",
        "enumValue": "6601",
        "enumName": "农一师",
        "children": [
          {
            "parentEnumValue": "6601",
            "enumValue": "660100",
            "enumName": "农一师本级"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660124",
            "enumName": "阿拉尔市"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660101",
            "enumName": "1团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660102",
            "enumName": "2团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660103",
            "enumName": "3团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660104",
            "enumName": "4团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660105",
            "enumName": "5团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660106",
            "enumName": "6团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660107",
            "enumName": "7团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660108",
            "enumName": "8团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660109",
            "enumName": "9团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660110",
            "enumName": "10团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660111",
            "enumName": "11团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660112",
            "enumName": "12团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660113",
            "enumName": "13团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660114",
            "enumName": "14团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660115",
            "enumName": "15团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660116",
            "enumName": "16团"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660117",
            "enumName": "建安总公司"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660118",
            "enumName": "水电工程处"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660119",
            "enumName": "塔水处"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660120",
            "enumName": "沙水处"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660121",
            "enumName": "阿管局"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660122",
            "enumName": "沙管局"
          },
          {
            "parentEnumValue": "6601",
            "enumValue": "660123",
            "enumName": "西工区"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6602",
        "enumName": "农二师",
        "children": [
          {
            "parentEnumValue": "6602",
            "enumValue": "660225",
            "enumName": "铁门关市"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660200",
            "enumName": "农二师本级"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660201",
            "enumName": "21团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660202",
            "enumName": "22团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660203",
            "enumName": "23团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660204",
            "enumName": "24团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660205",
            "enumName": "25团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660206",
            "enumName": "26团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660207",
            "enumName": "27团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660208",
            "enumName": "28团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660209",
            "enumName": "29团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660210",
            "enumName": "30团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660211",
            "enumName": "31团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660212",
            "enumName": "32团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660213",
            "enumName": "33团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660214",
            "enumName": "34团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660215",
            "enumName": "35团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660216",
            "enumName": "36团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660217",
            "enumName": "223团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660218",
            "enumName": "师直"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660219",
            "enumName": "塔里木水管处"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660220",
            "enumName": "且末支队"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660221",
            "enumName": "18团水管处"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660222",
            "enumName": "38团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660223",
            "enumName": "工二团"
          },
          {
            "parentEnumValue": "6602",
            "enumValue": "660224",
            "enumName": "工四团"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6603",
        "enumName": "农三师",
        "children": [
          {
            "parentEnumValue": "6603",
            "enumValue": "660300",
            "enumName": "农三师本级"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660325",
            "enumName": "图木舒克市"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660326",
            "enumName": "54团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660301",
            "enumName": "41团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660302",
            "enumName": "42团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660303",
            "enumName": "43团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660304",
            "enumName": "44团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660305",
            "enumName": "45团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660306",
            "enumName": "46团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660308",
            "enumName": "48团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660309",
            "enumName": "49团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660310",
            "enumName": "50团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660311",
            "enumName": "51团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660312",
            "enumName": "52团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660313",
            "enumName": "53团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660314",
            "enumName": "工程团"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660315",
            "enumName": "小海子水管处"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660316",
            "enumName": "东风农场"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660317",
            "enumName": "师直"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660318",
            "enumName": "红旗农场"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660319",
            "enumName": "前进水管处"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660320",
            "enumName": "莎车农场"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660321",
            "enumName": "托运牧场"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660322",
            "enumName": "叶城牧场"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660323",
            "enumName": "伽师总场"
          },
          {
            "parentEnumValue": "6603",
            "enumValue": "660324",
            "enumName": "永安坝"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6604",
        "enumName": "农四师",
        "children": [
          {
            "parentEnumValue": "6604",
            "enumValue": "660400",
            "enumName": "农四师本级"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660424",
            "enumName": "可克达拉市"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660401",
            "enumName": "61团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660402",
            "enumName": "62团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660403",
            "enumName": "63团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660404",
            "enumName": "64团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660405",
            "enumName": "65团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660406",
            "enumName": "66团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660407",
            "enumName": "67团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660408",
            "enumName": "68团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660409",
            "enumName": "69团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660410",
            "enumName": "70团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660411",
            "enumName": "71团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660412",
            "enumName": "72团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660413",
            "enumName": "73团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660414",
            "enumName": "74团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660415",
            "enumName": "75团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660416",
            "enumName": "76团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660417",
            "enumName": "77团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660418",
            "enumName": "78团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660419",
            "enumName": "79团"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660420",
            "enumName": "工矿厂"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660421",
            "enumName": "拜什墩农场"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660422",
            "enumName": "良繁场"
          },
          {
            "parentEnumValue": "6604",
            "enumValue": "660423",
            "enumName": "师直"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6605",
        "enumName": "农五师",
        "children": [
          {
            "parentEnumValue": "6605",
            "enumValue": "660500",
            "enumName": "农五师本级"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660513",
            "enumName": "双河市"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660501",
            "enumName": "81团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660502",
            "enumName": "82团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660503",
            "enumName": "83团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660504",
            "enumName": "84团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660505",
            "enumName": "85团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660506",
            "enumName": "86团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660507",
            "enumName": "87团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660508",
            "enumName": "88团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660509",
            "enumName": "89团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660510",
            "enumName": "90团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660511",
            "enumName": "91团"
          },
          {
            "parentEnumValue": "6605",
            "enumValue": "660512",
            "enumName": "师直"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6606",
        "enumName": "农六师",
        "children": [
          {
            "parentEnumValue": "6606",
            "enumValue": "660600",
            "enumName": "农六师本级"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660621",
            "enumName": "五家渠市"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660601",
            "enumName": "101团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660602",
            "enumName": "102团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660603",
            "enumName": "103团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660604",
            "enumName": "105团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660605",
            "enumName": "106团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660606",
            "enumName": "107团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660607",
            "enumName": "108团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660608",
            "enumName": "109团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660609",
            "enumName": "110团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660610",
            "enumName": "111团"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660611",
            "enumName": "新湖农场"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660612",
            "enumName": "芳草湖农场"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660613",
            "enumName": "军户农场"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660614",
            "enumName": "共青团农场"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660615",
            "enumName": "六运湖农场"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660616",
            "enumName": "土墩子农场"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660617",
            "enumName": "红旗农场"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660618",
            "enumName": "奇台农场"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660619",
            "enumName": "北塔山农场"
          },
          {
            "parentEnumValue": "6606",
            "enumValue": "660620",
            "enumName": "师直"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6607",
        "enumName": "农七师",
        "children": [
          {
            "parentEnumValue": "6607",
            "enumValue": "660700",
            "enumName": "农七师本级"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660714",
            "enumName": "胡杨河市"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660701",
            "enumName": "123团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660702",
            "enumName": "124团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660703",
            "enumName": "125团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660704",
            "enumName": "126团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660705",
            "enumName": "127团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660706",
            "enumName": "128团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660707",
            "enumName": "129团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660708",
            "enumName": "130团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660709",
            "enumName": "131团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660710",
            "enumName": "137团"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660711",
            "enumName": "师直"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660712",
            "enumName": "奎屯天北新区"
          },
          {
            "parentEnumValue": "6607",
            "enumValue": "660713",
            "enumName": "红山煤矿"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6608",
        "enumName": "农八师",
        "children": [
          {
            "parentEnumValue": "6608",
            "enumValue": "660800",
            "enumName": "农八师本级"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660826",
            "enumName": "石河子市"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660801",
            "enumName": "121团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660802",
            "enumName": "122团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660803",
            "enumName": "132团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660804",
            "enumName": "133团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660805",
            "enumName": "134团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660806",
            "enumName": "135团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660807",
            "enumName": "136团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660808",
            "enumName": "141团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660809",
            "enumName": "142团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660810",
            "enumName": "143团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660811",
            "enumName": "144团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660812",
            "enumName": "石总场"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660813",
            "enumName": "147团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660814",
            "enumName": "148团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660815",
            "enumName": "149团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660816",
            "enumName": "150团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660817",
            "enumName": "151团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660818",
            "enumName": "152团"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660819",
            "enumName": "南山矿区"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660820",
            "enumName": "玛管处"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660821",
            "enumName": "巴管处"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660822",
            "enumName": "其他单位"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660823",
            "enumName": "红旗农场"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660824",
            "enumName": "东城"
          },
          {
            "parentEnumValue": "6608",
            "enumValue": "660825",
            "enumName": "南区"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6609",
        "enumName": "农九师",
        "children": [
          {
            "parentEnumValue": "6609",
            "enumValue": "660900",
            "enumName": "农九师本级"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660901",
            "enumName": "师直"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660902",
            "enumName": "161团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660903",
            "enumName": "162团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660904",
            "enumName": "163团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660905",
            "enumName": "164团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660906",
            "enumName": "165团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660907",
            "enumName": "166团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660908",
            "enumName": "167团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660909",
            "enumName": "168团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660910",
            "enumName": "169团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660911",
            "enumName": "170团"
          },
          {
            "parentEnumValue": "6609",
            "enumValue": "660912",
            "enumName": "团结农场"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6610",
        "enumName": "农十师",
        "children": [
          {
            "parentEnumValue": "6610",
            "enumValue": "661000",
            "enumName": "农十师本级"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661001",
            "enumName": "181团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661002",
            "enumName": "182团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661003",
            "enumName": "183团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661004",
            "enumName": "184团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661005",
            "enumName": "185团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661006",
            "enumName": "186团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661007",
            "enumName": "187团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661008",
            "enumName": "188团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661009",
            "enumName": "189团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661010",
            "enumName": "190团"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661011",
            "enumName": "青河农场"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661012",
            "enumName": "煤矿"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661013",
            "enumName": "师直北屯"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661014",
            "enumName": "一矿"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661015",
            "enumName": "二矿"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661016",
            "enumName": "三矿"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "661017",
            "enumName": "四矿"
          },
          {
            "parentEnumValue": "6610",
            "enumValue": "659005",
            "enumName": "北屯市"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6611",
        "enumName": "建工师",
        "children": [
          {
            "parentEnumValue": "6611",
            "enumValue": "661100",
            "enumName": "建工师本级"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6612",
        "enumName": "农十二师",
        "children": [
          {
            "parentEnumValue": "6612",
            "enumValue": "661200",
            "enumName": "农十二师本级"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661501",
            "enumName": "222团"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661201",
            "enumName": "104团"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661202",
            "enumName": "五一农场"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661203",
            "enumName": "三坪农场"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661204",
            "enumName": "头屯河农场"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661205",
            "enumName": "养禽场"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661206",
            "enumName": "西山牧场"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661207",
            "enumName": "221团"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661208",
            "enumName": "合作分区"
          },
          {
            "parentEnumValue": "6612",
            "enumValue": "661209",
            "enumName": "红岩水库"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6613",
        "enumName": "农十三师",
        "children": [
          {
            "parentEnumValue": "6613",
            "enumValue": "661300",
            "enumName": "农十三师本级"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661301",
            "enumName": "红星一场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661302",
            "enumName": "红星二场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661303",
            "enumName": "红星三场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661304",
            "enumName": "红星四场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661305",
            "enumName": "黄田农场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661306",
            "enumName": "火箭农场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661307",
            "enumName": "柳树泉农场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661308",
            "enumName": "红山农场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661309",
            "enumName": "淖毛湖农场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661310",
            "enumName": "红星一牧场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661311",
            "enumName": "红星二牧场"
          },
          {
            "parentEnumValue": "6613",
            "enumValue": "661312",
            "enumName": "师直"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6614",
        "enumName": "农十四师",
        "children": [
          {
            "parentEnumValue": "6614",
            "enumValue": "661400",
            "enumName": "农十四师本级"
          },
          {
            "parentEnumValue": "6614",
            "enumValue": "661406",
            "enumName": "225团"
          },
          {
            "parentEnumValue": "6614",
            "enumValue": "661405",
            "enumName": "昆玉市"
          },
          {
            "parentEnumValue": "6614",
            "enumValue": "661401",
            "enumName": "皮山农场"
          },
          {
            "parentEnumValue": "6614",
            "enumValue": "661402",
            "enumName": "47团"
          },
          {
            "parentEnumValue": "6614",
            "enumValue": "661403",
            "enumName": "一牧场"
          },
          {
            "parentEnumValue": "6614",
            "enumValue": "661404",
            "enumName": "224团"
          }
        ]
      },
      {
        "parentEnumValue": "66",
        "enumValue": "6615",
        "enumName": "兵直",
        "children": [
          {
            "parentEnumValue": "6615",
            "enumValue": "661500",
            "enumName": "兵直本级"
          }
        ]
      }
    ]
  },
  {
    "enumValue": "71",
    "enumName": "台湾省",
  },
  {
    "enumValue": "81",
    "enumName": "香港特别行政区",
  },
  {
    "enumValue": "82",
    "enumName": "澳门特别行政区",
  }
];
