export type Base = {
  id: string;
  label: string;
  [key: string]: any;
};

export type landUsageFifthLevel = Base;

export type landUsageFourthLevel = Base & {
  children?: landUsageFifthLevel[]
}

export type landUsageThirdLevel = Base & {
  children?: landUsageFourthLevel[];
};

export type landUsageSecondLevel = Base & {
  children?: landUsageThirdLevel[];
};

export type landUsageFirstLevel = Base & {
  children: landUsageSecondLevel[];
};

// export const uses: landUsageFirstLevel[] = [
//   {
//     id: "new",
//     label: "新版用途",
//     children: [
//       {
//         id: "05",
//         label: "商服用地",
//         children: [
//           { id: "0501", label: "零售商业用地" },
//           { id: "0502", label: "批发市场用地" },
//           { id: "0503", label: "餐饮用地" },
//           { id: "0504", label: "旅馆用地" },
//           { id: "0505", label: "商务金融用地" },
//           { id: "0506", label: "娱乐用地" },
//           { id: "0507", label: "其他商服用地" },
//         ],
//       },
//       {
//         id: "06",
//         label: "工矿仓储用地",
//         children: [
//           { id: "0601", label: "工业用地" },
//           { id: "0602", label: "采矿用地" },
//           { id: "0603", label: "盐田" },
//           { id: "0604", label: "仓储用地" },
//         ],
//       },
//       {
//         id: "07",
//         label: "住宅用地",
//         children: [
//           { id: "0702", label: "农村宅基地" },
//           { id: "0703", label: "城镇住宅-普通商品住房用地" },
//           { id: "0704", label: "城镇住宅-经济适用住房用地" },
//           { id: "0705", label: "城镇住宅-公共租赁住房用地" },
//           { id: "0708", label: "城镇住宅-租赁型商品住房用地" },
//           { id: "0709", label: "城镇住宅-共有产权住房用地" },
//         ],
//       },
//       {
//         id: "08",
//         label: "公共管理与公共服务用地",
//         children: [
//           { id: "0801", label: "机关团体用地" },
//           { id: "0802", label: "新闻出版用地" },
//           { id: "0803", label: "教育用地" },
//           { id: "0804", label: "科研用地" },
//           { id: "0805", label: "医疗卫生用地" },
//           { id: "0806", label: "社会福利用地" },
//           { id: "0807", label: "文化设施用地" },
//           { id: "0808", label: "体育用地" },
//           { id: "0809", label: "公用设施用地" },
//           { id: "0810", label: "公园与绿地" },
//         ],
//       },
//       {
//         id: "09",
//         label: "特殊用地",
//         children: [
//           { id: "0901", label: "军事设施用地" },
//           { id: "0902", label: "使领馆用地" },
//           { id: "0903", label: "监教场所用地" },
//           { id: "0904", label: "宗教用地" },
//           { id: "0905", label: "殡葬用地" },
//           { id: "0906", label: "风景名胜设施用地" },
//         ],
//       },
//       {
//         id: "10",
//         label: "交通运输用地",
//         children: [
//           { id: "1001", label: "铁路用地" },
//           { id: "1002", label: "轨道交通用地" },
//           { id: "1003", label: "公路用地" },
//           { id: "1004", label: "城镇村道路用地" },
//           { id: "1005", label: "交通服务场站用地" },
//           { id: "1006", label: "农村道路" },
//           { id: "1007", label: "机场用地" },
//           { id: "1008", label: "港口码头用地" },
//           { id: "1009", label: "管道运输用地" },
//         ],
//       },
//       {
//         id: "11",
//         label: "水域及水利设施用地",
//         children: [
//           { id: "1101", label: "河流水面" },
//           { id: "1102", label: "湖泊水面" },
//           { id: "1103", label: "水库水面" },
//           { id: "1104", label: "坑塘水面" },
//           { id: "1105", label: "沿海滩涂" },
//           { id: "1106", label: "内陆滩涂" },
//           { id: "1107", label: "沟渠" },
//           { id: "1108", label: "沼泽地" },
//           { id: "1109", label: "水工建筑用地" },
//           { id: "1110", label: "冰川及永久积雪" },
//         ],
//       },
//       {
//         id: "12",
//         label: "其他土地",
//         children: [
//           { id: "1201", label: "空闲地" },
//           { id: "1202", label: "设施农用地" },
//           { id: "1203", label: "田坎" },
//           { id: "1204", label: "盐碱地" },
//           { id: "1205", label: "沙地" },
//           { id: "1206", label: "裸土地" },
//           { id: "1207", label: "裸岩石砾地" },
//         ],
//       },
//       { id: "98", label: "商住综合" },
//       {
//         id: "shangy",
//         label: "商业",
//         children: [
//           { id: "shangy000", label: "商业" },
//           { id: "shangy000", label: "商业" },
//         ],
//       },
//     ],
//   },
//   {
//     id: "old",
//     label: "旧版用途",
//     children: [
//       {
//         id: "Y05",
//         label: "商服用地",
//         children: [
//           { id: "051", label: "批发零售用地" },
//           { id: "052", label: "住宿餐饮用地" },
//           { id: "053", label: "商务金融用地" },
//           { id: "054", label: "其他商服用地" },
//         ],
//       },
//       {
//         id: "Y06",
//         label: "工矿仓储用地",
//         children: [
//           { id: "061", label: "工业用地" },
//           { id: "062", label: "采矿用地" },
//           { id: "063", label: "仓储用地" },
//         ],
//       },
//       {
//         id: "Y07",
//         label: "住宅用地",
//         children: [
//           { id: "071", label: "高档住宅用地" },
//           { id: "072", label: "中低价位、中小套型普通商品住房用地" },
//           { id: "073", label: "其他普通商品住房用地" },
//           { id: "074", label: "经济适用住房用地" },
//           { id: "075", label: "廉租住房用地" },
//           { id: "076", label: "其他住房用地" },
//           { id: "077", label: "公共租赁住房用地" },
//         ],
//       },
//       {
//         id: "Y08",
//         label: "公共管理与公共服务用地",
//         children: [
//           { id: "081", label: "机关团体用地" },
//           { id: "082", label: "新闻出版用地" },
//           { id: "083", label: "科教用地" },
//           { id: "084", label: "医卫慈善用地" },
//           { id: "085", label: "文体娱乐用地" },
//           { id: "086", label: "公共设施用地" },
//           { id: "087", label: "公园与绿地" },
//           { id: "088", label: "风景名胜设施用地" },
//         ],
//       },
//       {
//         id: "Y09",
//         label: "特殊用地",
//         children: [
//           { id: "091", label: "军事设施用地" },
//           { id: "092", label: "使领馆用地" },
//           { id: "093", label: "监教场所用地" },
//           { id: "094", label: "宗教用地" },
//           { id: "095", label: "殡葬用地" },
//         ],
//       },
//       {
//         id: "Y10",
//         label: "交通运输用地",
//         children: [
//           { id: "101", label: "铁路用地" },
//           { id: "102", label: "公路用地" },
//           { id: "103", label: "街巷用地" },
//           { id: "104", label: "农村道路" },
//           { id: "105", label: "机场用地" },
//           { id: "106", label: "港口码头用地" },
//           { id: "107", label: "管道运输用地" },
//         ],
//       },
//       {
//         id: "Y11",
//         label: "水域及水利设施用地",
//         children: [
//           { id: "113", label: "水库水面" },
//           { id: "114", label: "坑塘水面" },
//           { id: "115", label: "沿海滩涂" },
//           { id: "116", label: "内陆滩涂" },
//           { id: "117", label: "沟渠" },
//           { id: "118", label: "水工建筑用地" },
//           { id: "119", label: "冰川及永久积雪" },
//         ],
//       },
//       {
//         id: "Y12",
//         label: "其他土地",
//         children: [
//           { id: "121", label: "空闲地" },
//           { id: "122", label: "设施农用地" },
//           { id: "123", label: "田坎" },
//           { id: "124", label: "盐碱地" },
//           { id: "125", label: "沼泽地" },
//           { id: "126", label: "沙地" },
//           { id: "127", label: "裸地" },
//         ],
//       },
//     ],
//   },
// ];


export const uses: landUsageFirstLevel[] =[
  {
    "id": "V3",
    "label": "2023版本分类",
    "children": [
      {
        "id": "V3-09",
        "label": "商业服务业用地",
        "children": [
          {
            "id": "V3-0904",
            "label": "其他商业服务业用地"
          },
          {
            "id": "V3-0903",
            "label": "娱乐用地",
          },
          {
            "id": "V3-0901",
            "label": "商业用地",
            "children": [
              {
                "id": "V3-090101",
                "label": "零售商业用地"
              },
              {
                "id": "V3-090102",
                "label": "批发市场用地"
              },
              {
                "id": "V3-090103",
                "label": "餐饮用地"
              },
              {
                "id": "V3-090104",
                "label": "旅馆用地"
              },
              {
                "id": "V3-090105",
                "label": "公用设施营业网点用地"
              }
            ]
          },
          {
            "id": "V3-0902",
            "label": "商务金融用地"
          }
        ]
      },
      {
        "id": "V3-10",
        "label": "工矿用地",
        "children": [
          {
            "id": "V3-1001",
            "label": "工业用地",
            "children": [
              {
                "id": "V3-100101",
                "label": "一类工业用地"
              },
              {
                "id": "V3-100103",
                "label": "三类工业用地"
              },
              {
                "id": "V3-100102",
                "label": "二类工业用地"
              }
            ]
          },
          {
            "id": "V3-1002",
            "label": "采矿用地"
          },
          {
            "id": "V3-1003",
            "label": "盐田"
          }
        ]
      },
      {
        "id": "V3-07",
        "label": "居住用地",
        "children": [
          {
            "id": "V3-0701",
            "label": "城镇住宅用地",
            "children": [
              {
                "id": "V3-070101",
                "label": "一类城镇住宅",
                "children": [
                  {
                    "id": "V3-07010103",
                    "label": "普通商品住房用地(一类)"
                  },
                  {
                    "id": "V3-07010110",
                    "label": "用于安置的商品住房用地(一类)"
                  },
                  {
                    "id": "V3-07010104",
                    "label": "经济适用住房用地(一类)"
                  },
                  {
                    "id": "V3-07010106",
                    "label": "公共租赁住房用地(一类)"
                  },
                  {
                    "id": "V3-07010107",
                    "label": "保障性租赁住房用地(一类)"
                  },
                  {
                    "id": "V3-07010108",
                    "label": "租赁型商品住房用地(一类)"
                  },
                  {
                    "id": "V3-07010109",
                    "label": "共有产权住房用地(一类)"
                  },
                  {
                    "id": "V3-07010111",
                    "label": "配售型保障房(一类)"
                  }
                ]
              },
              {
                "id": "V3-070102",
                "label": "二类城镇住宅",
                "children": [
                  {
                    "id": "V3-07010203",
                    "label": "普通商品住房用地(二类)"
                  },
                  {
                    "id": "V3-07010210",
                    "label": "用于安置的商品住房用地(二类)"
                  },
                  {
                    "id": "V3-07010204",
                    "label": "经济适用住房用地(二类)"
                  },
                  {
                    "id": "V3-07010206",
                    "label": "公共租赁住房用地(二类)"
                  },
                  {
                    "id": "V3-07010207",
                    "label": "保障性租赁住房用地(二类)"
                  },
                  {
                    "id": "V3-07010208",
                    "label": "租赁型商品住房用地(二类)"
                  },
                  {
                    "id": "V3-07010209",
                    "label": "共有产权住房用地(二类)"
                  },
                  {
                    "id": "V3-07010211",
                    "label": "配售型保障房(二类)"
                  }
                ]
              },
              {
                "id": "V3-070103",
                "label": "三类城镇住宅",
                "children": [
                  {
                    "id": "V3-07010303",
                    "label": "普通商品住房用地(三类)"
                  },
                  {
                    "id": "V3-07010310",
                    "label": "用于安置的商品住房用地(三类)"
                  },
                  {
                    "id": "V3-07010304",
                    "label": "经济适用住房用地(三类)"
                  },
                  {
                    "id": "V3-07010306",
                    "label": "公共租赁住房用地(三类)"
                  },
                  {
                    "id": "V3-07010307",
                    "label": "保障性租赁住房用地(三类)"
                  },
                  {
                    "id": "V3-07010308",
                    "label": "租赁型商品住房用地(三类)"
                  },
                  {
                    "id": "V3-07010309",
                    "label": "共有产权住房用地(三类)"
                  },
                  {
                    "id": "V3-07010311",
                    "label": "配售型保障房(三类)"
                  }
                ]
              }
            ]
          },
          {
            "id": "V3-0702",
            "label": "城镇社区服务设施用地"
          },
          {
            "id": "V3-0703",
            "label": "农村宅基地",
            "children": [
              {
                "id": "V3-070301",
                "label": "一类农村宅基地"
              },
              {
                "id": "V3-070302",
                "label": "二类农村宅基地"
              }
            ]
          },
          {
            "id": "V3-0704",
            "label": "农村社区服务设施用地"
          }
        ]
      },
      {
        "id": "V3-08",
        "label": "公共管理与公共服务用地",
        "children": [
          {
            "id": "V3-0801",
            "label": "机关团体用地"
          },
          {
            "id": "V3-0804",
            "label": "教育用地",
            "children": [
              {
                "id": "V3-080401",
                "label": "高等教育用地"
              },
              {
                "id": "V3-080402",
                "label": "中等职业教育用地"
              },
              {
                "id": "V3-080403",
                "label": "中小学用地"
              },
              {
                "id": "V3-080404",
                "label": "幼儿园用地"
              },
              {
                "id": "V3-080405",
                "label": "其他教育用地"
              }
            ]
          },
          {
            "id": "V3-0802",
            "label": "科研用地"
          },
          {
            "id": "V3-0806",
            "label": "医疗卫生用地",
            "children": [
              {
                "id": "V3-080601",
                "label": "医院用地"
              },
              {
                "id": "V3-080602",
                "label": "基层医疗卫生设施用地"
              },
              {
                "id": "V3-080603",
                "label": "公共卫生用地"
              }
            ]
          },
          {
            "id": "V3-0807",
            "label": "社会福利用地",
            "children": [
              {
                "id": "V3-080701",
                "label": "老年人社会福利用地"
              },
              {
                "id": "V3-080702",
                "label": "儿童社会福利用地"
              },
              {
                "id": "V3-080703",
                "label": "残疾人社会福利用地"
              },
              {
                "id": "V3-080704",
                "label": "其他社会福利用地"
              }
            ]
          },
          {
            "id": "V3-0803",
            "label": "文化用地",
            "children": [
              {
                "id": "V3-080301",
                "label": "图书与展览用地"
              },
              {
                "id": "V3-080302",
                "label": "文化活动用地"
              }
            ]
          },
          {
            "id": "V3-0805",
            "label": "体育用地",
            "children": [
              {
                "id": "V3-080501",
                "label": "体育场馆用地"
              },
              {
                "id": "V3-080502",
                "label": "体育训练用地"
              }
            ]
          }
        ]
      },
      {
        "id": "V3-11",
        "label": "仓储用地",
        "children": [
          {
            "id": "V3-1101",
            "label": "物流仓储用地",
            "children": [
              {
                "id": "V3-110101",
                "label": "一类物流仓储用地"
              },
              {
                "id": "V3-110102",
                "label": "二类物流仓储用地"
              },
              {
                "id": "V3-110103",
                "label": "三类物流仓储用地"
              }
            ]
          },
          {
            "id": "V3-1102",
            "label": "储备库用地"
          }
        ]
      },
      {
        "id": "V3-12",
        "label": "交通运输用地",
        "children": [
          {
            "id": "V3-1201",
            "label": "铁路用地"
          },
          {
            "id": "V3-1202",
            "label": "公路用地"
          },
          {
            "id": "V3-1203",
            "label": "机场用地"
          },
          {
            "id": "V3-1204",
            "label": "港口码头用地"
          },
          {
            "id": "V3-1205",
            "label": "管道运输用地"
          },
          {
            "id": "V3-1206",
            "label": "城市轨道交通用地"
          },
          {
            "id": "V3-1207",
            "label": "城镇村道路用地"
          },
          {
            "id": "V3-1208",
            "label": "交通场站用地",
            "children": [
              {
                "id": "V3-120801",
                "label": "对外交通场站用地"
              },
              {
                "id": "V3-120802",
                "label": "公共交通场站用地"
              },
              {
                "id": "V3-120803",
                "label": "社会停车场用地"
              }
            ]
          },
          {
            "id": "V3-1209",
            "label": "其他交通设施用地"
          }
        ]
      },
      {
        "id": "V3-17",
        "label": "陆地水域",
        "children": [
          {
            "id": "V3-1701",
            "label": "河流水面"
          },
          {
            "id": "V3-1702",
            "label": "湖泊水面"
          },
          {
            "id": "V3-1703",
            "label": "水库水面"
          },
          {
            "id": "V3-1704",
            "label": "坑塘水面"
          },
          {
            "id": "V3-1705",
            "label": "沟渠"
          },
          {
            "id": "V3-1706",
            "label": "冰川及常年积雪"
          }
        ]
      },
      {
        "id": "V3-15",
        "label": "特殊用地",
        "children": [
          {
            "id": "V3-1501",
            "label": "军事设施用地"
          },
          {
            "id": "V3-1502",
            "label": "使领馆用地"
          },
          {
            "id": "V3-1503",
            "label": "宗教用地"
          },
          {
            "id": "V3-1504",
            "label": "文物古迹用地"
          },
          {
            "id": "V3-1505",
            "label": "监教场所用地"
          },
          {
            "id": "V3-1506",
            "label": "殡葬用地"
          },
          {
            "id": "V3-1507",
            "label": "其他特殊用地"
          }
        ]
      },
      {
        "id": "V3-14",
        "label": "绿地与开敞空间用地",
        "children": [
          {
            "id": "V3-1401",
            "label": "公园绿地"
          },
          {
            "id": "V3-1402",
            "label": "防护绿地"
          },
          {
            "id": "V3-1403",
            "label": "广场用地"
          }
        ]
      },
      {
        "id": "V3-06",
        "label": "农业设施建设用地",
        "children": [
          {
            "id": "V3-0601",
            "label": "农村道路",
            "children": [
              {
                "id": "V3-060101",
                "label": "村道用地"
              },
              {
                "id": "V3-060102",
                "label": "田间道"
              }
            ]
          },
          {
            "id": "V3-0602",
            "label": "设施农用地",
            "children": [
              {
                "id": "V3-060201",
                "label": "种植设施建设用地"
              },
              {
                "id": "V3-060202",
                "label": "畜禽养殖设施建设用地"
              },
              {
                "id": "V3-060203",
                "label": "水产养殖设施建设用地"
              }
            ]
          }
        ]
      },
      {
        "id": "V3-13",
        "label": "公用设施用地",
        "children": [
          {
            "id": "V3-1301",
            "label": "供水用地"
          },
          {
            "id": "V3-1302",
            "label": "排水用地"
          },
          {
            "id": "V3-1303",
            "label": "供电用地"
          },
          {
            "id": "V3-1304",
            "label": "供燃气用地"
          },
          {
            "id": "V3-1305",
            "label": "供热用地"
          },
          {
            "id": "V3-1306",
            "label": "通信用地"
          },
          {
            "id": "V3-1307",
            "label": "邮政用地"
          },
          {
            "id": "V3-1308",
            "label": "广播电视设施用地"
          },
          {
            "id": "V3-1309",
            "label": "环卫用地"
          },
          {
            "id": "V3-1310",
            "label": "消防用地"
          },
          {
            "id": "V3-1311",
            "label": "水工设施用地"
          },
          {
            "id": "V3-1312",
            "label": "其他公用设施用地"
          }
        ]
      },
      {
        "id": "V3-05",
        "label": "湿地",
        "children": [
          {
            "id": "V3-0505",
            "label": "沿海滩涂"
          },
          {
            "id": "V3-0506",
            "label": "内陆滩涂"
          }
        ]
      },
      {
        "id": "V3-23",
        "label": "其他土地",
        "children": [
          {
            "id": "V3-2301",
            "label": "空闲地"
          },
          {
            "id": "V3-2302",
            "label": "后备耕地"
          },
          {
            "id": "V3-2303",
            "label": "田坎"
          },
          {
            "id": "V3-2304",
            "label": "盐碱地"
          },
          {
            "id": "V3-2305",
            "label": "沙地"
          },
          {
            "id": "V3-2306",
            "label": "裸土地"
          },
          {
            "id": "V3-2307",
            "label": "裸岩石砾地"
          }
        ]
      }
    ]
  },
  {
    "id": "new",
    "label": "土地用途2017",
    "children": [
      {
        "id": "05",
        "label": "商服用地",
        "children": [
          {
            "id": "0501",
            "label": "零售商业用地"
          },
          {
            "id": "0502",
            "label": "批发市场用地"
          },
          {
            "id": "0503",
            "label": "餐饮用地"
          },
          {
            "id": "0504",
            "label": "旅馆用地"
          },
          {
            "id": "0505",
            "label": "商务金融用地"
          },
          {
            "id": "0506",
            "label": "娱乐用地"
          },
          {
            "id": "0507",
            "label": "其他商服用地"
          }
        ]
      },
      {
        "id": "06",
        "label": "工矿仓储用地",
        "children": [
          {
            "id": "0601",
            "label": "工业用地"
          },
          {
            "id": "0602",
            "label": "采矿用地"
          },
          {
            "id": "0603",
            "label": "盐田"
          },
          {
            "id": "0604",
            "label": "仓储用地"
          }
        ]
      },
      {
        "id": "07",
        "label": "住宅用地",
        "children": [
          {
            "id": "0703",
            "label": "城镇住宅-普通商品住房用地"
          },
          {
            "id": "0705",
            "label": "城镇住宅-公共租赁住房用地"
          },
          {
            "id": "0702",
            "label": "农村宅基地"
          },
          {
            "id": "0708",
            "label": "城镇住宅-租赁型商品住房用地"
          },
          {
            "id": "0701",
            "label": "城镇住宅用地"
          },
          {
            "id": "0709",
            "label": "城镇住宅-共有产权住房用地"
          },
          {
            "id": "0704",
            "label": "城镇住宅-经济适用住房用地"
          },
          {
            "id": "0710",
            "label": "城镇住宅-用于安置的商品住房用地 "
          },
          {
            "id": "0706",
            "label": "保障性租赁住房"
          }
        ]
      },
      {
        "id": "08",
        "label": "公共管理与公共服务用地",
        "children": [
          {
            "id": "0801",
            "label": "机关团体用地"
          },
          {
            "id": "0802",
            "label": "新闻出版用地"
          },
          {
            "id": "0803",
            "label": "教育用地"
          },
          {
            "id": "0804",
            "label": "科研用地"
          },
          {
            "id": "0805",
            "label": "医疗卫生用地"
          },
          {
            "id": "0806",
            "label": "社会福利用地"
          },
          {
            "id": "0807",
            "label": "文化设施用地"
          },
          {
            "id": "0808",
            "label": "体育用地"
          },
          {
            "id": "0809",
            "label": "公用设施用地"
          },
          {
            "id": "0810",
            "label": "公园与绿地"
          }
        ]
      },
      {
        "id": "09",
        "label": "特殊用地",
        "children": [
          {
            "id": "0901",
            "label": "军事设施用地"
          },
          {
            "id": "0902",
            "label": "使领馆用地"
          },
          {
            "id": "0903",
            "label": "监教场所用地"
          },
          {
            "id": "0904",
            "label": "宗教用地"
          },
          {
            "id": "0905",
            "label": "殡葬用地"
          },
          {
            "id": "0906",
            "label": "风景名胜设施用地"
          }
        ]
      },
      {
        "id": "10",
        "label": "交通运输用地",
        "children": [
          {
            "id": "1001",
            "label": "铁路用地"
          },
          {
            "id": "1002",
            "label": "轨道交通用地"
          },
          {
            "id": "1003",
            "label": "公路用地"
          },
          {
            "id": "1004",
            "label": "城镇村道路用地"
          },
          {
            "id": "1006",
            "label": "农村道路"
          },
          {
            "id": "1007",
            "label": "机场用地"
          },
          {
            "id": "1008",
            "label": "港口码头用地"
          },
          {
            "id": "1009",
            "label": "管道运输用地"
          },
          {
            "id": "1005",
            "label": "交通服务场站用地"
          }
        ]
      },
      {
        "id": "11",
        "label": "水域及水利设施用地",
        "children": [
          {
            "id": "1101",
            "label": "河流水面"
          },
          {
            "id": "1102",
            "label": "湖泊水面"
          },
          {
            "id": "1103",
            "label": "水库水面"
          },
          {
            "id": "1104",
            "label": "坑塘水面"
          },
          {
            "id": "1105",
            "label": "沿海滩涂"
          },
          {
            "id": "1106",
            "label": "内陆滩涂"
          },
          {
            "id": "1107",
            "label": "沟渠"
          },
          {
            "id": "1108",
            "label": "沼泽地"
          },
          {
            "id": "1109",
            "label": "水工建筑用地"
          },
          {
            "id": "1110",
            "label": "冰川及永久积雪"
          }
        ]
      },
      {
        "id": "12",
        "label": "其他土地",
        "children": [
          {
            "id": "1201",
            "label": "空闲地"
          },
          {
            "id": "1202",
            "label": "设施农用地"
          },
          {
            "id": "1203",
            "label": "田坎"
          },
          {
            "id": "1204",
            "label": "盐碱地"
          },
          {
            "id": "1205",
            "label": "沙地"
          },
          {
            "id": "1206",
            "label": "裸土地"
          },
          {
            "id": "1207",
            "label": "裸岩石砾地"
          }
        ]
      }
    ]
  },
  {
    "id": "old",
    "label": "土地用途2007",
    "children": [
      {
        "id": "Y05",
        "label": "商服用地",
        "children": [
          {
            "id": "051",
            "label": "批发零售用地"
          },
          {
            "id": "052",
            "label": "住宿餐饮用地"
          },
          {
            "id": "053",
            "label": "商务金融用地"
          },
          {
            "id": "054",
            "label": "其他商服用地"
          }
        ]
      },
      {
        "id": "Y06",
        "label": "工矿仓储用地",
        "children": [
          {
            "id": "061",
            "label": "工业用地"
          },
          {
            "id": "062",
            "label": "采矿用地"
          },
          {
            "id": "063",
            "label": "仓储用地"
          }
        ]
      },
      {
        "id": "Y07",
        "label": "住宅用地",
        "children": [
          {
            "id": "071",
            "label": "高档住宅用地"
          },
          {
            "id": "072",
            "label": "中低价位、中小套型普通商品住房用地"
          },
          {
            "id": "073",
            "label": "其他普通商品住房用地"
          },
          {
            "id": "074",
            "label": "经济适用住房用地"
          },
          {
            "id": "075",
            "label": "廉租住房用地"
          },
          {
            "id": "076",
            "label": "其他住房用地"
          },
          {
            "id": "077",
            "label": "公共租赁住房用地"
          }
        ]
      },
      {
        "id": "Y08",
        "label": "公共管理与公共服务用地",
        "children": [
          {
            "id": "081",
            "label": "机关团体用地"
          },
          {
            "id": "082",
            "label": "新闻出版用地"
          },
          {
            "id": "083",
            "label": "科教用地"
          },
          {
            "id": "084",
            "label": "医卫慈善用地"
          },
          {
            "id": "085",
            "label": "文体娱乐用地"
          },
          {
            "id": "086",
            "label": "公共设施用地"
          },
          {
            "id": "087",
            "label": "公园与绿地"
          },
          {
            "id": "088",
            "label": "风景名胜设施用地"
          }
        ]
      },
      {
        "id": "Y09",
        "label": "特殊用地",
        "children": [
          {
            "id": "091",
            "label": "军事设施用地"
          },
          {
            "id": "092",
            "label": "使领馆用地"
          },
          {
            "id": "093",
            "label": "监教场所用地"
          },
          {
            "id": "094",
            "label": "宗教用地"
          },
          {
            "id": "095",
            "label": "殡葬用地"
          }
        ]
      },
      {
        "id": "Y10",
        "label": "交通运输用地",
        "children": [
          {
            "id": "101",
            "label": "铁路用地"
          },
          {
            "id": "102",
            "label": "公路用地"
          },
          {
            "id": "103",
            "label": "街巷用地"
          },
          {
            "id": "104",
            "label": "农村道路"
          },
          {
            "id": "105",
            "label": "机场用地"
          },
          {
            "id": "106",
            "label": "港口码头用地"
          },
          {
            "id": "107",
            "label": "管道运输用地"
          }
        ]
      },
      {
        "id": "Y11",
        "label": "水域及水利设施用地",
        "children": [
          {
            "id": "113",
            "label": "水库水面"
          },
          {
            "id": "114",
            "label": "坑塘水面"
          },
          {
            "id": "115",
            "label": "沿海滩涂"
          },
          {
            "id": "116",
            "label": "内陆滩涂"
          },
          {
            "id": "117",
            "label": "沟渠"
          },
          {
            "id": "118",
            "label": "水工建筑用地"
          },
          {
            "id": "119",
            "label": "冰川及永久积雪"
          }
        ]
      },
      {
        "id": "Y12",
        "label": "其他土地",
        "children": [
          {
            "id": "121",
            "label": "空闲地"
          },
          {
            "id": "122",
            "label": "设施农用地"
          },
          {
            "id": "123",
            "label": "田坎"
          },
          {
            "id": "124",
            "label": "盐碱地"
          },
          {
            "id": "125",
            "label": "沼泽地"
          },
          {
            "id": "126",
            "label": "沙地"
          },
          {
            "id": "127",
            "label": "裸地"
          }
        ]
      }
    ]
  }
]