export type method = {
  label: string;
  value: string;
};

export const methods: method[] = [
  {
    label: "划拨",
    value: "1",
  },
  {
    label: "招拍挂出让",
    value: "2",
  },
  {
    label: "招标出让",
    value: "21",
  },
  {
    label: "拍卖出让",
    value: "22",
  },
  {
    label: "挂牌出让",
    value: "23",
  },
  {
    label: "协议出让",
    value: "3",
  },
  {
    label: "租赁",
    value: "4",
  },
  {
    label: "作价出资或入股",
    value: "5",
  },
  {
    label: "授权经营",
    value: "6",
  },
];
